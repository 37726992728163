import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation, useQuery } from "react-query";
import { successAlert, errorAlert } from "config/sweet-alert/alert";
import { Input, Button } from "components"; //Select
import {
  GmailIcon,
  OrganizationIcon,
  PhoneIcon,
  PersonIcon,
  StateIcon,
  ZipIcon,
  CountryIcon,
} from "assets/images";
import { updateProfile, getProfile } from "services/authServices";
import { updateProfileSchema } from "config/schema";
import useLoaderStore from "store/loaderStore";

const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  organization: "",
  phone: "",
  hsi_contact: "",
  state: "",
  zipcode: "",
  country: "",
};

function UpdateProfile() {
  const { setLoading } = useLoaderStore();
  const navigate = useNavigate();
  //formik

  const {
    values,
    errors,
    touched,
    setFieldValue,
    // setFieldError,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: updateProfileSchema,
    onSubmit: (values) => {
      const trimmedValues = Object.entries(values).reduce(
        (acc, [key, value]) => {
          acc[key] = typeof value === "string" ? value.trim() : value;
          return acc;
        },
        {}
      );
      mutate(trimmedValues);
    },
  });

  //  Queries
  const { isLoading } = useQuery({
    queryKey: ["profile"],
    queryFn: getProfile,
    onSuccess: (data) => {
      const userDetail = data.data.data;
      const fields = [
        "email",
        "organization",
        "phone",
        "hsi_contact",
        "first_name",
        "last_name",
        "state",
        "zipcode",
        "country",
      ];
      // fields.forEach((field) => setFieldValue(field, userDetail[field], false));
      fields.forEach((field) => {
        const value = userDetail[field] !== undefined ? userDetail[field] : '';
        setFieldValue(field, value, false);
      });
    },
    refetchOnWindowFocus: false,
  });

  //function mutation
  const { mutate, isLoading: isLoadig } = useMutation("updateProfile", {
    mutationFn: updateProfile,
    onSuccess: (data) => {
      const user = localStorage.getItem("user");
      if (user) {
        let obj = JSON.parse(user);
        obj["first_name"] = data?.data?.data?.first_name; // Example modification
        obj["last_name"] = data?.data?.data?.last_name; // Example modification
        obj["zipcode"] = data?.data?.data?.zipcode; // Example modification
        const updatedUser = JSON.stringify(obj);
        localStorage.setItem("user", updatedUser);
      }
      successAlert("Ok", "Profile Updated Successfully");
    },
    onError: (err) => {
      errorAlert(err.response.data.message);
    },
  });

  //manage loader
  useEffect(() => {
    setLoading(isLoading || isLoadig);
    return () => {
      setLoading(false);
    };
  }, [setLoading, isLoading, isLoadig]);

  return (
    <div className="w-full login_outer flex justify-center">
      <form
        className="login_container my-12 h-fit flex flex-col"
        onSubmit={handleSubmit}
      >
        <h6 className="font-bold text-center text-2xl txtBlue">
          Update Profile
        </h6>
        <div className="mt-10">
          <label className="inputTag text-lg">First Name</label>
          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={PersonIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="first_name"
              placeholder="Enter First Name here"
              className="ml-4 w-full h-full rounded"
              value={values.first_name}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.first_name && touched.first_name ? (
            <p className="text-red-600 text-sm">{errors.first_name}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Last Name</label>
          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={PersonIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="last_name"
              placeholder="Enter Last Name here"
              className="ml-4 w-full h-full rounded"
              value={values.last_name}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.last_name && touched.last_name ? (
            <p className="text-red-600 text-sm">{errors.last_name}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Email Address</label>
          <div className="login_input flex items-center bg-[#FFE8C9]">
            <span className="ml-3">
              <img src={GmailIcon} alt="img" />
            </span>
            <Input
              readonly={true}
              type="email"
              name="email"
              className="ml-4 w-full h-full rounded bg-[#FFE8C9]"
              placeholder="Email id *"
              value={values.email}
            />
          </div>
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Organization</label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={OrganizationIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="organization"
              placeholder="Organization"
              className="ml-4 w-full h-full rounded"
              value={values.organization}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Country</label>
          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={CountryIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="country"
              placeholder="Enter Country here"
              className="ml-4 w-full h-full rounded"
              value={values.country}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.country && touched.country ? (
            <p className="text-red-600 text-sm">{errors.country}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Zip Code</label>
          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={ZipIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="zipcode"
              placeholder="Enter Zip Code here"
              className="ml-4 w-full h-full rounded"
              value={values.zipcode}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.zipcode && touched.zipcode ? (
            <p className="text-red-600 text-sm">{errors.zipcode}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">State</label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={StateIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="state"
              placeholder="Enter State here"
              className="ml-4 w-full h-full rounded"
              value={values.state}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.state && touched.state ? (
            <p className="text-red-600 text-sm">{errors.state}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Phone</label>
          <div className=" login_input flex items-center">
            <span className="ml-3">
              <img src={PhoneIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="phone"
              placeholder="Phone"
              className="ml-4 w-full h-full rounded"
              value={values.phone}
              handleChange={(e) => {
                const { value } = e.target;
                if (/^\d*$/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />
          </div>
          {errors.phone && touched.phone ? (
            <p className="text-red-600 text-sm">{errors.phone}</p>
          ) : null}
        </div>

        <div className="my-2">
          <label className="inputTag text-lg">
            HSI contact person Name/Email Id
          </label>

          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={PersonIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="hsi_contact"
              placeholder="HSI contact person Name/Email Id"
              className="ml-4 w-full h-full rounded"
              value={values.hsi_contact}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="mt-4 w-full flex justify-between gap-4">
          <Button
            className="btnTxt btnDashboard border-2 border-teal-700 text-teal-700  bg-white  rounded cursor-pointer w-1/2 font-semibold text-base"
            text={"Back"}
            handleClick={() => navigate(-1)}
            isDisabled={isLoading}
            type={"button"}
          />

          <Button
            className="login_btn btnDashboard btnTxt text-white cursor-pointer rounded w-1/2 font-semibold text-base"
            text={"Update"}
            isDisabled={isLoading}
            type={"submit"}
          />
        </div>
      </form>
    </div>
  );
}

export default UpdateProfile;
