import React from "react";
import {
  DashboardIcon,
  DesignIcon,
  LogoutIcon,
  RightArrow,
} from "assets/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import queryClient from "services/queryClient";
import useHeadWallStore from "store/headWallStore";
import { logoutAlert } from "config/sweet-alert/alert";

function Sidebar() {
  const { resetState } = useHeadWallStore();

  const Menus = [
    { title: "Dashboard", icon: DashboardIcon, path: "/dashboard" },
    {
      title: "Saved Designs",
      icon: DesignIcon,
      path: "/dashboard/saved-designs",
    },
  ];

  const location = useLocation();
  const navigate = useNavigate();

  const handleLogOutDialog = () => {
    logoutAlert(handleLogOut);
  };

  const handleLogOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("terms_accepted");   
    queryClient.clear();
    resetState();
    navigate("/");
  };

  return (
    <div className="sideBarListOpt    ">
      <div className=" h-full relative duration-300 flex flex-col justify-between">
        <ul className=" h-full">
          {Menus.map((menu, index) => (
            <Link to={menu.path} key={index}>
              <li
                className={`cursor-pointer ${
                  menu.path === location.pathname && " active"
                } `}
              >
                <img src={menu.icon} alt="...img" className="w-8 h-8" />
                <span className="route duration-400 text-xl font-normal whitespace-nowrap flex-1">
                  {menu.title}
                </span>
                <img
                  src={RightArrow}
                  alt="...img"
                  className="arrow origin-left  duration-400 "
                  style={{ width: "9px" }}
                />
              </li>
            </Link>
          ))}
        </ul>
        <ul>
          <li
            className="cursor-pointer text-gray-300 text-sm border-0 d-flex align-items-end"
            onClick={handleLogOutDialog}
          >
            <img src={LogoutIcon} alt="...img" className="w-8 h-8" />
            <span className="route origin-left duration-400 text-xl font-normal">
              Logout
            </span>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Sidebar;
