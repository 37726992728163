import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import { getDesigns } from "services/authServices";
import { DownloadIcon, EditIcon } from "assets/images";//NoDataIcon
import { Button, HeadwallImage } from "components";
import { downloadDesign } from "services/headwallServices";
import { useHeadWallStore, useLoaderStore } from "store";

const models = {
  infinity: "Horizontal Infinity",
  axiom: "Axiom Headwall",
  array: "Array SSH Headwall",
  compass: "Compass Headwall",
};

function DesignList({ limit, heading }) {
  const { setLoading } = useLoaderStore();
  const { resetState } = useHeadWallStore();
  const [showImage, setShowImage] = useState({
    open: false,
    designImage: "",
    id: "",
    fileURL: "",
    name: "",
    model: "",
  });

  const navigate = useNavigate();

  const { data, refetch, isLoading } = useQuery({
    queryKey: ["designs", limit],
    queryFn: () => getDesigns(limit),
  });

  //manage loader
  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);

  const formatDate = (d) => {
    const date = new Date(d);

    const options = {
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };

    const formattedDate = date
      .toLocaleDateString("en-IN", options)
      .replace(/at/gi, "") // Remove 'at'
      .replace(/\b(am|pm)\b/gi, (match) => match.toUpperCase()); // Capitalize 'am' or 'pm';
    return formattedDate;
  };

  const handleDownloadDesign = async (fileURL, name) => {
    try {
      await downloadDesign();
      const response = await fetch(fileURL);
      const blob = await response.blob();

      // Create a temporary anchor element
      const anchor = document.createElement("a");
      const objectURL = URL.createObjectURL(blob);

      // Set the href and download attributes
      anchor.href = objectURL;
      anchor.download = `${name}.jpg`; // Replace 'filename.ext' with your desired filename

      // Programmatically trigger the download
      document.body.appendChild(anchor);
      anchor.click();

      // Clean up resources
      document.body.removeChild(anchor);
      URL.revokeObjectURL(objectURL);
    } catch (err) {
      console.log(err);
    }
  };

  const handleEditDesign = (designId) => {
    navigate(`/editor?id=${designId}`);
  };

  return (
    <div className="w-100 d-inline-block dashboardMain ">
      {!showImage.open ? (
        <>
          <div className="headTitleDiv w-100 d-inline-block">
            <h2>{heading}</h2>
            <Link to={"/editor"} onClick={resetState}>
              <Button
                className="newDesignBtn"
                text={"Create New Design"}
                type={"button"}
              />
            </Link>
          </div>
          {!!data?.data?.data.length || isLoading ? (
            <ul className="designListDiv ">
              {data?.data?.data.map((des) => (
                <li key={des._id}>
                  <b className="titleTxt">
                    {models[des.model]}/{des.name}
                  </b>
                  <span
                    className="imgBox cursor-pointer"
                    onClick={() =>
                      setShowImage({
                        open: true,
                        designImage: des.design_image_url,
                        id: des._id,
                        fileURL: des.design_image_url,
                        name: des.name,
                        model: models[des.model],
                      })
                    }
                  >
                    <img
                      src={des.design_image_url}
                      alt="...img"
                      loading="lazy"
                    />
                  </span>
                  <span className="detailsTxt flex items-center">
                    <p>{formatDate(des.created_at)} </p>
                    <button
                      onClick={() => handleEditDesign(des._id)}
                      className="mr-3"
                    >
                      <img
                        src={EditIcon}
                        alt="...img"
                        style={{ width: "21px" }}
                      />
                    </button>
                    <button
                      onClick={() =>
                        handleDownloadDesign(des.design_image_url, des.name)
                      }
                    >
                      <img src={DownloadIcon} alt="...img" />
                    </button>
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <div className="designListDiv items-center flex justify-center flex-col">
              {/* <img
                src={NoDataIcon}
                alt="...img"
                width={"500px"}
                height={"500px"}
              /> */}
              <div className="font-semibold text-[40px] text-[#459BA5]">
              No designs yet
              </div>
            </div>
          )}
        </>
      ) : (
        <HeadwallImage
          designImg={showImage.designImage}
          id={showImage.id}
          fileURL={showImage.fileURL}
          name={showImage.name}
          model={showImage.model}
          handleShowImage={setShowImage}
          handleListDesigns={refetch}
          handleDownloadDesign={handleDownloadDesign}
        />
      )}
    </div>
  );
}

export default DesignList;
