import {
  AmberCherryGrain,
  HarvestMapleGrain,
  NeoWallNutGrain,
  PhantomCocoaGrain,
  FontHillPairGrain,
  DriftReconGrain,
} from "assets/images";
const BASE_URL = "https://api.hsiheadwalls.com/";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
const passwordRegExp =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&/{}()_\-+=,*]).{8,}$/;
const emailRegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

const AUTH = {
  SIGNUP: "user/signup",
  LOGIN: "user/signin",
  FORGOT_PASSWORD: "user/forgotPassword",
  RESET_PASSWORD: "user/resetPassword",
  CHANGE_PASSWORD: "user/changePassword",
  GET_DESINGS: "user/getDesigns",
  GET_PROFILE: "user/getProfile",
  UPDATE_PROFILE: "user/updateProfile",
  DELETE_DESIGN: "user/deleteDesign/{id}",
  REFRESH_TOKEN: "user/refreshToken",
};

const HEADWALL = {
  GET_PRODUCTS: "/user/getProducts",
  GET_SERVICES: "/user/getServices",
  GET_SPECIFICATIONS: "/user/getServiceDetails",
  SAVE_DESIGN: "/user/createDesign",
  DOWNLOAD_DESIGN: "user/downloadDesignImage",
  EDIT_DESIGN: "/user/getDesignById/{designId}",
  UPDATE_DESIGN: "/user/updateDesign/{designId}",
  GET_TEMPLATES: "/user/getDesignTemplates",
};

const PRODUCT = {
  INFINITY: "infinity",
  AXIOM: "axiom",
  COMPASS: "compass",
  ARRAY: "array",
};

const RAILS = {
  INFINITY: [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
    { label: 7, value: 7 },
    { label: 8, value: 8 },
    { label: 9, value: 9 },
    { label: 10, value: 10 },
    { label: 11, value: 11 },
    { label: 12, value: 12 },
    { label: 13, value: 13 },
    { label: 14, value: 14 },
    { label: 15, value: 15 },
    { label: 16, value: 16 },
    { label: 17, value: 17 },
  ],
  AXIOM: [
    { label: 0, value: 0 },
    { label: 1, value: 1 },
    { label: 2, value: 2 },
    { label: 3, value: 3 },
    { label: 4, value: 4 },
    { label: 5, value: 5 },
    { label: 6, value: 6 },
  ],
};

const CEILING_HEIGHT = [
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
  { label: 12, value: 12 },
];

const RAIL_INCHES = [
  { label: 0, value: 0 },
  { label: 1, value: 1 },
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
  { label: 5, value: 5 },
  { label: 6, value: 6 },
  { label: 7, value: 7 },
  { label: 8, value: 8 },
  { label: 9, value: 9 },
  { label: 10, value: 10 },
  { label: 11, value: 11 },
];

const COMPASS_WIDTH = [
  { label: 12, value: 12 },
  { label: 18, value: 18 },
  { label: 24, value: 24 },
  { label: 30, value: 30 },
  { label: 36, value: 36 },
  { label: 42, value: 42 },
  { label: 48, value: 48 },
];

const ARRAY_WIDTH = [
  { label: 2, value: 2 },
  { label: 3, value: 3 },
  { label: 4, value: 4 },
];

const COLORS = [
  "#384957",
  "#446454",
  "#114867",
  "#c4bcaa",
  "#442c1c",
  "#6c2b31",
  "#1c8c8c",
  "#79736d",
  "#f4f4e4",
];

const COMPASS = {
  SECTION_1_HEIGHT: 359,
  SECTION_2_HEIGHT: 355,
  SECTION_3_HEIGHT: 596,
};

const GRAINS = {
  7919: AmberCherryGrain,
  7953: HarvestMapleGrain,
  7991: NeoWallNutGrain,
  8213: PhantomCocoaGrain,
  10745: FontHillPairGrain,
  y0804: DriftReconGrain,
};

export {
  phoneRegExp,
  passwordRegExp,
  emailRegExp,
  BASE_URL,
  AUTH,
  HEADWALL,
  PRODUCT,
  RAILS,
  CEILING_HEIGHT,
  RAIL_INCHES,
  COLORS,
  COMPASS_WIDTH,
  ARRAY_WIDTH,
  COMPASS,
  GRAINS,
};
