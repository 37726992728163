import React, { memo, useMemo, useEffect } from "react";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { Button } from "components";
import { saveDesign, updateDesign } from "services/headwallServices";
import {
  errorAlert,
  successAlert,
  warningAlert,
} from "config/sweet-alert/alert";
import { PRODUCT } from "constants/appConstant";
import {
  useLoaderStore,
  useServiceStore,
  useHeadWallStore,
  useRightPanelStore,
} from "store";
import queryClient from "services/queryClient";

function RightPanel({ children, handleGenerateImage, designId, designName }) {
  const { setLoading } = useLoaderStore();
  const { serv } = useServiceStore();
  const { values, setValues, resetState } = useHeadWallStore();
  const { rightPanelStepper, updateStepCompletion } = useRightPanelStore();
  const navigate = useNavigate();

  const {
    width,
    ceiling_height,
    model,
    headwalls,
    name,
    color,
    background,
    sub_model,
    step,
    chase_position,
    chased,
  } = values;

  const createDesignMutation = useMutation(saveDesign, {
    onSuccess: (data) => {
      successAlert("Ok", data?.data?.message);
      resetState();
      queryClient.removeQueries(["designs"]);
      navigate("/dashboard");
    },
    onError: (err) => {
      setLoading(false);
      errorAlert(err.response.data.message);
    },
  });

  const updateDesignMutation = useMutation(updateDesign, {
    onSuccess: (data) => {
      successAlert("Ok", data?.data?.message);
      resetState();
      queryClient.removeQueries(["designs"]);
      navigate("/dashboard");
    },
    onError: (err) => {
      setLoading(false);
      errorAlert(err.response.data.message);
    },
  });

  //manage loader
  useEffect(() => {
    setLoading(
      createDesignMutation.isLoading || updateDesignMutation.isLoading
    );
    return () => {
      setLoading(false);
    };
  }, [
    setLoading,
    createDesignMutation.isLoading,
    updateDesignMutation.isLoading,
  ]);

  const headwallData = useMemo(() => {
    const transformedData = {
      headwalls: headwalls.map((headwall, index) => {
        return {
          width:
            model === PRODUCT.COMPASS ? headwall.width : headwall.width * 12,
          // position_ign: index + 1,
          position: headwall.position,
          services: headwall.services.map((service) => {
            return {
              service_type_id: service._id,
              name: service.name,
              rowPosition: service?.rowPosition || "",
              x_distance_inches: service.x_distance_inches,
              y_distance_inches: service.y_distance_inches,
              service_item_id: service?.specification?.service_item_id,
              custom_specifications:
                service?.specification?.custom_specification || "",
              is_slide: service?.is_slide || false,
              u_id: service.u_id,
              positions: service.positions,
            };
          }),
        };
      }),
    };
    return transformedData;
  }, [headwalls, model]);

  const handleStep = (step) => {
    setValues({ step: step });
  };

  const handleContinue = async () => {
    if (checkValidation()) {
      if (model !== PRODUCT.AXIOM && step === 1) {
        handleStep(step + 2);
        updateStepCompletion(3, true);
      } else if (model === PRODUCT.AXIOM && step <= 2) {
        handleStep(step + 1);
        updateStepCompletion(3, true);
      } else if (step === 3) {
        if (!color.trim() && !background)
          warningAlert("Please select headwall color.");
        else if (
          model === PRODUCT.INFINITY &&
          chased &&
          chase_position === "no"
        )
          warningAlert(
            "Hey, you forgot to pick the chase before moving forward with your design."
          );
        else {
          handleStep(step + 1);
          updateStepCompletion(4, true);
        }
      } else if (step === 4) {
        await handleGenerateImage();
        handleStep(step + 1);
        updateStepCompletion(5, true);
      } else if (step === 5) {
        const user = localStorage.getItem("user");
        if (user) {
          const newValues = { ...values };
          delete newValues.step;
          if (!designId || designName !== name.trim()) {
            delete newValues._id;
            createDesignMutation.mutate({
              ...newValues,
              name: name.trim(),
              model: model,
              width: Number(width.ft * 12 + width.in),
              ceiling_height: Number(
                ceiling_height.ft * 12 + ceiling_height.in
              ),
              headwalls: headwallData.headwalls,
            });
          } else {
            const updatedValues = {
              ...newValues,
              name: name.trim(),
              model: model,
              width: Number(width.ft * 12 + width.in),
              ceiling_height: Number(
                ceiling_height.ft * 12 + ceiling_height.in
              ),
              headwalls: headwallData.headwalls,
            };
            updateDesignMutation.mutate({ updatedValues, designId });
          }
        } else navigate("/");
      }
    }
  };

  const handleBack = () => {
    if (
      (model === PRODUCT.INFINITY ||
        model === PRODUCT.COMPASS ||
        model === PRODUCT.ARRAY) &&
      step === 3
    )
      handleStep(step - 2);
    else handleStep(step - 1);
  };

  const handleClick = async (st) => {
    if (st.completed) {
      if (st.step === 5) await handleGenerateImage();
      handleStep(st.step);
    } else return;
  };

  const buttonText = useMemo(() => {
    return step <= 3 ? "Continue" : step === 4 ? "Preview" : "Save Design";
  }, [step]);

  const checkValidation = () => {
    let isValid = true;
    if (
      model !== PRODUCT.COMPASS &&
      model !== PRODUCT.ARRAY &&
      step === 3 &&
      !width.ft &&
      width.in < 5
    ) {
      if (!width.ft) {
        setValues({
          ...values,
          width: {
            ...values["width"],
            ftError: "Minimum Width should be 5 inches.",
          },
        });
      }
      isValid = false;
    }
    return isValid;
  };

  const isDisabled = () => {
    if (step === 2 && !sub_model) {
      return true;
    } else if (step === 5 && !name.trim()) return true;
    else return false;
  };

  return (
    <>
      <div className="right_panel">
        <div className={"right_panel-inner rounded-b-2xl mb-4"}>
          <div className="flex flex-col justify-between p-[23px] h-full ">
            <div className="h-full">
              {step > 2 && (
                <div className="flex justify-between mb-4">
                  {rightPanelStepper.map((s, i) => (
                    <div
                      key={i}
                      className={`flex flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs bg-white right_panel_step_box_active ${
                        step === s.step ? "opacity-100" : "opacity-50"
                      } ${
                        s.completed ? "cursor-pointer " : "cursor-not-allowed"
                      }`}
                      onClick={() => handleClick(s)}
                    >
                      <img src={s.icon} alt="...img" />
                      <p className={`mt-1`}>{s.text}</p>
                    </div>
                  ))}
                </div>
              )}
              <div>{children}</div>
            </div>
          </div>
        </div>
        {step !== 1 ? (
          <div
            className={`double_button mx-4 ${serv && step === 4 && "hidden"}`}
          >
            <Button
              className="btnTxt backButton border-2   bg-white font-semibold py-2 px-4 rounded cursor-pointer"
              text={"Back"}
              handleClick={handleBack}
              type={"button"}
            />
            <Button
              className="login_btn py-2 px-4 w-full text-white cursor-pointer rounded btnTxt"
              text={buttonText}
              handleClick={handleContinue}
              isDisabled={isDisabled()}
              type={"submit"}
            />
          </div>
        ) : (
          <div className="single_button mx-4">
            <Button
              className="login_btn py-2 px-4 w-full text-white cursor-pointer rounded btnTxt"
              text={"Continue >>"}
              handleClick={handleContinue}
              isDisabled={model ? false : true}
              type={"button"}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default memo(RightPanel);
