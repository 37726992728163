import { warningAlert } from "config/sweet-alert/alert";
import { COMPASS, PRODUCT } from "constants/appConstant";

function generateUniqueId() {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let uniqueId = "";

  for (let i = 0; i < 10; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    uniqueId += characters.charAt(randomIndex);
  }

  return uniqueId;
}

const checkInfinityDropLocation = (
  railIndex,
  dragItem,
  values,
  dragPositions,
  railDimensionsRef,
  railMargins
) => {
  const { dragServiceWidth, service } = dragItem.current;
  const rails = [...values.headwalls];
  let services = rails[railIndex]?.services;
  if (service?.is_slide) services = services?.filter((ser) => ser?.is_slide);
  else services = services?.filter((ser) => !ser?.is_slide);
  const dragServicePosition = dragPositions.current;
  let dropLocation = true;
  let newDropLocation = { x: dragServicePosition.x, y: dragServicePosition.y };
  let checkServicesIntersecting = {
    oneTouched: false,
    twoTouched: false,
    startRailTouched: false,
    maxRailTouched: false,
  };
  //center chase calculations
  // const valueToCheck = dragPositions.current.x;
  const minValue = railDimensionsRef.current?.width / 2 - 192.5; // 40px chase width+ 107 px for 6 inch
  const maxValue = railDimensionsRef.current?.width / 2 + 192.5;
  //--------------------------------------------------------------------------//

  //if the service width is greater than rail width
  if (dragServiceWidth > railDimensionsRef.current?.width) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  }

  for (let i = 0; i < services?.length; i++) {
    const serviceWidth = services[i].gang_width / 0.056;
    if (service.u_id !== services[i].u_id) {
      //calculation for left side drag
      if (
        dragServicePosition.x >= services[i]?.positions?.x &&
        dragServicePosition.x <= services[i]?.positions?.x + serviceWidth
      ) {
        checkServicesIntersecting["oneTouched"] = true;
        const newPosition = services[i]?.positions?.x + serviceWidth;
        if (
          values.chase_position === "center" &&
          newPosition < minValue &&
          newPosition + dragServiceWidth > minValue
        ) {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
          return;
        }
        //to check the margins between last service and rail width
        if (
          newPosition + dragServiceWidth <=
          railDimensionsRef.current.width - railMargins.end
        ) {
          newDropLocation = {
            x: services[i]?.positions?.x + serviceWidth,
            y: 0,
          };
        } else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      } //calulation for right side drag
      else if (
        dragServicePosition.x + dragServiceWidth >= services[i]?.positions?.x &&
        dragServicePosition.x + dragServiceWidth <=
          services[i]?.positions?.x + dragServiceWidth
      ) {
        checkServicesIntersecting["twoTouched"] = true;
        const newPosition = services[i]?.positions?.x - dragServiceWidth;
        if (
          values.chase_position === "center" &&
          newPosition < maxValue &&
          newPosition + dragServiceWidth > maxValue
        ) {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
          return;
        }
        //to check the margins between first service and rail width
        if (newPosition >= railMargins.start) {
          newDropLocation = {
            x: newPosition,
            y: 0,
          };
          break;
        } else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
      }
    }
  }

  const checkServicesOverLap = services.some((serv) => {
    const serviceWidth = serv.gang_width / 0.056;
    return (
      serv.u_id !== service.u_id &&
      (serv.positions?.x === newDropLocation.x ||
        (newDropLocation.x + dragServiceWidth > serv.positions?.x &&
          newDropLocation.x + dragServiceWidth <
            serv.positions?.x + serviceWidth) ||
        (newDropLocation.x > serv.positions.x &&
          newDropLocation.x < serv.positions.x + serviceWidth) ||
        (newDropLocation.x < serv.positions?.x &&
          newDropLocation.x + dragServiceWidth >=
            serv.positions?.x + serviceWidth))
    );
  });
  if (
    (checkServicesIntersecting.oneTouched &&
      checkServicesIntersecting.twoTouched) ||
    checkServicesOverLap
  ) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  } else if (
    values.chase_position === "center" &&
    checkServicesIntersecting.oneTouched &&
    newDropLocation.x + dragServiceWidth > minValue &&
    newDropLocation.x < maxValue
  ) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  } else if (
    values.chase_position === "center" &&
    checkServicesIntersecting.twoTouched &&
    newDropLocation.x < maxValue &&
    newDropLocation.x > minValue
  ) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  } else if (
    checkServicesIntersecting.oneTouched ||
    checkServicesIntersecting.twoTouched
  )
    dragPositions.current = newDropLocation;
  //to restrict drop 6 inches both side of the center chase
  if (values.chased && values.chase_position === "center") {
    if (
      newDropLocation.x + dragServiceWidth > minValue &&
      newDropLocation.x < maxValue
    ) {
      if (newDropLocation.x < railDimensionsRef.current.width / 2) {
        const newPosition = minValue - dragServiceWidth;
        // if start value is less than the rail start point
        if (newPosition < railMargins.start) {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
          return;
        }
        const checkforOtherServiceOnSamePosition = services?.some((serv) => {
          return (
            serv.positions.x === newPosition ||
            (newPosition <= serv.positions.x &&
              newPosition + dragServiceWidth >=
                serv.positions.x + serv.gang_width / 0.056) ||
            (newPosition <= serv.positions.x + serv.gang_width / 0.056 &&
              newPosition + dragServiceWidth >=
                serv.positions.x + serv.gang_width / (0.056).toFixed(1))
          );
        });
        if (checkforOtherServiceOnSamePosition) {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
          return;
        }
        dragPositions.current = { x: newPosition, y: 0 };
      } else if (newDropLocation.x > railDimensionsRef.current.width / 2) {
        const newPosition = maxValue;
        // if service start value is greater than the rail end point
        if (
          newPosition + dragServiceWidth > railDimensionsRef.current.width ||
          newPosition + dragServiceWidth >
            railDimensionsRef.current.width - railMargins.end
        ) {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
          return;
        }
        const checkforOtherServiceOnSamePosition = services?.some((serv) => {
          const serviceWidth = serv.gang_width / 0.056;
          return (
            serv.positions.x === newPosition ||
            (newPosition + dragServiceWidth >= serv.positions.x &&
              newPosition + dragServiceWidth <=
                serv.positions.x + serviceWidth) ||
            (newPosition < serv.positions?.x &&
              newPosition + dragServiceWidth >=
                serv.positions?.x + serviceWidth)
          );
        });
        if (checkforOtherServiceOnSamePosition) {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
          return;
        }
        dragPositions.current = { x: newPosition, y: 0 };
      }
    }
  }
  return dropLocation;
};

const checkAxiomDropLocation = (
  railIndex,
  dragItem,
  values,
  dragPositions,
  railDimensionsRef,
  railMargins
) => {
  const { dragServiceWidth, dragServiceHeight, service } = dragItem.current;
  const dragServicePosition = dragPositions.current;
  const rails = [...values.headwalls];
  let services = rails[railIndex]?.services;
  if (service?.is_slide)
    services = services?.filter((ser) => {
      if (dragServicePosition.x > railDimensionsRef.current.width / 2)
        return (
          ser?.is_slide && ser.positions.x > railDimensionsRef.current.width / 2
        );
      else
        return (
          ser?.is_slide && ser.positions.x < railDimensionsRef.current.width / 2
        );
    });
  else services = services?.filter((ser) => !ser?.is_slide);
  let dropLocation = true;
  let newDropLocation = {};
  let checkServicesIntersecting = {
    leftTouched: false,
    rightTouched: false,
    topTouched: false,
    bottomTouched: false,
    startRailTouched: false,
    maxRailTouched: false,
  };

  //service margin between two services in y axis in px
  const serviceMargin = 26.78571428571429;
  const slideServiceMargin = 5;

  //for y axis
  for (let i = 0; i < services?.length; i++) {
    let serviceHeight = services[i].height / 0.056;
    if (service?.is_slide)
      serviceHeight = services[i].height / 0.056 + slideServiceMargin;
    if (services[i].u_id !== service?.u_id) {
      //for vaccum bottom drag
      if (
        service?.is_slide &&
        dragServicePosition.y >= services[i]?.positions?.y &&
        dragServicePosition.y <= services[i]?.positions?.y + serviceHeight
      ) {
        // console.log("1")
        checkServicesIntersecting["topTouched"] = true;
        const newPosition = services[i]?.positions?.y + dragServiceHeight;
        newDropLocation["y"] = newPosition;
        newDropLocation["x"] =
          dragServicePosition.x < railDimensionsRef.current.width / 2
            ? 0
            : railDimensionsRef.current.width;
        break;
      } else if (
        service?.is_slide &&
        dragServicePosition.y + dragServiceHeight >=
          services[i]?.positions?.y &&
        dragServicePosition.y + dragServiceHeight <=
          services[i]?.positions?.y + dragServiceHeight
      ) {
        // console.log("2")
        checkServicesIntersecting["bottomTouched"] = true;
        const newPosition = services[i]?.positions?.y - dragServiceHeight;
        if (newPosition < railMargins.top) {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
          return;
        }
        newDropLocation["y"] = newPosition;
        newDropLocation["x"] =
          dragServicePosition.x < railDimensionsRef.current.width / 2
            ? 0
            : railDimensionsRef.current.width;
        break;
      }
      //calculation for bottom drag
      else if (
        dragServicePosition.y >= services[i]?.positions?.y &&
        dragServicePosition.y <= services[i]?.positions?.y + serviceHeight
      ) {
        // console.log("working1");
        checkServicesIntersecting["topTouched"] = true;
        newDropLocation["y"] = services[i]?.positions?.y;
        newDropLocation["x"] = dragServicePosition.x;
        break;
      } else if (
        !service?.is_slide &&
        dragServicePosition.y >= services[i]?.positions?.y &&
        dragServicePosition.y <=
          services[i]?.positions?.y + serviceHeight + serviceMargin
      ) {
        // console.log("working2");
        checkServicesIntersecting["topTouched"] = true;
        const newPosition =
          services[i]?.positions?.y + serviceHeight + serviceMargin;
        // services[i]?.positions?.y + dragServiceHeight;
        newDropLocation["y"] = newPosition;
        newDropLocation["x"] = dragServicePosition.x;
        break;
      }

      //calulation for top drag
      else if (
        dragServicePosition.y + serviceHeight >=
          services[i]?.positions?.y + 13.38 && //13.38 because it calculation position from margin instead of the switch
        dragServicePosition.y + serviceHeight <=
          services[i]?.positions?.y + dragServiceHeight
      ) {
        // console.log("working3");
        checkServicesIntersecting["bottomTouched"] = true;
        newDropLocation["y"] = services[i]?.positions?.y;
        newDropLocation["x"] = dragServicePosition.x;
        break;
      } else if (
        dragServicePosition.y + dragServiceHeight >=
          services[i]?.positions?.y && //- serviceMargin
        dragServicePosition.y + dragServiceHeight - serviceMargin <=
          services[i]?.positions?.y + dragServiceHeight
      ) {
        // console.log("working4");
        checkServicesIntersecting["bottomTouched"] = true;
        const newPosition = services[i]?.positions?.y - dragServiceHeight;
        if (newPosition < railMargins.top) {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
          return;
        }
        newDropLocation["y"] = newPosition;
        newDropLocation["x"] = dragServicePosition.x;
        break;
      }
    }
  }
  const checkVerticalServicesOverLap = services.some((serv) => {
    const serviceHeight = serv.height / 0.056;
    return (
      serv.u_id !== service.u_id &&
      ((newDropLocation.y !== serv.positions?.y &&
        ((newDropLocation.y + dragServiceHeight > serv.positions?.y &&
          newDropLocation.y + dragServiceHeight <
            serv.positions?.y + serviceHeight + serviceMargin) ||
          (newDropLocation.y > serv.positions?.y &&
            Math.round(newDropLocation.y) <
              Math.round(
                serv.positions?.y + serviceHeight + serviceMargin
              )))) ||
        (newDropLocation.y === serv.positions?.y &&
          newDropLocation.y > serv.positions?.y &&
          newDropLocation.y <
            serv.positions?.y + serviceHeight + serviceMargin) ||
        newDropLocation.y + dragServiceHeight >
          railDimensionsRef.current.parentHeight)
    );
  });
  // console.log({ checkVerticalServicesOverLap });
  if (checkVerticalServicesOverLap) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
    return;
  }
  if (!service?.is_slide) {
    //filtering services on the same y axis
    services = services?.filter(
      (serv) => newDropLocation.y === serv?.positions?.y
    );
    //for x-axis
    for (let i = 0; i < services?.length; i++) {
      let serviceWidth;
      if (services[i].category === "Medical Gas Outlet")
        serviceWidth = (services[i].width + 2.375) / 0.056;
      else serviceWidth = (services[i].width + 0.125) / 0.056; //0.0625 to manage margins around the service
      if (services[i].u_id !== service.u_id) {
        //calculation for left side drag
        if (
          dragServicePosition.x >= services[i]?.positions?.x &&
          dragServicePosition.x <= services[i]?.positions?.x + serviceWidth
        ) {
          // console.log("1");
          checkServicesIntersecting["leftTouched"] = true;
          // const newPosition = services[i]?.positions?.x + serviceWidth;
          //to manage side margins in case of gas
          let newPosition = 0;
          if (service.category === "Medical Gas Outlet")
            newPosition = services[i]?.positions?.x + serviceWidth;
          else newPosition = services[i]?.positions?.x + serviceWidth;

          if (
            newPosition + dragServiceWidth <=
            railDimensionsRef.current.width - railMargins.end
          )
            newDropLocation["x"] = newPosition;
          else {
            dropLocation = false;
            warningAlert("Sorry, you cannot place new services here .");
          }
          break;
        } //calulation for right side drag
        else if (
          dragServicePosition.x + dragServiceWidth >=
            services[i]?.positions?.x &&
          dragServicePosition.x + dragServiceWidth <=
            services[i]?.positions?.x + dragServiceWidth
        ) {
          // console.log("2");

          checkServicesIntersecting["rightTouched"] = true;
          let newPosition = 0;
          if (service.category === "Medical Gas Outlet")
            newPosition = services[i]?.positions?.x - dragServiceWidth + 2.8;
          //to manage side margins in case of gas
          else
            newPosition = services[i]?.positions?.x - dragServiceWidth - 0.42; //to manage side margins
          if (newPosition > railMargins.start)
            newDropLocation["x"] = newPosition;
          else {
            dropLocation = false;
            warningAlert("Sorry, you cannot place new services here .");
          }
        }
      }
    }
  }
  const checkHorizontalServicesOverLap = services.some((serv) => {
    let serviceWidth;
    if (serv.category === "Medical Gas Outlet")
      serviceWidth = (serv.width + 2.375) / 0.056;
    else serviceWidth = (serv.width + 0.125) / 0.056; //0.0625 to manage margins around the service
    return (
      serv.u_id !== service.u_id &&
      (serv.positions?.x === newDropLocation.x ||
        (service.category !== "Medical Gas Outlet" &&
          newDropLocation.x + dragServiceWidth > serv.positions?.x &&
          newDropLocation.x + dragServiceWidth <
            serv.positions?.x + serviceWidth) ||
        (service.category === "Medical Gas Outlet" &&
          newDropLocation.x + dragServiceWidth - 2.8 > serv.positions?.x &&
          newDropLocation.x + dragServiceWidth - 2.8 <
            serv.positions?.x + serviceWidth) ||
        (newDropLocation.x > serv.positions.x &&
          newDropLocation.x < serv.positions.x + serviceWidth) ||
        (newDropLocation.x < serv.positions?.x &&
          newDropLocation.x + dragServiceWidth >=
            serv.positions?.x + serviceWidth))
    );
  });
  // console.log({ checkHorizontalServicesOverLap });
  if (checkHorizontalServicesOverLap) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
    return;
  }
  if (
    checkServicesIntersecting.leftTouched ||
    checkServicesIntersecting.rightTouched ||
    checkServicesIntersecting.topTouched ||
    checkServicesIntersecting.bottomTouched
  )
    dragPositions.current = newDropLocation;
  return dropLocation;
};

const checkCompassDropLocation = (
  rowIndex,
  railIndex,
  dragItem,
  values,
  dragPositions,
  railMargins
) => {
  const { dragServiceWidth, dragServiceHeight, service } = dragItem.current;
  const rails = [...values.headwalls];
  let services = rails[railIndex]?.services;
  const rowWidth = rails[railIndex]?.width;
  const dragServicePosition = dragPositions.current;
  let droppableAreaWidth;
  // to manage x on the auto cad
  let manageXMargin = 6.78571428571;
  if (
    service.name.includes("Call") ||
    service.name.includes("Staff") ||
    service.name.includes("Code Blue")
  ) {
    manageXMargin = 8.92857142857;
  }
  if (!service?.is_slide) droppableAreaWidth = (rowWidth - 5.5) / 0.056;
  else droppableAreaWidth = rowWidth / 0.056;

  let dropLocation = true;
  let newDropLocation = {};
  let checkServicesIntersecting = {
    leftTouched: false,
    rightTouched: false,
    topTouched: false,
    bottomTouched: false,
    startRailTouched: false,
    maxRailTouched: false,
  };
  const extraMarginForNormalServices = 49; //padding or margin of row from the outer rail for normal services
  // const extraMarginForNormalServices = 49.1071428571; //padding or margin of row from the outer rail for normal services
  if (!service?.is_slide && !rowIndex) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  } else if (!service?.is_slide) {
    const normalServices = services?.filter(
      (serv) => serv.rowPosition === rowIndex
    );
    const slideServices = services?.filter((serv) => {
      return (
        serv?.is_slide &&
        ((serv.positions.y >= dragServicePosition.y &&
          serv.positions.y <= dragServicePosition.y + dragServiceHeight) ||
          (serv.positions.y + serv.height / 0.056 >= dragServicePosition.y &&
            dragServicePosition.y >= serv.positions.y))
      );
    });
    if (normalServices?.length || slideServices?.length)
      services = [...normalServices, ...slideServices];
    else services = [];
  } else {
    services = services?.filter((serv) => {
      return (
        (serv.positions.y >= dragServicePosition.y &&
          serv.positions.y <= dragServicePosition.y + dragServiceHeight) ||
        (serv.positions.y + serv.height / 0.056 >= dragServicePosition.y &&
          dragServicePosition.y >= serv.positions.y)
      );
    });
  }

  let dropPoints = getDropPoints(rowWidth);
  if (rowIndex !== null && !service?.is_slide) {
    const nearestDropPoint = findNearestValue(
      dropPoints,
      dragPositions.current?.x
    );
    dragPositions.current.x = nearestDropPoint - manageXMargin;
  }
  //--------------------------------------------------------------------------//
  //if the service width is greater than rail width
  if (dragServiceWidth > droppableAreaWidth) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  }

  if (
    (dragServicePosition.y < COMPASS.SECTION_1_HEIGHT &&
      dragServicePosition.y + dragServiceHeight >= COMPASS.SECTION_1_HEIGHT) ||
    (dragServicePosition.y <
      COMPASS.SECTION_2_HEIGHT + COMPASS.SECTION_1_HEIGHT &&
      dragServicePosition.y + dragServiceHeight >=
        COMPASS.SECTION_2_HEIGHT + COMPASS.SECTION_1_HEIGHT)
  ) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  }
  // console.log({ services });
  for (let i = 0; i < services?.length; i++) {
    const serviceWidth = services[i].width / 0.056;
    const serviceHeight = services[i].height / 0.056;
    //calculation for left side drag
    //calculation for normal services
    if (
      !service?.is_slide &&
      !services[i]?.is_slide &&
      dragServicePosition.x >= services[i]?.positions?.x &&
      dragServicePosition.x <= services[i]?.positions?.x + serviceWidth
    ) {
      checkServicesIntersecting["leftTouched"] = true;
      const newPosition =
        services[i]?.positions?.x + manageXMargin + serviceWidth;
      let nearestDropPoint = findNearestValue(dropPoints, newPosition);
      nearestDropPoint = nearestDropPoint - manageXMargin;
      //add some extra margin for call, staff and code blue
      if (
        services[i].name.includes("Call") ||
        services[i].name.includes("Staff") ||
        services[i].name.includes("Code Blue") ||
        service.name.includes("Call") ||
        service.name.includes("Staff") ||
        service.name.includes("Code Blue")
      )
        nearestDropPoint += 16.183035714;
      //to check the margins between last service and rail width
      if (nearestDropPoint + dragServiceWidth <= droppableAreaWidth) {
        newDropLocation = {
          x: nearestDropPoint,
          y: dragServicePosition.y,
        };
      } else {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here .");
      }
    } //calulation for right side drag
    else if (
      !service?.is_slide &&
      !services[i]?.is_slide &&
      dragServicePosition.x + dragServiceWidth >= services[i]?.positions?.x &&
      dragServicePosition.x + dragServiceWidth <=
        services[i]?.positions?.x + dragServiceWidth
    ) {
      checkServicesIntersecting["rightTouched"] = true;
      const newPosition = services[i]?.positions?.x - dragServiceWidth;
      let nearestDropPoint = findNearestValue(dropPoints, newPosition);
      nearestDropPoint = nearestDropPoint - manageXMargin;
      //add some extra margin for call, staff and code blue
      if (
        services[i].name.includes("Call") ||
        services[i].name.includes("Staff") ||
        services[i].name.includes("Code Blue") ||
        service.name.includes("Call") ||
        service.name.includes("Staff") ||
        service.name.includes("Code Blue")
      )
        nearestDropPoint -= 16.183035714;
      //to check the margins between first service and rail width
      if (nearestDropPoint >= railMargins.start) {
        newDropLocation = {
          x: nearestDropPoint,
          y: dragServicePosition.y,
        };
      } else {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here .");
      }
    }
    //calculation for slide services if dragged service is slide and service[i] is also slide
    else if (
      service?.is_slide &&
      services[i]?.is_slide &&
      dragServicePosition.x >= services[i]?.positions?.x &&
      dragServicePosition.x <= services[i]?.positions?.x + serviceWidth
    ) {
      // console.log("3")
      checkServicesIntersecting["leftTouched"] = true;
      let newPosition;
      if (
        dragServicePosition.y >= services[i]?.positions?.y + serviceHeight ||
        dragServicePosition.y + dragServiceHeight <= services[i]?.positions?.y
      )
        newPosition = services[i]?.positions?.x;
      else newPosition = services[i]?.positions?.x + serviceWidth;
      //to check the margins between last service and rail width
      if (newPosition + dragServiceWidth <= droppableAreaWidth) {
        newDropLocation = {
          x: newPosition,
          y: dragServicePosition.y,
        };
      } else {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here .");
      }
    } //calulation for right side drag
    else if (
      service.is_slide &&
      services[i]?.is_slide &&
      dragServicePosition.x + dragServiceWidth >= services[i]?.positions?.x &&
      dragServicePosition.x + dragServiceWidth <=
        services[i]?.positions?.x + dragServiceWidth
    ) {
      // console.log("4")
      checkServicesIntersecting["rightTouched"] = true;
      let newPosition;
      if (
        dragServicePosition.y > services[i]?.positions?.y + serviceHeight ||
        dragServicePosition.y + dragServiceHeight <= services[i]?.positions?.y
      )
        newPosition = services[i]?.positions?.x;
      else newPosition = services[i]?.positions?.x - serviceWidth;

      //to check the margins between first service and rail width
      if (newPosition >= railMargins.start) {
        newDropLocation = {
          x: newPosition,
          y: dragServicePosition.y,
        };
      } else {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here .");
      }
    }
    //calculation for slide services if dragged service is slide and service[i] is normal service
    else if (
      service?.is_slide &&
      !services[i]?.is_slide &&
      dragServicePosition.x >=
        services[i]?.positions?.x + extraMarginForNormalServices &&
      dragServicePosition.x <=
        services[i]?.positions?.x + extraMarginForNormalServices + serviceWidth
    ) {
      // console.log("4");
      let newPosition;
      checkServicesIntersecting["leftTouched"] = true;
      if (
        dragServicePosition.y >= services[i]?.positions?.y + serviceHeight ||
        dragServicePosition.y + dragServiceHeight <= services[i]?.positions?.y
      )
        newPosition = services[i]?.positions?.x;
      else
        newPosition =
          services[i]?.positions?.x +
          extraMarginForNormalServices +
          serviceWidth;
      //to check the margins between last service and rail width
      if (newPosition + dragServiceWidth <= droppableAreaWidth) {
        newDropLocation = {
          x: newPosition,
          y: dragServicePosition.y,
        };
      } else {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here.");
      }
    } //calulation for right side drag
    else if (
      service.is_slide &&
      !services[i]?.is_slide &&
      dragServicePosition.x + dragServiceWidth >=
        services[i]?.positions?.x + extraMarginForNormalServices &&
      dragServicePosition.x + dragServiceWidth <=
        services[i]?.positions?.x +
          extraMarginForNormalServices +
          dragServiceWidth
    ) {
      console.log("5");
      checkServicesIntersecting["rightTouched"] = true;
      let newPosition;
      if (
        dragServicePosition.y > services[i]?.positions?.y + serviceHeight ||
        dragServicePosition.y + dragServiceHeight <= services[i]?.positions?.y
      )
        newPosition = services[i]?.positions?.x;
      else {
        let extraSpace = 0.59285714285;
        if (
          services[i].name.includes("Call") ||
          services[i].name.includes("Staff") ||
          services[i].name.includes("Code Blue")
        )
          extraSpace = 2.41071428571;
        newPosition =
          services[i]?.positions?.x +
          extraMarginForNormalServices -
          dragServiceWidth -
          extraSpace; //to manage extra space on left side for vaccum service
      }
      //to check the margins between first service and rail width
      if (newPosition >= railMargins.start) {
        newDropLocation = {
          x: newPosition,
          y: dragServicePosition.y,
        };
      } else {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here .");
      }
    }
    //calculation for slide services if dragged service is normal and service[i] is slide service
    else if (
      !service?.is_slide &&
      services[i]?.is_slide &&
      dragServicePosition.x + extraMarginForNormalServices >=
        services[i]?.positions?.x &&
      dragServicePosition.x + extraMarginForNormalServices <=
        services[i]?.positions?.x + serviceWidth
    ) {
      // console.log("6");
      checkServicesIntersecting["leftTouched"] = true;
      const newPosition =
        services[i]?.positions?.x -
        extraMarginForNormalServices +
        serviceWidth +
        manageXMargin;
      let nearestDropPoint = findNearestValue(dropPoints, newPosition);
      nearestDropPoint = nearestDropPoint - manageXMargin;
      //add some extra margin for call, staff and code blue
      // if (
      //   services[i].name.includes("Call") ||
      //   services[i].name.includes("Staff") ||
      //   services[i].name.includes("Code Blue") ||
      //   service.name.includes("Call") ||
      //   service.name.includes("Staff") ||
      //   service.name.includes("Code Blue")
      // )
      nearestDropPoint += 16.183035714;
      //to check the margins between last service and rail width
      if (nearestDropPoint + dragServiceWidth <= droppableAreaWidth) {
        newDropLocation = {
          x: nearestDropPoint,
          y: dragServicePosition.y,
        };
      } else {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here .");
      }
    } //calulation for right side drag
    else if (
      !service?.is_slide &&
      services[i]?.is_slide &&
      dragServicePosition.x + dragServiceWidth + extraMarginForNormalServices >=
        services[i]?.positions?.x &&
      dragServicePosition.x + dragServiceWidth + extraMarginForNormalServices <=
        services[i]?.positions?.x + dragServiceWidth
    ) {
      // console.log("7");
      checkServicesIntersecting["rightTouched"] = true;
      if (services[i].positions.y + serviceHeight > dragServicePosition.y) {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here.");
        return;
      }
      const newPosition = services[i]?.positions?.x - dragServiceWidth;
      let nearestDropPoint = findNearestValue(dropPoints, newPosition);
      //add some extra margin for call, staff and code blue
      // if (
      //   services[i].name.includes("Call") ||
      //   services[i].name.includes("Staff") ||
      //   services[i].name.includes("Code Blue") ||
      //   service.name.includes("Call") ||
      //   service.name.includes("Staff") ||
      //   service.name.includes("Code Blue")
      // )
      nearestDropPoint -= 16.183035714;
      //to check the margins between first service and rail width
      if (nearestDropPoint >= railMargins.start) {
        newDropLocation = {
          x: nearestDropPoint,
          y: dragServicePosition.y,
        };
      } else {
        dropLocation = false;
        warningAlert("Sorry, you cannot place new services here .");
      }
    }
  }

  const checkHorizontalServicesOverLap = services.some((serv) => {
    // debugger;
    const serviceWidth = serv.width / 0.056;
    if (
      (!serv?.is_slide && !service?.is_slide) ||
      (serv?.is_slide && service?.is_slide)
    ) {
      return (
        serv.u_id !== service.u_id &&
        (serv.positions?.x === newDropLocation.x ||
          (newDropLocation.x + dragServiceWidth > serv.positions?.x &&
            newDropLocation.x + dragServiceWidth <
              serv.positions?.x + serviceWidth) ||
          (newDropLocation.x > serv.positions.x &&
            newDropLocation.x < serv.positions.x + serviceWidth) ||
          (newDropLocation.x < serv.positions.x &&
            newDropLocation.x + dragServiceWidth >
              serv.positions?.x + serviceWidth))
      );
    } else if (!serv?.is_slide && service?.is_slide) {
      return (
        serv.u_id !== service.u_id &&
        (serv.positions?.x + extraMarginForNormalServices ===
          newDropLocation.x ||
          (newDropLocation.x + dragServiceWidth >
            serv.positions?.x + extraMarginForNormalServices &&
            newDropLocation.x + dragServiceWidth <
              serv.positions?.x +
                serviceWidth +
                extraMarginForNormalServices) ||
          (newDropLocation.x >
            serv.positions.x + extraMarginForNormalServices &&
            newDropLocation.x <
              serv.positions.x + serviceWidth + extraMarginForNormalServices))
      );
    } else if (serv?.is_slide && !service?.is_slide) {
      return (
        serv.u_id !== service.u_id &&
        (serv.positions?.x ===
          newDropLocation.x + extraMarginForNormalServices ||
          (newDropLocation.x + dragServiceWidth + extraMarginForNormalServices >
            serv.positions?.x &&
            newDropLocation.x +
              dragServiceWidth +
              extraMarginForNormalServices <
              serv.positions?.x + serviceWidth) ||
          (newDropLocation.x + extraMarginForNormalServices >
            serv.positions.x &&
            newDropLocation.x + extraMarginForNormalServices <
              serv.positions.x + serviceWidth - manageXMargin))
      );
    } else return false;
  });

  // console.log({ checkHorizontalServicesOverLap });

  if (checkHorizontalServicesOverLap) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here.");
  } else if (
    checkServicesIntersecting.leftTouched ||
    checkServicesIntersecting.rightTouched
  )
    dragPositions.current = newDropLocation;
  return dropLocation;
};

const checkArrayDropLocation = (
  rowIndex,
  railIndex,
  dragItem,
  values,
  dragPositions,
  railMargins,
  railDimensionsRef
) => {
  const { dragServiceWidth, dragServiceHeight, service } = dragItem.current;
  const rails = [...values.headwalls];
  const servicesArr = rails[railIndex]?.services;
  const rowWidth = rails[railIndex]?.width;
  const dragServicePosition = dragPositions.current;
  let droppableAreaWidth;
  if (!service?.is_slide) droppableAreaWidth = (rowWidth * 12 - 3.25) / 0.056;

  //service margin between two services in y axis in px
  const serviceMargin = 13.3928571429;

  const railHeight =
    values.ceiling_height.ft * 12 + values.ceiling_height.in - 6;
  const section1 = (railHeight - 60) / 0.056;
  const section2 = (railHeight - 30) / 0.056;

  let dropLocation = true;
  let newDropLocation = {};
  let checkServicesIntersecting = {
    leftTouched: false,
    rightTouched: false,
    topTouched: false,
    bottomTouched: false,
    startRailTouched: false,
    maxRailTouched: false,
  };

  if (
    service?.is_slide &&
    ((dragServicePosition.y < section1 &&
      dragServicePosition.y + dragServiceHeight >= section1) ||
      (dragServicePosition.y < section2 &&
        dragServicePosition.y + dragServiceHeight >= section2))
  ) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  } else if (
    (!service?.is_slide && !rowIndex) ||
    (!dragServicePosition.y && !dragServicePosition.x)
  ) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  }

  //find the rowIndex on the basis of the drag position of slide service
  if (service?.is_slide) {
    if (dragServicePosition.y < section1) {
      rowIndex = 1;
    } else if (
      dragServicePosition.y < section2 &&
      dragServicePosition.y > section1
    ) {
      rowIndex = 2;
    } else if (
      dragServicePosition.y > section2 &&
      dragServicePosition.y < railHeight / 0.056
    ) {
      rowIndex = 3;
    }
  }

  //to manage the y axis while user dragging normal services

  let manageServiceY = 0;
  if (rowIndex === 1) manageServiceY = 2.875 / 0.056;
  else if (rowIndex === 2) {
    const ceilingHeight =
      values.ceiling_height.ft * 12 + values.ceiling_height.in;
    manageServiceY = (ceilingHeight - 64) / 0.056;
  } else if (rowIndex === 3) {
    const ceilingHeight =
      values.ceiling_height.ft * 12 + values.ceiling_height.in;
    manageServiceY = (ceilingHeight - 34) / 0.056;
  }

  let services = servicesArr.filter(
    (ser) => !ser.is_slide && ser.rowPosition === rowIndex
  );

  if (!services.length) newDropLocation["y"] = dragServicePosition.y;

  const extraMarginForNormalServices = 31.25; //padding or margin of row from the outer rail for normal services
  if (!service?.is_slide) {
    //for y axis
    for (let i = 0; i < services?.length; i++) {
      const serviceHeight = services[i].height / 0.056;
      if (services[i].u_id !== service.u_id) {
        //calculation for bottom drag
        if (
          !services[i].is_slide &&
          !service?.is_slide &&
          dragServicePosition.y >= services[i]?.positions?.y &&
          dragServicePosition.y <= services[i]?.positions?.y + serviceHeight
        ) {
          // console.log("workingy1");
          checkServicesIntersecting["topTouched"] = true;
          newDropLocation["y"] = services[i]?.positions?.y;
          newDropLocation["x"] = dragServicePosition.x;
          break;
        } else if (
          !services[i].is_slide &&
          !service?.is_slide &&
          dragServicePosition.y >= services[i]?.positions?.y &&
          dragServicePosition.y <=
            services[i]?.positions?.y + serviceHeight + serviceMargin
        ) {
          // console.log("workingy2");
          checkServicesIntersecting["topTouched"] = true;
          const newPosition =
            services[i]?.positions?.y + serviceHeight + serviceMargin;
          newDropLocation["y"] = newPosition;
          newDropLocation["x"] = dragServicePosition.x;
          break;
        }
        //calulation for top drag
        else if (
          !services[i].is_slide &&
          !service?.is_slide &&
          dragServicePosition.y + dragServiceHeight >=
            services[i]?.positions?.y + serviceMargin &&
          dragServicePosition.y + dragServiceHeight <=
            services[i]?.positions?.y + serviceHeight
        ) {
          // console.log("workingy3");
          checkServicesIntersecting["bottomTouched"] = true;
          newDropLocation["y"] = services[i]?.positions?.y;
          newDropLocation["x"] = dragServicePosition.x;
          break;
        } else if (
          !services[i].is_slide &&
          !service?.is_slide &&
          dragServicePosition.y + dragServiceHeight >=
            services[i]?.positions?.y - serviceMargin &&
          dragServicePosition.y + dragServiceHeight - serviceMargin <=
            services[i]?.positions?.y + dragServiceHeight
        ) {
          // console.log("workingy4");
          checkServicesIntersecting["bottomTouched"] = true;
          const newPosition =
            services[i]?.positions?.y - dragServiceHeight - serviceMargin;
          if (newPosition < railMargins.top) {
            dropLocation = false;
            warningAlert("Sorry, you cannot place new services here .");
            return;
          }
          newDropLocation["y"] = newPosition;
          newDropLocation["x"] = dragServicePosition.x;
          break;
        }
      }
    }

    const checkVerticalServicesOverLap = services.some((serv) => {
      const serviceHeight = serv.height / 0.056;
      return (
        serv.u_id !== service.u_id &&
        ((newDropLocation.y !== serv.positions?.y &&
          newDropLocation.y + dragServiceHeight > serv.positions?.y &&
          newDropLocation.y + dragServiceHeight <
            serv.positions?.y + serviceHeight + serviceMargin) ||
          (newDropLocation.y > serv.positions?.y &&
            newDropLocation.y <
              serv.positions?.y + serviceHeight + serviceMargin) ||
          (newDropLocation.y === serv.positions?.y &&
            newDropLocation.y > serv.positions?.y &&
            newDropLocation.y <
              serv.positions?.y + serviceHeight + serviceMargin))
      );
    });
    // console.log({ checkVerticalServicesOverLap });
    if (checkVerticalServicesOverLap) {
      dropLocation = false;
      warningAlert("Sorry, you cannot place new services here .");
      return;
    }
  }
  // if service dropped away from other services
  if (!newDropLocation.y) newDropLocation["y"] = dragServicePosition.y;

  //filter normal services while dragging simple service
  if (!service?.is_slide)
    services = services.filter((ser) => ser.positions.y === newDropLocation.y);
  //filtering normal services while dragging slide service
  else
    services = services.filter((ser) => {
      const servHeight = ser.height / 0.056;
      return (
        (ser.positions.y + manageServiceY <= newDropLocation.y &&
          ser.positions.y + servHeight + manageServiceY >= newDropLocation.y) || //for top
        (ser.positions.y + manageServiceY <=
          newDropLocation.y + dragServiceHeight &&
          ser.positions.y + manageServiceY >= newDropLocation.y)
      );
    });
  // console.log({services})
  //filtering slide services based on the position of service
  let slideServices = [];
  if (!service?.is_slide)
    slideServices = servicesArr.filter((ser) => {
      const servHeight = ser.height / 0.056;
      return (
        ser?.is_slide &&
        ((ser.positions.y <= newDropLocation.y + manageServiceY &&
          ser.positions.y + servHeight >= newDropLocation.y + manageServiceY) || //for top
          (ser.positions.y <=
            newDropLocation.y + dragServiceHeight + manageServiceY &&
            ser.positions.y >= newDropLocation.y + manageServiceY))
      );
    });
  else {
    slideServices = servicesArr.filter((ser) => {
      const servHeight = ser.height / 0.056;
      return (
        ser?.is_slide &&
        ((ser.positions.y <= newDropLocation.y &&
          ser.positions.y + servHeight >= newDropLocation.y) || //for top
          (ser.positions.y <= newDropLocation.y + dragServiceHeight &&
            ser.positions.y >= newDropLocation.y))
      );
    });
  }

  services = [...services, ...slideServices];
  // console.log({ services });
  //for x-axis
  for (let i = 0; i < services?.length; i++) {
    const serviceWidth = services[i].width / 0.056;
    const serviceHeight = services[i].height / 0.056;
    //calculation for left side drag
    //calculation for normal services
    if (services[i].u_id !== service.u_id) {
      if (
        !service?.is_slide &&
        !services[i]?.is_slide &&
        dragServicePosition.x >= services[i]?.positions?.x &&
        dragServicePosition.x <=
          services[i]?.positions?.x + serviceWidth + railMargins.between
      ) {
        checkServicesIntersecting["leftTouched"] = true;
        const newPosition =
          services[i]?.positions?.x + serviceWidth + railMargins.between;
        //to check the margins between last service and rail width
        if (newPosition + dragServiceWidth <= droppableAreaWidth)
          newDropLocation["x"] = newPosition;
        else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      } //calulation for right side drag
      else if (
        !service?.is_slide &&
        !services[i]?.is_slide &&
        dragServicePosition.x + dragServiceWidth >=
          services[i]?.positions?.x - railMargins.between &&
        dragServicePosition.x + dragServiceWidth <=
          services[i]?.positions?.x + serviceWidth
      ) {
        // console.log("2")
        checkServicesIntersecting["rightTouched"] = true;
        const newPosition =
          services[i]?.positions?.x - dragServiceWidth - railMargins.between;
        //to check the margins between first service and rail width
        if (newPosition >= railMargins.start)
          newDropLocation["x"] = newPosition;
        else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      }
      //calculation for slide services if dragged service is slide and service[i] is also slide
      else if (
        service?.is_slide &&
        services[i]?.is_slide &&
        dragServicePosition.x >= services[i]?.positions?.x &&
        dragServicePosition.x <= services[i]?.positions?.x + serviceWidth
      ) {
        // console.log("3")
        checkServicesIntersecting["leftTouched"] = true;
        let newPosition;
        if (
          dragServicePosition.y >= services[i]?.positions?.y + serviceHeight ||
          dragServicePosition.y + dragServiceHeight <= services[i]?.positions?.y
        )
          newPosition = services[i]?.positions?.x;
        else newPosition = services[i]?.positions?.x + serviceWidth;
        //to check the margins between last service and rail width
        if (newPosition + dragServiceWidth <= railDimensionsRef.current.width) {
          newDropLocation = {
            x: newPosition,
            y: dragServicePosition.y,
          };
        } else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      } //calulation for right side drag
      else if (
        service.is_slide &&
        services[i]?.is_slide &&
        dragServicePosition.x + dragServiceWidth >= services[i]?.positions?.x &&
        dragServicePosition.x + dragServiceWidth <=
          services[i]?.positions?.x + dragServiceWidth
      ) {
        // console.log("4")
        checkServicesIntersecting["rightTouched"] = true;
        let newPosition;
        if (
          dragServicePosition.y > services[i]?.positions?.y + serviceHeight ||
          dragServicePosition.y + dragServiceHeight <= services[i]?.positions?.y
        )
          newPosition = services[i]?.positions?.x;
        else newPosition = services[i]?.positions?.x - serviceWidth;

        //to check the margins between first service and rail width
        if (newPosition >= railMargins.start) {
          newDropLocation = {
            x: newPosition,
            y: dragServicePosition.y,
          };
        } else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      }
      //calculation for slide services if dragged service is slide and service[i] is normal service
      else if (
        service?.is_slide &&
        !services[i]?.is_slide &&
        dragServicePosition.x >=
          services[i]?.positions?.x + extraMarginForNormalServices &&
        dragServicePosition.x <=
          services[i]?.positions?.x +
            extraMarginForNormalServices +
            serviceWidth
      ) {
        // console.log("5")
        let newPosition;
        checkServicesIntersecting["leftTouched"] = true;
        if (
          dragServicePosition.y >=
            services[i]?.positions?.y + serviceHeight + manageServiceY ||
          dragServicePosition.y + dragServiceHeight <=
            services[i]?.positions?.y + manageServiceY
        )
          newPosition = services[i]?.positions?.x;
        else
          newPosition =
            services[i]?.positions?.x +
            extraMarginForNormalServices +
            serviceWidth;
        //to check the margins between last service and rail width
        if (newPosition + dragServiceWidth <= railDimensionsRef.current.width) {
          newDropLocation = {
            x: newPosition,
            y: dragServicePosition.y,
          };
        } else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      } //calulation for right side drag
      else if (
        service.is_slide &&
        !services[i]?.is_slide &&
        dragServicePosition.x + dragServiceWidth >=
          services[i]?.positions?.x + extraMarginForNormalServices &&
        dragServicePosition.x + dragServiceWidth <=
          services[i]?.positions?.x +
            extraMarginForNormalServices +
            dragServiceWidth
      ) {
        // console.log("6")
        checkServicesIntersecting["rightTouched"] = true;
        let newPosition;
        if (
          dragServicePosition.y > services[i]?.positions?.y + serviceHeight ||
          dragServicePosition.y + dragServiceHeight <= services[i]?.positions?.y
        )
          newPosition = services[i]?.positions?.x;
        else
          newPosition =
            services[i]?.positions?.x +
            extraMarginForNormalServices -
            dragServiceWidth;

        //to check the margins between first service and rail width
        if (newPosition >= railMargins.start) {
          newDropLocation = {
            x: newPosition,
            y: dragServicePosition.y,
          };
        } else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      }

      //calculation for slide services if dragged service is normal and service[i] is slide service
      else if (
        !service?.is_slide &&
        services[i]?.is_slide &&
        dragServicePosition.x + extraMarginForNormalServices >=
          services[i]?.positions?.x &&
        dragServicePosition.x + extraMarginForNormalServices <=
          services[i]?.positions?.x + serviceWidth
      ) {
        // console.log("7")
        checkServicesIntersecting["leftTouched"] = true;
        const newPosition =
          services[i]?.positions?.x -
          extraMarginForNormalServices +
          serviceWidth;
        //to manage the normal service width with the slide service
        let newArr = JSON.parse(JSON.stringify(services));
        newArr[i].positions.x =
          services[i]?.positions?.x - extraMarginForNormalServices;
        //to check the margins between last service and rail width
        if (newPosition + dragServiceWidth <= droppableAreaWidth) {
          newDropLocation["x"] = newPosition;
        } else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      } //calulation for right side drag
      else if (
        !service?.is_slide &&
        services[i]?.is_slide &&
        dragServicePosition.x +
          dragServiceWidth +
          extraMarginForNormalServices >=
          services[i]?.positions?.x &&
        dragServicePosition.x +
          dragServiceWidth +
          extraMarginForNormalServices <=
          services[i]?.positions?.x + dragServiceWidth
      ) {
        // console.log("8")
        checkServicesIntersecting["rightTouched"] = true;
        const newPosition =
          services[i]?.positions?.x -
          extraMarginForNormalServices -
          dragServiceWidth;
        //to check the margins between first service and rail width
        if (newPosition >= railMargins.start) {
          newDropLocation["x"] = newPosition;
        } else {
          dropLocation = false;
          warningAlert("Sorry, you cannot place new services here .");
        }
        break;
      }
    }
  }
  // console.log({ newDropLocation });
  const checkHorizontalServicesOverLap = services.some((serv) => {
    const serviceWidth = serv.width / 0.056;
    if (
      (!serv?.is_slide && !service?.is_slide) ||
      (serv?.is_slide && service?.is_slide)
    ) {
      return (
        serv.u_id !== service.u_id &&
        (serv.positions?.x === newDropLocation.x ||
          (newDropLocation.x + dragServiceWidth > serv.positions?.x &&
            newDropLocation.x + dragServiceWidth <
              serv.positions?.x + serviceWidth) ||
          (newDropLocation.x > serv.positions.x &&
            newDropLocation.x < serv.positions.x + serviceWidth) ||
          (newDropLocation.x < serv.positions.x &&
            newDropLocation.x + dragServiceWidth >
              serv.positions?.x + serviceWidth))
      );
    } else if (!serv?.is_slide && service?.is_slide) {
      return (
        serv.u_id !== service.u_id &&
        (serv.positions?.x + extraMarginForNormalServices ===
          newDropLocation.x ||
          (newDropLocation.x + dragServiceWidth >
            serv.positions?.x + extraMarginForNormalServices &&
            newDropLocation.x + dragServiceWidth <
              serv.positions?.x +
                serviceWidth +
                extraMarginForNormalServices) ||
          (newDropLocation.x >
            serv.positions.x + extraMarginForNormalServices &&
            newDropLocation.x <
              serv.positions.x + serviceWidth + extraMarginForNormalServices))
      );
    } else if (serv?.is_slide && !service?.is_slide) {
      return (
        serv.u_id !== service.u_id &&
        (serv.positions?.x ===
          newDropLocation.x + extraMarginForNormalServices ||
          (newDropLocation.x + dragServiceWidth + extraMarginForNormalServices >
            serv.positions?.x &&
            newDropLocation.x +
              dragServiceWidth +
              extraMarginForNormalServices <
              serv.positions?.x + serviceWidth) ||
          (newDropLocation.x + extraMarginForNormalServices >
            serv.positions.x &&
            newDropLocation.x + extraMarginForNormalServices <
              serv.positions.x + serviceWidth) ||
          //service overlapping over slide service
          (newDropLocation.x + extraMarginForNormalServices <
            serv.positions.x &&
            newDropLocation.x +
              extraMarginForNormalServices +
              dragServiceWidth >
              serv.positions.x + serviceWidth))
      );
    } else return false;
  });
  // console.log({ checkHorizontalServicesOverLap });
  if (checkHorizontalServicesOverLap) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
    return;
  }
  if (
    (checkServicesIntersecting.leftTouched &&
      checkServicesIntersecting.rightTouched) ||
    (checkServicesIntersecting.topTouched &&
      checkServicesIntersecting.bottomTouched)
  ) {
    dropLocation = false;
    warningAlert("Sorry, you cannot place new services here .");
  } else if (
    checkServicesIntersecting.leftTouched ||
    checkServicesIntersecting.rightTouched ||
    checkServicesIntersecting.topTouched ||
    checkServicesIntersecting.bottomTouched
  )
    dragPositions.current = newDropLocation;
  return dropLocation;
};

const handleInfinityAxiomDrop = (
  e,
  rails,
  railIndex,
  dragRailIndex,
  values,
  dragPositions,
  railDimensionsRef,
  railMargins,
  dropLocation,
  service,
  // serviceIndex,
  dragItemNode
) => {
  const serviceIndex = rails[dragRailIndex]?.services?.findIndex(
    (serv) => serv.u_id === service.u_id
  );
  if (dropLocation && railIndex !== null && dragItemNode.current !== e.target) {
    if (dragRailIndex !== railIndex) {
      if (dragRailIndex !== null)
        //if user is dragging from one rail to another then remove from dragging rail
        rails[dragRailIndex].services.splice(serviceIndex, 1);
      //drop  services into rails
      const newService = {
        ...service,
        x_distance_inches: calculateXDistance(
          dragPositions,
          railMargins,
          railDimensionsRef,
          values,
          service
        ),
        y_distance_inches: calculateYDistance(dragPositions),
        u_id: generateUniqueId(),
      };
      rails[railIndex].services.push({
        ...newService,
        positions: dragPositions.current,
      });
    } else {
      //moving service inside the rail
      rails[railIndex].services[serviceIndex].positions = dragPositions.current;
      rails[railIndex].services[serviceIndex].x_distance_inches =
        calculateXDistance(
          dragPositions,
          railMargins,
          railDimensionsRef,
          values,
          service
        );
      rails[railIndex].services[serviceIndex].y_distance_inches =
        calculateYDistance(dragPositions);
    }
  } else if (railIndex === null && dragRailIndex !== null) {
    //delete service from rail if dragged to the wall
    rails[dragRailIndex].services.splice(serviceIndex, 1);
  }
};

const handleCompassDrop = (
  e,
  rails,
  railIndex, //where service get dropped
  dragRailIndex, //form where service is dragged
  values,
  dragPositions,
  railDimensionsRef,
  railMargins,
  dropLocation,
  service,
  // serviceIndex,
  dragItemNode,
  rowIndex,
  dragRowIndex
) => {
  const serviceIndex = rails[dragRailIndex]?.services.findIndex(
    (serv) => serv.u_id === service.u_id
  );

  if (dropLocation && railIndex !== null && dragItemNode.current !== e.target) {
    if (dragRailIndex !== railIndex) {
      if (dragRailIndex !== null) {
        rails[dragRailIndex].services.splice(serviceIndex, 1);
      }
      //drop  services into rails
      const newService = {
        ...service,
        x_distance_inches: calculateXDistance(
          dragPositions,
          railMargins,
          railDimensionsRef,
          values,
          service
        ),
        y_distance_inches: dragPositions.current.y * 0.056,
        rowPosition: service?.is_slide ? null : rowIndex,
        u_id: generateUniqueId(),
      };
      rails[railIndex].services.push({
        ...newService,
        positions: dragPositions.current,
      });
    } else {
      //moving service inside the rail
      //while moving from one row to another in same rail
      if (!service.is_slide) {
        if (dragRowIndex !== rowIndex) {
          rails[railIndex].services[serviceIndex].rowPosition = rowIndex;
          rails[railIndex].services[serviceIndex].positions =
            dragPositions.current;
          rails[railIndex].services[serviceIndex].x_distance_inches =
            calculateXDistance(
              dragPositions,
              railMargins,
              railDimensionsRef,
              values
            );
          rails[railIndex].services[serviceIndex].y_distance_inches =
            dragPositions.current.y * 0.056;
        }
        //while moving in same row
        else {
          rails[railIndex].services[serviceIndex].positions =
            dragPositions.current;
          rails[railIndex].services[serviceIndex].x_distance_inches =
            calculateXDistance(
              dragPositions,
              railMargins,
              railDimensionsRef,
              values
            );
          rails[railIndex].services[serviceIndex].y_distance_inches =
            dragPositions.current.y * 0.056;
        }
      } else {
        rails[railIndex].services[serviceIndex].positions =
          dragPositions.current;
        rails[railIndex].services[serviceIndex].x_distance_inches =
          calculateXDistance(
            dragPositions,
            railMargins,
            railDimensionsRef,
            values
          );
        rails[railIndex].services[serviceIndex].y_distance_inches =
          dragPositions.current.y * 0.056;
      }
    }
  } else if (railIndex === null && dragRailIndex !== null) {
    rails[dragRailIndex].services.splice(serviceIndex, 1);
  }
};

const handleArrayDrop = (
  e,
  rails,
  railIndex, //where service get dropped
  dragRailIndex, //form where service is dragged
  values,
  dragPositions,
  railDimensionsRef,
  railMargins,
  dropLocation,
  service,
  dragItemNode,
  rowIndex,
  dragRowIndex
) => {
  const serviceIndex = rails[dragRailIndex]?.services?.findIndex(
    (serv) => serv.u_id === service.u_id
  );

  //to manage the y axis while user dragging normal services
  let manageServiceY = 0;
  if (!service.is_slide) {
    if (rowIndex === 1) manageServiceY = 2.875;
    else if (rowIndex === 2) {
      const ceilingHeight =
        values.ceiling_height.ft * 12 + values.ceiling_height.in;
      manageServiceY = ceilingHeight - 64;
    } else if (rowIndex === 3) {
      const ceilingHeight =
        values.ceiling_height.ft * 12 + values.ceiling_height.in;
      manageServiceY = ceilingHeight - 34;
    }
  }

  if (dropLocation && railIndex !== null && dragItemNode.current !== e.target) {
    if (dragRailIndex !== railIndex) {
      if (dragRailIndex !== null) {
        rails[dragRailIndex].services.splice(serviceIndex, 1);
      }
      //drop  services into rails
      const newService = {
        ...service,
        x_distance_inches: calculateXDistance(
          dragPositions,
          railMargins,
          railDimensionsRef,
          values,
          service
        ),
        y_distance_inches: service?.is_slide
          ? dragPositions.current.y * 0.056
          : dragPositions.current.y * 0.056 + manageServiceY,
        rowPosition: rowIndex,
        u_id: generateUniqueId(),
      };
      rails[railIndex].services.push({
        ...newService,
        positions: dragPositions.current,
      });
    } else {
      //moving service inside the rail
      //while moving from one row to another in same rail
      if (!service.is_slide) {
        if (dragRowIndex !== rowIndex) {
          rails[dragRailIndex].services[serviceIndex].rowPosition = rowIndex;

          rails[dragRailIndex].services[serviceIndex].positions =
            dragPositions.current;
          rails[dragRailIndex].services[serviceIndex].x_distance_inches =
            calculateXDistance(
              dragPositions,
              railMargins,
              railDimensionsRef,
              values
            );
          rails[dragRailIndex].services[serviceIndex].y_distance_inches =
            dragPositions.current.y * 0.056 + manageServiceY;
        }
        //while moving in same row
        else {
          rails[dragRailIndex].services[serviceIndex].positions =
            dragPositions.current;
          rails[dragRailIndex].services[serviceIndex].x_distance_inches =
            calculateXDistance(
              dragPositions,
              railMargins,
              railDimensionsRef,
              values
            );
          rails[dragRailIndex].services[serviceIndex].y_distance_inches =
            dragPositions.current.y * 0.056 + manageServiceY;
        }
      } else {
        rails[dragRailIndex].services[serviceIndex].x_distance_inches =
          calculateXDistance(
            dragPositions,
            railMargins,
            railDimensionsRef,
            values
          );
        rails[dragRailIndex].services[serviceIndex].y_distance_inches =
          calculateYDistance(dragPositions);
        rails[dragRailIndex].services[serviceIndex].positions =
          dragPositions.current;
      }
    }
  } else if (railIndex === null && dragRailIndex !== null) {
    //delete service from rail if dragged to the wall
    rails[dragRailIndex].services.splice(serviceIndex, 1);
  }
};

// for compass to get drop points
const getDropPoints = (divWidth) => {
  const addend = 16.183035714; //16 px for 0.90625000 inches
  const subtractValue = 8.091517857;
  const sideMargin = 5.296428571; // last margin on the sides of the rails
  const rowWidth = (divWidth - 5.5) / 0.056;
  const center = (divWidth - 5.5) / 2 / 0.056;

  let startingPoint = center - subtractValue;

  let innerPoints = [];

  // Subtract until the last value is greater than 16.196428571
  while (startingPoint > sideMargin) {
    innerPoints.push(startingPoint);
    startingPoint -= addend;
  }

  // Reset starting point for addition
  startingPoint = center - subtractValue + addend;

  // Add until the last value is less than 116.28
  while (startingPoint < rowWidth - sideMargin) {
    innerPoints.push(startingPoint);
    startingPoint += addend;
  }

  return innerPoints;
};

const findNearestValue = (arr, target) => {
  let nearestValue = arr[0];
  let minDifference = Math.abs(target - nearestValue);

  for (let i = 1; i < arr?.length; i++) {
    const difference = Math.abs(target - arr[i]);

    if (difference < minDifference) {
      minDifference = difference;
      nearestValue = arr[i];
    }
  }

  return nearestValue;
};

const calculateXDistance = (
  dragPositions,
  railMargins,
  railDimensionsRef,
  values,
  service
) => {
  const currentPosition = dragPositions.current.x;
  const railDim = railDimensionsRef.current?.width / 2;
  const { chased, chase_position, model } = values;
  if (chased && chase_position === "center" && currentPosition > railDim) {
    const distance = (currentPosition + railMargins.between) * 0.056;
    return distance + 4.304; // adding extra 4 inches to manage 13inches for center chase
  } else {
    //calculations for axiom service gas
    if (model === PRODUCT.AXIOM && service?.is_slide) {
      return currentPosition * 0.056;
    } else if (model === PRODUCT.ARRAY) return currentPosition * 0.056;
    else if (model === PRODUCT.COMPASS) {
      if (service?.is_slide) return currentPosition * 0.056;
      else if (
        service?.name.includes("Call") ||
        service?.name.includes("Staff") ||
        service?.name.includes("Code Blue")
      )
        return currentPosition * 0.056 + 0.5;
      else return currentPosition * 0.056 + 0.38;
    } else return (currentPosition + railMargins.between) * 0.056;
  }
};

const calculateYDistance = (dragPositions) => {
  const currentPosition = dragPositions.current.y;
  return (currentPosition + 13) * 0.056;
};

const convertInchesToFeetAndInches = (totalInches) => {
  // Calculate the number of feet
  const feet = Math.floor(totalInches / 12);
  // Calculate the remaining inches
  const inches = totalInches % 12;

  return { ft: feet, in: inches, ftError: "" };
};

const headwallData = (headwalls, model) => {
  const transformedData = {
    headwalls: headwalls?.map((headwall) => {
      return {
        width: model === PRODUCT.COMPASS ? headwall.width : headwall.width / 12,
        position: headwall.position,
        services: headwall.services.map((service) => {
          return {
            _id: service.service_type_id,
            rowPosition: service?.rowPosition,
            x_distance_inches: service.x_distance_inches,
            y_distance_inches: service.y_distance_inches,
            specification: service?.specification,
            is_slide: service.is_slide,
            name: service.name,
            image_url: service.service_image,
            height: service.dimension.height,
            width: service.dimension.width,
            gang_width: service.dimension.gang_width,
            u_id: service.u_id,
            positions: service.positions,
            category_id: service.category_id,
            quote_requested: service.quote_requested,
            cad_requested: service.cad_requested,
            category: service?.category_name,
          };
        }),
      };
    }),
  };
  return transformedData;
};

export {
  calculateXDistance,
  calculateYDistance,
  checkInfinityDropLocation,
  checkAxiomDropLocation,
  checkCompassDropLocation,
  checkArrayDropLocation,
  handleInfinityAxiomDrop,
  handleCompassDrop,
  handleArrayDrop,
  convertInchesToFeetAndInches,
  headwallData,
};
