import React, { useMemo } from "react";
import { LogoIcon } from "assets/images";
import { Link, useLocation } from "react-router-dom";
import { HeaderProfileLogo } from "components";

function Header() {
  const location = useLocation();

  const showLogo = useMemo(() => {
    if (
      location.pathname === "/" ||
      location.pathname === "/signup" ||
      location.pathname === "/forgot-password"||
      location.pathname === "/reset-password"
    )
      return false;
    else return true;
  }, [location.pathname]);
  return (
    <>
      <div className="header headerMain flex items-center justify-between px-0">
        <div className="headerBg  d-inline-block">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="314"
            height="233"
            viewBox="0 0 314 233"
            fill="none"
          >
            <path
              d="M313.5 0H0V232.5C11.2 208.5 24 207.5 37.5 206C130 203.5 159.5 209.5 208 206C252.856 202.763 262.5 195.5 286 173.5C304.8 155.9 311.667 126.167 313 113.5L313.5 104V0Z"
              fill="#FFE8C9"
            />
          </svg>

          <Link to={"https://www.hsiheadwalls.com/"} target="blank">
            <img
              src={LogoIcon}
              alt="img"
              className=" p-1 rounded cursor-pointer"
            />
          </Link>
        </div>
        {showLogo && (
          <div className="header relative flex items-center justify-end px-0 ">
            <HeaderProfileLogo />
          </div>
        )}
      </div>
    </>
  );
}

export default Header;
