import React from "react";
import { GroupActiveIcon, GroupInactiveIcon } from "assets/images";

function Stepper({ currentStep }) {
  const steps = ["Product", "Design Panel", "Services", "Review"];

  const activeSteps = {
    1: "Product",
    2: "Product",
    3: "Design Panel",
    4: "Services",
    5: "Review",
  };

  return (
    <div className="flex justify-between stepper">
      {steps.map((step, i) => (
        <div
          key={i}
          className={`step_item flex items-center  ${
            activeSteps[currentStep] === step && "active_step"
          }   
          ${i + 2 < currentStep && "active_step"}
          `}
        >
          <div className="innerStep sm:w-full">
            <img
              src={GroupInactiveIcon}
              className="inactiveIcon"
              alt="...img"
            />
            <img src={GroupActiveIcon} className="activeIcon" alt="...img" />
          </div>
          <p className="font-semibold text-xs text-state-300 whitespace-nowrap">
            {step}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Stepper;
