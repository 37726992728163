import React, {
  useRef,
  useEffect,
  useState,
  useMemo,
  Fragment,
  forwardRef,
} from "react";
import Chase from "./headwall-components/chase";
import { useServiceStore } from "store";
import { GRAINS } from "constants/appConstant";
import Wrapper from "./headwall-components/wrapper";
import { HorizontalRuler } from "components";

const chasePosition = {
  left: "items-start",
  center: "items-center",
  right: "items-end",
};

const manageChasePosition = {
  // left: "justify-center",
  center: "w-full",
  // right: "justify-center",
};

const nonDroppableArea = {
  no: "non_dropable_without_chase",
  left: "non_dropable_left_chase",
  right: "non_dropable_right_chase",
  center: "non_dropable_without_chase",
};

const rail_position = {
  middle: "rail_center",
  top: "rail_top",
  bottom: "rail_bottom",
};

const InfinityPanel = forwardRef((props, ref) => {
  const {
    values,
    ruler,
    handleDragStart,
    handleonDragOver,
    handleDrop,
    handleDragEnter,
    dragEntered,
    wallLength,
  } = props;
  const { setService } = useServiceStore();
  const heightRef = useRef(null);
  const [chaseHeight, setChaseHeight] = useState(0);
  const {
    color,
    chase_position,
    chased,
    headwalls,
    width,
    add_rail,
    background,
    ceiling_height,
    model,
  } = values;

  useEffect(() => {
    const handleChaseHeight = () => {
      const lastChild = heightRef?.current?.lastElementChild;
      // Get the second last child element
      const secondLastChild = lastChild.previousElementSibling;
      // if ruler is added then select second last child other wise last child
      const finalChild = ruler ? secondLastChild : lastChild;
      const outerDivOffsetTop = heightRef.current?.offsetTop;
      const childTopOffset = finalChild?.offsetTop - outerDivOffsetTop;

      // Calculate the height until the child element
      const height = childTopOffset + finalChild?.offsetHeight;
      return height;
    };

    const height = handleChaseHeight();
    setChaseHeight(height);
  }, [add_rail, headwalls, chase_position, chased, width, ruler,ceiling_height]);

  //adding some extra pixels in outer div to handle scroll issue while for left and right chase
  const widthCaluclation = useMemo(() => {
    // return Math.round((width.ft * 12 + width.in) / 0.056) + 200;
    if (!chased) return Math.round((width.ft * 12 + width.in) / 0.056) + 100;
    else return Math.round((width.ft * 12 + width.in) / 0.056) + 250;
  }, [width, chased]);

  //chase width calculation
  const railWidth = useMemo(() => {
    if (chased && chase_position === "center")
      return Math.round((width.ft * 12 + width.in) / 0.056) + 171;
    else return Math.round((width.ft * 12 + width.in) / 0.056);
  }, [chased, chase_position, width]);

  const serviceWidth = (ser) => {
    return ser.gang_width / 0.056;
  };

  const servicePosition = (ser) => {
    if (ser?.is_slide) return `translate(${ser.positions?.x}px, ${80}px)`;
    else return `translate(${ser.positions?.x}px, ${0}px)`;
  };

  const generateStyle = (ser) => {
    const styleObj = {
      cursor: "grab",
    };
    const isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor);
    if (isSafari) {
      styleObj.left = ser.positions.x;
      styleObj.top = ser?.is_slide ? "80px" : "0px";
    } else styleObj.transform = servicePosition(ser);
    return styleObj;
  };

  const wallHeight = useMemo(() => {
    return Math.round((ceiling_height.ft * 12 + ceiling_height.in) / 0.056);
  }, [ceiling_height]);

  useEffect(() => {
    //to adjust scale for axiom
    // Select all elements with the class 'scale'
    if (ruler) {
      const firstElements = document.querySelector(".first");
      const nextElements = document.querySelectorAll(".next");

      // Update styles for 'first' elements
      firstElements.style.maxWidth = "220.285714285px";

      nextElements.forEach((element) => {
        element.style.maxWidth = "214.285714285px";
      });
      // Cleanup function to revert styles when component unmounts
      return () => {
        firstElements.style.maxWidth = "";
        nextElements.forEach((element) => {
          element.style.maxWidth = "";
        });
      };
    }
  }, [ruler, width]);

  const rulerWidth = useMemo(() => {
    //added 2 ft for bed
    let totalFeet = 0;
    let totalInches = 0;
    if (chase_position === "center") {
      totalFeet = width.ft;
      totalInches = width.in + 9; //adding 9 inches to manage center chase of 13 inches
      if (totalInches >= 12) {
        totalFeet += Math.floor(totalInches / 12);
        totalInches = totalInches % 12;
      }
    } else {
      totalFeet = width.ft;
      totalInches = width.in;
    }

    return { ft: totalFeet, in: totalInches };
  }, [width, chase_position]);

  return (
    <>
      <Wrapper
        ruler={ruler}
        width={widthCaluclation}
        wallHeight={wallHeight}
        wallLength={wallLength}
        railWidth={railWidth}
        ceiling_height={ceiling_height}
        model={model}
        chase_position={chase_position}
        ref={ref}
      >
        <div
          className={`h-full pb-5 flex justify-center ${
            wallLength > railWidth + 20 ? "justify-center" : ""
          }`}
        >
          <div
            className={`h-full flex relative border-y-2 my-3
            ${!chased ? "justify-center" : ""}
            ${
              chase_position === "center"
                ? `${chasePosition[chase_position]} flex-col`
                : ""
            }`}
          >
            {chased &&
            (chase_position === "left" || chase_position === "center") ? (
              <Chase
                chase_color={color}
                background_image={GRAINS[background]}
                height={chaseHeight}
                chase_position={chase_position}
              />
            ) : null}
            <div
              className={`flex flex-col h-full relative justify-end ${
                chased ? manageChasePosition[chase_position] : " w-full"
              }`}
              ref={heightRef}
              // style={{ width: railWidth+10  }}
              style={{
                width:
                  chase_position === "center" || !chased
                    ? railWidth + 10
                    : railWidth,
              }}
            >
              <>
                {headwalls?.map((rail, railIndex) => (
                  <Fragment key={railIndex}>
                    <div
                      className={`w-max headwall_outer ${
                        rail_position[rail.position]
                      } 
                    ${
                      chased && chase_position === "left"
                        ? "chase_left_headwall_outer"
                        : chase_position === "right"
                        ? "chase_right_headwall_outer"
                        : ""
                    }`}
                      onDrop={(e) => handleDrop(e, "rail", railIndex)}
                      onDragOver={(e) => handleonDragOver(e, "rail")}
                      onDragEnter={handleDragEnter}
                    >
                      <div className="headwall">
                        <div
                          className={`relative h-20 flex w-full ${
                            dragEntered ? nonDroppableArea[chase_position] : ""
                          }`}
                          style={{
                            backgroundColor: color,
                            width: railWidth,
                            backgroundImage: `url(${GRAINS[background]})`,
                          }}
                          onDrop={(e) => handleDrop(e, "rail", railIndex)}
                          onDragOver={(e) => handleonDragOver(e, "rail")}
                          // onDragEnter={handleDragEnter}
                        >
                          {chased &&
                            dragEntered &&
                            chase_position === "center" && (
                              <div
                                className="chase_center"
                                onDragEnter={(e) => e.stopPropagation()}
                              ></div>
                            )}
                          {rail.services.map((ser, serviceIndex) => (
                            <Fragment key={serviceIndex}>
                              <div
                                draggable={true}
                                draggableid={ser._id}
                                className={`service_main absolute py-1 flex justify-center h-full items-center ${
                                  dragEntered ? "non_dropable " : ""
                                }`}
                                style={generateStyle(ser)}
                                onDragStart={(e) =>
                                  handleDragStart(
                                    ser,
                                    e,
                                    railIndex,
                                    serviceIndex
                                  )
                                }
                                onDragEnter={(e) => e.stopPropagation()}
                                onClick={() => setService(ser, true)}
                              >
                                <div
                                  draggable={"false"}
                                  className="service_img pointer-events-none"
                                  style={{
                                    width: serviceWidth(ser),
                                    backgroundImage: `url(${ser.image_url})`,
                                    backgroundSize: "contain",
                                    backgroundRepeat: "no-repeat",
                                    backgroundPosition: "center",
                                  }}
                                  onDragEnter={(e) => e.stopPropagation()}
                                />
                              </div>
                            </Fragment>
                          ))}
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ))}
                {ruler && (
                  <HorizontalRuler
                    class_name={"w-full bottom-[14px] z-50"}
                    width={rulerWidth}
                  />
                )}
              </>
            </div>
            {chased && chase_position === "right" ? (
              <Chase
                chase_color={color}
                background_image={GRAINS[background]}
                height={chaseHeight}
                chase_position={chase_position}
              />
            ) : null}
          </div>
        </div>
      </Wrapper>
    </>
  );
});

export default InfinityPanel;
