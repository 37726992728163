import { create } from "zustand";

const initialvalues = {
  name: "",
  device_type: "Desktop",
  design_image: "",
  model: "",
  sub_model: "",
  width: { ft: 3, in: 0, ftError: "" },
  ceiling_height: { ft: 8, in: 0, ftError: "" },
  chased: false,
  quote_requested: false,
  cad_requested: false,
  chase_position: "no",
  color: "#384957",
  background: "",
  add_rail: "no",
  comment: "",
  headwalls: [{ position: "middle", services: [], width: 0 }],
  step: 1,
  custom_finish: "",
  span_window: false,
};

// Define your Zustand store
const useHeadWallStore = create((set) => ({
  values: { ...initialvalues },
  // Generic update function
  setValues: (newValues) =>
    set((state) => ({ values: { ...state.values, ...newValues } })),
  resetState: () => set({ values: initialvalues }),
}));

export default useHeadWallStore;
