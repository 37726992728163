import React, { forwardRef } from "react";
import { PRODUCT } from "constants/appConstant";
import InfinityPanel from "./Infinity";
import Axiom from "./Axiom";
import Compass from "./Compass";
import Array from "./Array";

const HeadWalls = forwardRef((props, ref) => {
  const { values } = props;
  return (
    <>
      {(() => {
        switch (values.model) {
          case PRODUCT.INFINITY:
            return <InfinityPanel ref={ref} {...props} />;
          case PRODUCT.AXIOM:
            return <Axiom ref={ref} {...props} />;
          case PRODUCT.COMPASS:
            return <Compass ref={ref} {...props} />;
          case PRODUCT.ARRAY:
            return <Array ref={ref} {...props} />;
          default:
            return null;
        }
      })()}
    </>
  );
});

export default HeadWalls;
