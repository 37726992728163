import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { DiscardIcon } from "assets/images";
import { Button } from "components";
import { deleteAlert, errorAlert } from "config/sweet-alert/alert";
import { deleteDesign } from "services/authServices";
import useLoaderStore from "store/loaderStore";

function HeadwallImage({
  designImg,
  id,
  fileURL,
  name,
  model,
  handleShowImage,
  handleListDesigns,
  handleDownloadDesign,
}) {
  const { setLoading } = useLoaderStore();

  //function mutation
  const { mutate, isLoading } = useMutation({
    mutationFn: deleteDesign,
    onSuccess: () => {
      handleListDesigns();
      handleShowImage(false);
    },
    onError: (err) => {
      errorAlert(err.response.data.message);
    },
  });

  //manage loader
  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);

  const handleDelete = () => {
    deleteAlert(
      handleDiscard,
      "Are you sure you want to delete your design?",
      "Please keep in mind that entire design will be deleted and this process cannot be reversible.",
      "Yes, Delete"
    );
  };

  const handleDiscard = () => {
    mutate(id);
  };

  return (
    <>
      <div className="w-100 d-inline-block text-xl font-extrabold text-black">
        <h4>
          {model}/{name}
        </h4>
      </div>
      <div className="w-100 my-2 flex justify-end">
        <img
          src={DiscardIcon}
          alt="...img"
          className="ml-5 cursor-pointer"
          onClick={handleDelete}
          data-te-toggle="tooltip"
          data-te-placement="top"
          data-te-ripple-init
          data-te-ripple-color="light"
          title="Delete Design"
        />
      </div>
      <div className="w-100 imgDesignDiv">
        <img className="" src={designImg} alt="...img" />
      </div>
      <div className="flex justify-end">
        <Button
          className="btnTxt mt-2 px-7 py-1 border border-teal-700 text-teal-700  bg-white font-semibold  rounded cursor-pointer "
          text={"Back"}
          handleClick={() => handleShowImage(false)}
          type={"button"}
        />
        <Button
          className="login_btn btnTxt  text-white cursor-pointer rounded mt-2 px-5 mx-2"
          text={"Download"}
          handleClick={() => handleDownloadDesign(fileURL, name)}
          type={"button"}
        />
      </div>
    </>
  );
}

export default HeadwallImage;
