import axios from "axios";
import { AUTH, BASE_URL } from "./appConstant";

const httpClient = axios.create({
  baseURL: BASE_URL,
});

httpClient.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("user")) {
      const { token } = JSON.parse(localStorage.getItem("user"));
      if (token) config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const generateRefreshToken = async (token) => {
  try {
    const res = await fetch(`${BASE_URL}${AUTH.REFRESH_TOKEN}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        refresh_token: token,
      }),
    });

    const data = await res.json();
    if (data.status === 401) {
      localStorage.removeItem("user");
      window.location.href = '/';
      return;
    }
    return data;
  } catch (err) {
    console.log(err);
  }
};

// Response interceptor to handle token expiration
httpClient.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      const user = JSON.parse(localStorage.getItem("user"));
      originalRequest._retry = true;
      const newAccessToken = await generateRefreshToken(user.refresh_token);
      axios.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${newAccessToken.token}`;
      user["token"] = newAccessToken.token; // Example modification
      user["refresh_token"] = newAccessToken.refresh_token; // Example modification
      const updatedUser = JSON.stringify(user);
      localStorage.setItem("user", updatedUser);
      return httpClient(originalRequest);
    }
    return Promise.reject(error);
  }
);

export default httpClient;
