import { forwardRef } from "react";
import RulerWrapper from "./ruler-wrapper";
import NormalWrapper from "./normal-wrapper";

const Wrapper = forwardRef((props, ref) => {
  const { children, ruler, ...rest } = props;
  return ruler ? (
    <RulerWrapper ref={ref} {...rest}>
      {children}
    </RulerWrapper>
  ) : (
    <NormalWrapper ref={ref} {...rest}>
      {children}
    </NormalWrapper>
  );
});

export default Wrapper;
