import React, { forwardRef } from "react";
import { VerticalRuler } from "components";
import { PRODUCT } from "constants/appConstant";

const RulerWrapper = forwardRef((props, ref) => {
  const {
    children,
    width,
    wallHeight,
    ceiling_height,
    wallLength,
    railWidth,
    model,
    compassHeight,
    chase_position,
  } = props;

  const conditionalClass = () => {
    if (model === PRODUCT.INFINITY && chase_position === "right")
      return "items-center";
    else return "items-center flex-col";
  };

  return (
    <div
      ref={ref}
      className="border-t-2 my-3 min-w-full items-end"
      style={{
        width: width,
        height: wallHeight,
        display: "grid",
        gridTemplateColumns: "45px 1fr",
      }}
    >
      <VerticalRuler
        class_name="flex flex-col-reverse sticky z-10 left-0"
        height={ceiling_height}
        scaleHeight={wallHeight}
      />
      <div
        className={`h-full flex 
          ${conditionalClass()}
          ${
            model === PRODUCT.INFINITY && wallLength > railWidth + 20
              ? "justify-center"
              : model !== PRODUCT.INFINITY
              ? "justify-end"
              : ""
          }`}
        style={{ height: compassHeight }}
      >
        {children}
      </div>
    </div>
  );
});

export default RulerWrapper;
