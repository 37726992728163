import httpClient from "constants/httpClient";
import { HEADWALL } from "constants/appConstant";

const getProducts = async () => {
  return await httpClient.get(HEADWALL.GET_PRODUCTS);
};

const getServices = async (product) => {
  return await httpClient.get(`${HEADWALL.GET_SERVICES}/${product}`);
};
const getTemplates = async (headwall) => {
  return await httpClient.get(`${HEADWALL.GET_TEMPLATES}/${headwall}`);
};

const getSpecifications = async (service_id, category_id, product) => {
  return await httpClient.get(
    `${HEADWALL.GET_SPECIFICATIONS}/${product}/${category_id}/${service_id}`
  );
};

const saveDesign = async (values) => {
  return await httpClient.post(HEADWALL.SAVE_DESIGN, values);
};

const downloadDesign = async () => {
  return await httpClient.get(HEADWALL.DOWNLOAD_DESIGN);
};

const editDesign = async (designId) => {
  return await httpClient.get(
    HEADWALL.EDIT_DESIGN.replace("{designId}", designId)
  );
};
const updateDesign = async ({ updatedValues, designId }) => {
  return await httpClient.patch(
    HEADWALL.UPDATE_DESIGN.replace("{designId}", designId),
    updatedValues
  );
};

export {
  getProducts,
  getServices,
  saveDesign,
  getSpecifications,
  downloadDesign,
  editDesign,
  updateDesign,
  getTemplates,
};
