import React from "react";

function VerticalRuler(props) {
  const { class_name, height } = props; //scaleHeight

  // Calculate the total number of feet and additional inches
  const totalFeet = height.ft;
  const additionalInches = height.in;

  const renderInches = (footIndex) => {
    const arrayLength = footIndex === 1 ? 12 : 13;
    const inchesLength = arrayLength === 12 ? 11 : 12;
    const inches = Array.from({ length: arrayLength }, (_, i) => (
      <div key={i}>
        {i === inchesLength ||
        (arrayLength === 12 && i === 5) ||
        (arrayLength === 13 && i === 6) ? (
          <>
            &#9472;&#9472;&#9472;
            {i === inchesLength && (
              <span className="vertical-number">{footIndex}</span>
            )}
          </>
        ) : (
          <>&#9472;</>
        )}
      </div>
    ));
    return inches;
  };

  return (
    <>
      <div className={`vertical-scale ${class_name} flex-col z-50`}>
        <div className="vertical-first">
          <div>
            &#9472;&#9472;&#9472;
            <span className="vertical-number">0</span>
          </div>
          {<>{renderInches(1)}</>}
        </div>
        {Array.from({ length: totalFeet - 1 }, (_, i) => (
          <div className="vertical-next" key={i}>
            {renderInches(i + 2)}
          </div>
        ))}

        {/* Render the additional inches in the last foot */}
        {!!additionalInches && (
          <div className="vertical-next">
            {Array.from({ length: additionalInches + 1 }, (_, i) => (
              <>
                {i === 6 ? (
                  <div key={i}>&#9472;&#9472;&#9472;</div>
                ) : (
                  <div key={i}>&#9472;</div>
                )}
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default VerticalRuler;
