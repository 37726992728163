import React from "react";

function Checkbox(props) {
  const { label, handleChange, value, name, uniqueKey, className } = props;
  return (
    <div className="flex items-center " key={uniqueKey}>
       <input
        name={name}
        type="checkbox"
        className=" w-5 h-8 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
        onChange={handleChange}
        checked={value}
        id={name} 
      />
      <label className={className} htmlFor={name}>{label}</label>
    </div>
  );
}
export default Checkbox;
