import React, { useMemo } from "react";
import { Switch, Button, Select } from "components";
import {
  AlignJustifyStart,
  AlignJustifyCenter,
  AlignJustifyEnd,
  AlignEnd,
  AlignCenter,
  SinleAlignIcon,
  DoubleAlignIcon,
  AlertIcon,
  CrossIcon,
} from "assets/images";
import {
  PRODUCT,
  RAILS,
  CEILING_HEIGHT,
  RAIL_INCHES,
  COLORS,
  COMPASS_WIDTH,
  ARRAY_WIDTH,
  GRAINS,
} from "constants/appConstant";
import { chaseAlert } from "config/sweet-alert/alert";
import { useHeadWallStore, useRightPanelStore } from "store";

function DesignPanel() {
  const { values, setValues } = useHeadWallStore();
  const { updateStepCompletion } = useRightPanelStore();

  const {
    width,
    ceiling_height,
    color,
    add_rail,
    headwalls,
    model,
    background,
    custom_finish,
  } = values;

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    const newHeadWallArray = [...headwalls];
    if (type !== "checkbox") setValues({ ...values, [name]: value });
    else {
      const checkServicesExist = newHeadWallArray.some((rails) => {
        return rails.services.length;
      });
      if (checkServicesExist)
        chaseAlert(
          "If you change the chase pattern , your services will be cleared.",
          handleClearServices
        );
      else setValues({ ...values, [name]: checked, chase_position: "no" });
    }
  };

  const handleRailHeightWidth = (e, dimension, index) => {
    const { name, value } = e.target;
    const newHeadWallArray = [...headwalls];
    const checkServicesExist = newHeadWallArray.some((rails) => {
      return rails.services.length;
    });
    if (checkServicesExist)
      chaseAlert(
        "If you change the headwall length , your services will be cleared.",
        handleClearServices
      );
    else {
      if (
        dimension === "ceiling_height" &&
        ceiling_height.ft === 12 &&
        name === "in" &&
        value > 0
      ) {
        setValues({
          ...values,
          [dimension]: {
            ...values[dimension],
            ftError: "Maximum Ceiling Height should be 12ft only.",
          },
        });
      } else if (
        dimension === "ceiling_height" &&
        ceiling_height.in > 0 &&
        name === "ft" &&
        value >= 12
      ) {
        setValues({
          ...values,
          [dimension]: {
            ...values[dimension],
            ftError: "Maximum Ceiling Height should be 12ft only.",
          },
        });
      } else if (
        model === PRODUCT.INFINITY &&
        ((dimension === "width" && !width.ft && name === "in" && value < 5) ||
          (dimension === "width" && width.in < 5 && name === "ft" && value < 1))
      ) {
        setValues({
          ...values,
          [dimension]: {
            ...values[dimension],
            ftError: "Minimum Width should be 5 inches.",
          },
        });
      } else if (
        model === PRODUCT.INFINITY &&
        ((dimension === "width" &&
          width.ft === 17 &&
          name === "in" &&
          value > 0) ||
          (dimension === "width" &&
            width.in > 0 &&
            name === "ft" &&
            value > 16))
      ) {
        setValues({
          ...values,
          [dimension]: {
            ...values[dimension],
            ftError: "Maximum Width should be 17ft only.",
          },
        });
      } else if (
        model === PRODUCT.AXIOM &&
        ((dimension === "width" && !width.ft && name === "in" && value < 11) ||
          (dimension === "width" &&
            width.in < 11 &&
            name === "ft" &&
            value < 1))
      ) {
        setValues({
          ...values,
          [dimension]: {
            ...values[dimension],
            ftError: "Minimum Width should be 11 inches.",
          },
        });
      } else if (
        model === PRODUCT.AXIOM &&
        ((dimension === "width" &&
          width.ft === 6 &&
          name === "in" &&
          value > 0) ||
          (dimension === "width" && width.in > 0 && name === "ft" && value > 5))
      ) {
        setValues({
          ...values,
          [dimension]: {
            ...values[dimension],
            ftError: "Maximum Width should be 6ft only.",
          },
        });
      } else if (
        (model === PRODUCT.COMPASS || model === PRODUCT.ARRAY) &&
        dimension === "width"
      ) {
        const { value } = e.target;
        const newArr = [...headwalls];
        newArr[index].width = value;
        setValues({ ...values, headwalls: newArr });
      } else
        setValues({
          ...values,
          [dimension]: {
            ...values[dimension],
            [name]: Number(value),
            ftError: "",
          },
        });
    }
  };

  const handleChase = (pos) => {
    const newHeadWallArray = [...headwalls];
    const checkServicesExist = newHeadWallArray.some((rails) => {
      return rails.services.length;
    });
    if (checkServicesExist)
      chaseAlert(
        "If you change the chase pattern , your services will be cleared.",
        handleClearServices
      );
    else setValues({ ...values, chase_position: pos });
  };

  const handleClearServices = () => {
    const newRails = [...headwalls];
    newRails.map((rail) => {
      rail.services.length = 0;
      return rail;
    });
    setValues({ ...values, headwalls: newRails, add_rail: add_rail });
    updateStepCompletion(5, false);
  };

  const handleRails = (name) => {
    let newValue;
    if (name === add_rail) newValue = "no";
    else newValue = name;
    const newRails = addRails(newValue);
    setValues({ ...values, headwalls: newRails, add_rail: newValue });
  };

  const addRails = (position) => {
    let rails;
    switch (position) {
      case "top":
        rails = addTopRail();
        return rails;
      case "bottom":
        rails = addBottomRail();
        return rails;
      case "both":
        rails = addBothRail();
        return rails;
      case "single":
        rails = addSingleRail();
        return rails;
      case "double":
        rails = addDoubleRail();
        return rails;
      case "no":
        rails = removeBothRows();
        return rails;
      default:
        return "";
    }
  };

  const addTopRail = () => {
    const rails = [...headwalls];
    const checkTopRail = rails.findIndex((rail) => rail.position === "top");
    const checkBottomRail = rails.findIndex(
      (rail) => rail.position === "bottom"
    );
    if (checkBottomRail !== -1) rails.splice(checkBottomRail, 1);
    if (checkTopRail === -1) rails.unshift({ position: "top", services: [] });
    return rails;
  };

  const addBottomRail = () => {
    const rails = [...headwalls];
    const checkTopRail = rails.findIndex((rail) => rail.position === "top");
    const checkBottomRail = rails.findIndex(
      (rail) => rail.position === "bottom"
    );
    if (checkTopRail !== -1) rails.splice(checkTopRail, 1);
    if (checkBottomRail === -1)
      rails.push({ position: "bottom", services: [] });
    return rails;
  };

  const addBothRail = () => {
    const rails = [...headwalls];
    const checkTopRail = rails.find((rail) => rail.position === "top");
    const checkBottomRail = rails.find((rail) => rail.position === "bottom");
    if (!checkTopRail) rails.unshift({ position: "top", services: [] });
    if (!checkBottomRail) rails.push({ position: "bottom", services: [] });
    return rails;
  };

  const removeBothRows = () => {
    const rails = [...headwalls];
    const topRailIndex = rails.findIndex((rail) => rail.position === "top");
    if (topRailIndex !== -1) rails.splice(topRailIndex, 1);
    const bottomRailIndex = rails.findIndex(
      (rail) => rail.position === "bottom"
    );
    if (bottomRailIndex !== -1) rails.splice(bottomRailIndex, 1);
    const leftRailIndex = rails.findIndex((rail) => rail.position === "left");
    if (leftRailIndex !== -1) rails.splice(leftRailIndex, 1);
    return rails;
  };

  const addSingleRail = () => {
    const rails = [...headwalls];
    const checkLeftRail = rails.findIndex((rail) => rail.position === "left");
    if (checkLeftRail !== -1) rails.splice(checkLeftRail, 1);
    return rails;
  };

  const addDoubleRail = () => {
    const rails = [...headwalls];
    rails.unshift({ position: "left", services: [] });
    return rails;
  };

  const railDimensions = useMemo(() => {
    if (model === PRODUCT.INFINITY) return RAILS.INFINITY;
    else return RAILS.AXIOM;
  }, [model]);

  const handleAddSection = () => {
    const newArr = [...headwalls];
    const width = model === PRODUCT.COMPASS ? 12 : 2;
    newArr.push({ position: "", services: [], width: width });
    setValues({ ...values, headwalls: newArr });
  };

  const handleRemoveSection = () => {
    const newArr = [...headwalls];
    newArr.pop();
    setValues({ ...values, headwalls: newArr });
  };

  return (
    <>
      <div className="overflow-auto newheightDesign mt-8 pr-2 border-t-2 border-slate-300">
        {(() => {
          switch (model) {
            case PRODUCT.INFINITY:
              return (
                <InfinityDesginOptions
                  values={values}
                  railDimensions={railDimensions}
                  handleRailHeightWidth={handleRailHeightWidth}
                  handleRails={handleRails}
                  handleChange={handleChange}
                  handleChase={handleChase}
                />
              );
            case PRODUCT.AXIOM:
              return (
                <AxiomDesginOptions
                  values={values}
                  railDimensions={railDimensions}
                  handleRailHeightWidth={handleRailHeightWidth}
                  handleRails={handleRails}
                  handleChange={handleChange}
                />
              );
            case PRODUCT.COMPASS:
              return (
                <CompassDesginOptions
                  values={values}
                  handleAddSection={handleAddSection}
                  handleRemoveSection={handleRemoveSection}
                  handleRailHeightWidth={handleRailHeightWidth}
                />
              );
            case PRODUCT.ARRAY:
              return (
                <CompassDesginOptions
                  values={values}
                  handleAddSection={handleAddSection}
                  handleRemoveSection={handleRemoveSection}
                  handleRailHeightWidth={handleRailHeightWidth}
                />
              );
            default:
              return (
                <InfinityDesginOptions
                  values={values}
                  railDimensions={railDimensions}
                  handleRailHeightWidth={handleRailHeightWidth}
                  handleRails={handleRails}
                  handleChange={handleChange}
                  handleChase={handleChase}
                />
              );
          }
        })()}
        <div className=" pt-4 border-t-2 border-slate-300 ">
          <p className="font-bold text-base txtBlue headingTxt">
            Headwall Grains/Colors
          </p>
          <div className="grid gap-2 grid-cols-4 my-2 bg-white rounded-md border-2 p-[7px]">
            {Object.keys(GRAINS).map((key, i) => (
              <img
                key={i}
                className={`rounded border-2  h-12 w-full cursor-pointer flex justify-center items-center ${
                  key === background ? "border-sky-600" : "border-slate-300"
                }`}
                style={{ objectFit: "cover" }}
                onClick={() =>
                  setValues({ ...values, color: "", background: key })
                }
                src={GRAINS[key]}
                alt="...img"
              />
            ))}
          </div>
        </div>
        <div className="grid gap-2 grid-cols-4 my-2 bg-white rounded-md border-2 p-[7px]">
          {COLORS.map((col, i) => (
            <div
              className={`rounded border-2  h-12 w-full cursor-pointer flex justify-center items-center  ${
                color === col ? "border-sky-600" : "border-slate-300"
              }`}
              key={i}
              style={{ background: col }}
              onClick={() =>
                setValues({ ...values, color: col, background: "" })
              }
            ></div>
          ))}
        </div>
        <div className="pt-4 mt-8 border-t-2 border-slate-300">
          <p className="font-bold text-base txtBlue headingTxt">
            Specified Finish
          </p>

          <div className="flex items-center justify-between my-3 gap-2 color_input">
            <textarea
              name="custom_finish"
              rows="3"
              className="bg-white w-full p-2 rounded font-bold text-xs border border-slate-300"
              placeholder="Type Specified Finish here..."
              value={custom_finish}
              onChange={handleChange}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default DesignPanel;

const HeadwallLength = ({
  width,
  ceiling_height,
  railDimensions,
  handleRailHeightWidth,
}) => {
  return (
    <div className="pt-4 mt-8 border-t-2 border-slate-300">
      <p className="font-bold text-base txtBlue headingTxt">Headwall Length</p>
      <div className="grid gap-4 grid-cols-2 my-2 ">
        <div>
          <div className="flex items-center ">
            <div className="flex rounded border border-teal-700 pt-1 pb-2  px-2 bg-white  w-full">
              <span className="borderBottom w-[50%]">
                <Select
                  name="ft"
                  className="bg-white  font-bold text-xs  my-0 mx-[2px] text-center w-full"
                  options={railDimensions}
                  value={width.ft}
                  handleSelect={(e) => handleRailHeightWidth(e, "width")}
                />
              </span>
              <span className="borderBottom w-[50%]">
                <Select
                  name="in"
                  className="bg-white borderBottom font-bold text-xs  my-0 mx-[2px] text-center w-full"
                  options={RAIL_INCHES}
                  value={width.in}
                  handleSelect={(e) => handleRailHeightWidth(e, "width")}
                />
              </span>
            </div>
          </div>
          <p className="font-medium text-sm flex pl-0">
            <small className=" font-medium txtBlue">Width (ft/in)</small>
          </p>
        </div>
        <div>
          <div className="flex items-center">
            <div className="flex rounded border border-teal-700 pt-1 pb-2 px-2 bg-white   w-full">
              <span className="borderBottom w-[50%]">
                <Select
                  name="ft"
                  className="bg-white font-bold text-xs text-center w-full   my-0 mx-[2px]"
                  options={CEILING_HEIGHT}
                  value={ceiling_height.ft}
                  handleSelect={(e) =>
                    handleRailHeightWidth(e, "ceiling_height")
                  }
                />
              </span>
              <span className="borderBottom w-[50%]">
                <Select
                  name="in"
                  className="bg-white font-bold text-xs text-center w-full  my-0 mx-[2px]"
                  options={RAIL_INCHES}
                  value={ceiling_height.in}
                  handleSelect={(e) =>
                    handleRailHeightWidth(e, "ceiling_height")
                  }
                />
              </span>
            </div>
          </div>
          <p className="font-medium text-sm flex pl-0 ">
            <small className=" font-medium txtBlue">
              Ceiling Height (ft/in)
            </small>
          </p>
        </div>
      </div>
      {/* errors */}
      {width.ftError && (
        <div className=" flex items-center text-xs font-normal text-yellow-600">
          <img
            src={AlertIcon}
            alt="..img"
            className="mx-2"
            style={{ height: "20px" }}
          />
          {width.ftError}
        </div>
      )}
      {ceiling_height.ftError && (
        <div className=" flex items-center text-xs font-normal text-yellow-600">
          <img
            src={AlertIcon}
            alt="..img"
            className="mx-2"
            style={{ height: "20px" }}
          />
          {ceiling_height.ftError}
        </div>
      )}
    </div>
  );
};

const InfinityDesginOptions = ({
  values,
  railDimensions,
  handleRailHeightWidth,
  handleRails,
  handleChange,
  handleChase,
}) => {
  const { width, ceiling_height, chased, chase_position, add_rail } = values;
  return (
    <>
      <div className="my-4">
        <p className="font-bold text-base txtBlue headingTxt">Rails</p>
        <div className="flex justify-between my-2">
          <Button
            className={`flex w-1/2.8 h-12 flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs bg-white  right_panel_step_box_active cursor-pointer rotate-180 ${
              add_rail === "top" ? "  opacity-100" : "opacity-50"
            }`}
            ImgPath={AlignEnd}
            handleClick={() => handleRails("top")}
            type={"button"}
          />

          <Button
            className={`flex w-1/2.8 h-12 flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs  right_panel_step_box_active cursor-pointer  ${
              add_rail === "bottom" ? "  opacity-100" : " opacity-50"
            }`}
            ImgPath={AlignEnd}
            handleClick={() => handleRails("bottom")}
            type={"button"}
          />

          <Button
            className={`flex w-1/2.8 h-12 flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs right_panel_step_box_active cursor-pointer  ${
              add_rail === "both" ? "  opacity-100" : " opacity-50"
            }`}
            ImgPath={AlignCenter}
            handleClick={() => handleRails("both")}
            type={"button"}
          />
        </div>
      </div>
      <HeadwallLength
        width={width}
        ceiling_height={ceiling_height}
        railDimensions={railDimensions}
        handleRailHeightWidth={handleRailHeightWidth}
      />

      <div className="flex items-center justify-between my-4 pt-4 mt-8 border-t-2 border-slate-300">
        <p className="font-bold text-base txtBlue headingTxt">Chase Needed</p>
        <Switch name="chased" handleSwitch={handleChange} value={chased} />
      </div>
      {chased && (
        <div className=" my-4">
          <div className="  flex justify-between mb-4 w-full" role="group">
            <Button
              className={`flex w-1/2.8 h-12 flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs bg-white  right_panel_step_box_active cursor-pointer ${
                chase_position === "left"
                  ? "  opacity-100"
                  : "chase_needed opacity-50"
              }`}
              ImgPath={AlignJustifyStart}
              handleClick={() => handleChase("left")}
              type={"button"}
            />

            <Button
              className={`flex w-1/2.8 h-12 flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs  right_panel_step_box_active cursor-pointer  ${
                chase_position === "center"
                  ? "  opacity-100"
                  : "chase_needed opacity-50"
              }`}
              ImgPath={AlignJustifyCenter}
              handleClick={() => handleChase("center")}
              type={"button"}
            />

            <Button
              className={`flex w-1/2.8 h-12 flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs right_panel_step_box_active cursor-pointer  ${
                chase_position === "right"
                  ? "  opacity-100"
                  : "chase_needed opacity-50"
              }`}
              ImgPath={AlignJustifyEnd}
              handleClick={() => handleChase("right")}
              type={"button"}
            />
          </div>
        </div>
      )}
    </>
  );
};

const AxiomDesginOptions = ({
  values,
  railDimensions,
  handleRailHeightWidth,
  handleRails,
}) => {
  const { width, ceiling_height, add_rail } = values;
  return (
    <>
      <div className="my-4  ">
        <p className="font-bold text-base txtBlue headingTxt">Panels</p>
        <div className="flex  my-2">
          <Button
            className={`flex w-1/2.8 h-12 flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs bg-white  right_panel_step_box_active cursor-pointer ${
              add_rail === "single" ? "  opacity-100" : " opacity-50"
            }`}
            ImgPath={SinleAlignIcon}
            handleClick={() => handleRails("single")}
            type={"button"}
          />

          <Button
            className={`flex w-1/2.8 h-12 flex-col border justify-center items-center py-2 px-4 rounded font-bold text-xs  right_panel_step_box_active cursor-pointer mx-4 ${
              add_rail === "double" ? "  opacity-100" : " opacity-50"
            }`}
            ImgPath={DoubleAlignIcon}
            handleClick={() => handleRails("double")}
            type={"button"}
          />
        </div>
      </div>
      <HeadwallLength
        width={width}
        ceiling_height={ceiling_height}
        railDimensions={railDimensions}
        handleRailHeightWidth={handleRailHeightWidth}
      />
    </>
  );
};

const CompassDesginOptions = ({
  values,
  handleAddSection,
  handleRemoveSection,
  handleRailHeightWidth,
}) => {
  const { ceiling_height, headwalls, model } = values;
  const width_options = model === PRODUCT.COMPASS ? COMPASS_WIDTH : ARRAY_WIDTH;
  const measurement = model === PRODUCT.COMPASS ? "inch" : "ft";
  return (
    <div className="my-4">
      <p className="font-semibold text-base txtBlue headingTxt">Sections</p>
      <div className=" border-b-2 border-slate-300">
        {!!headwalls.length &&
          headwalls.map((wall, i) => (
            <div className="flex items-center justify-between  my-2" key={i}>
              <span className="txtBlue font-bold text-[13px]">
                Section {i + 1}{" "}
                <small className="text-xs font-normal">
                  Width ({measurement})
                </small>
              </span>

              <div className="flex items-center">
                <div className="selectwidth">
                  <span className="borderBottomMini w-[50%] font-bold text-xs left-[2px]">
                    <Select
                      name="in"
                      className=""
                      options={width_options}
                      value={wall.width}
                      handleSelect={(e) => handleRailHeightWidth(e, "width", i)}
                    />
                  </span>
                </div>
                <img
                  src={CrossIcon}
                  alt="...img"
                  className={`ml-2 ${
                    i === headwalls.length - 1 && i !== 0
                      ? "cursor-pointer"
                      : "opacity-0 pointer-events-none"
                  }`}
                  data-te-toggle="tooltip"
                  data-te-placement="top"
                  data-te-ripple-init
                  data-te-ripple-color="light"
                  title="Remove Section"
                  onClick={handleRemoveSection}
                />
              </div>
            </div>
          ))}
        {headwalls.length < 5 && (
          <div className="flex justify-end w-11/12">
            <Button
              text={"Add Section"}
              className="login_btn py-2 px-4 text-white cursor-pointer rounded btnTxt my-2 font-bold"
              handleClick={handleAddSection}
              type={"button"}
            />
          </div>
        )}
      </div>

      <div className="my-2">
        <p className="font-semibold text-base txtBlue headingTxt">
          Headwall Ceiling Height (ft/inch)
        </p>
        <div className="items-center my-2">
          <div className="  selectwidth flex w-1/2 px-3 py-2 gap-4">
            <span className="borderBottomMini w-[50%] font-bold text-xs">
              <Select
                name="ft"
                className=""
                options={CEILING_HEIGHT}
                value={ceiling_height.ft}
                handleSelect={(e) => handleRailHeightWidth(e, "ceiling_height")}
              />
            </span>
            <span className="borderBottomMini w-[50%] font-bold text-xs">
              <Select
                name="in"
                className=""
                options={RAIL_INCHES}
                value={ceiling_height.in}
                handleSelect={(e) => handleRailHeightWidth(e, "ceiling_height")}
              />
            </span>
          </div>
          {ceiling_height.ftError && (
            <div className=" flex items-center text-xs font-normal text-yellow-600 my-1">
              <img
                src={AlertIcon}
                alt="..img"
                className="mx-2"
                style={{ height: "20px" }}
              />
              {ceiling_height.ftError}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
