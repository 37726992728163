import React from "react";

function Button(props) {
  const { className, text, handleClick, symbol, isDisabled, ImgPath,type } = props;
  return (
    <button
      className={className}
      onClick={handleClick}
      type={type}
      disabled={isDisabled}
    >
      {text}
      {ImgPath && <img className="h-6 w-6" src={ImgPath} alt="img" />}
      {symbol && <span id="super">{symbol}</span>}
    </button>
  );
}

export default Button;
