import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query"; //useQuery
import { Input, Button, Welcome } from "components"; //Select
import { signupSchema } from "config/schema";
import { successAlert, errorAlert } from "config/sweet-alert/alert";
import {
  GmailIcon,
  LockIcon,
  HideIcon,
  OrganizationIcon,
  PhoneIcon,
  PersonIcon,
  ShowIcon,
  ZipIcon,
  StateIcon,
  CountryIcon,
} from "assets/images";
import { signUp } from "services/authServices";
import useLoaderStore from "store/loaderStore";

//intial value
const initialValues = {
  first_name: "",
  last_name: "",
  email: "",
  organization: "",
  phone: "",
  password: "",
  repeat_password: "",
  hsi_contact: "",
  country: "",
  zipcode: "",
  state: "",
};

function SignUp() {
  const { setLoading } = useLoaderStore();
  //state
  const [show, setShow] = useState({ password: false, repeat_password: false });
  const [showWelcomeModal, setShowWelcomeModal] = useState(true);

  const navigate = useNavigate();

  //formik
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signupSchema,
    onSubmit: (values) => {
      const trimmedValues = Object.keys(values).reduce((acc, key) => {
        acc[key] =
          typeof values[key] === "string" ? values[key].trim() : values[key];
        return acc;
      }, {});
      _handleSubmit(trimmedValues);
    },
  });

  //function mutation
  const { mutate, isLoading } = useMutation("signUp", {
    mutationFn: signUp,
    onSuccess: () => {
      successAlert(
        "Continue",
        "Congratulations your account has been created successfully. You can manage your account by signing in."
      );
      localStorage.removeItem("terms_accepted");
      navigate("/");
    },
    onError: (err) => {
      errorAlert(err.response.data.message);
    },
  });

  //debouncing
  // const debouncedQuery = useDebounce(values.zipcode, 300);

  // useQuery(["search", debouncedQuery], () => getState(debouncedQuery), {
  //   enabled: !!debouncedQuery && values.country === "United States", // Only fetch if there's a query
  //   onSuccess: (data) => {
  //     const { data: res } = data.data;
  //     if (res) setFieldValue("state", res.state_name);
  //     else {
  //       setFieldValue("state", "").then(() => {
  //         setFieldError("zipcode", "Please enter a valid zip code");
  //       });
  //     }
  //   },
  // });

  //manage loader

  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);

  const _handleSubmit = async (values) => {
    mutate({ ...values, terms_accepted: true });
  };


  return (
    <div className="w-full login_outer h-full flex justify-center">
      {showWelcomeModal && <Welcome handleClose={setShowWelcomeModal} />}
      <form
        className="login_container  my-12 h-fit flex flex-col"
        onSubmit={handleSubmit}
      >
        <h6 className="font-bold text-center text-2xl txtBlue">Sign Up</h6>
        <div className="my-2">
          <label className="inputTag text-lg">
            First Name<span className="text-red-600">*</span>
          </label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={PersonIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="first_name"
              className="ml-4 w-full h-full rounded"
              placeholder="Enter First Name here"
              value={values.first_name}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.first_name && touched.first_name ? (
            <p className="text-red-600 text-sm">{errors.first_name}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">
            Last Name<span className="text-red-600">*</span>
          </label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={PersonIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="last_name"
              className="ml-4 w-full h-full rounded"
              placeholder="Enter Last Name here"
              value={values.last_name}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.last_name && touched.last_name ? (
            <p className="text-red-600 text-sm">{errors.last_name}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">
            Email Address<span className="text-red-600">*</span>
          </label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={GmailIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="email"
              className="ml-4 w-full h-full rounded"
              placeholder="Enter Email Address here"
              value={values.email}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />
          </div>
          {errors.email && touched.email ? (
            <p className="text-red-600 text-sm">{errors.email}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Organization</label>
          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={OrganizationIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="organization"
              placeholder="Enter Organization here"
              className="ml-4 w-full h-full rounded"
              value={values.organization}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">
            Country<span className="text-red-600">*</span>
          </label>
          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={CountryIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="country"
              placeholder="Enter Country here"
              className="ml-4 w-full h-full rounded"
              value={values.country}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.country && touched.country ? (
            <p className="text-red-600 text-sm">{errors.country}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">
            Zip Code<span className="text-red-600">*</span>
          </label>
          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={ZipIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="zipcode"
              placeholder="Enter Zip Code here"
              className="ml-4 w-full h-full rounded"
              value={values.zipcode}
              // value={formatZipCode(values.zipcode)}
              // handleChange={(e) => {
              //   const { value } = e.target;
              //   if (/^[\d-]*$/.test(value)) {
              //     handleChange(e);
              //   }
              // }}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.zipcode && touched.zipcode ? (
            <p className="text-red-600 text-sm">{errors.zipcode}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">
            State<span className="text-red-600">*</span>
          </label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={StateIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="state"
              placeholder="State"
              className="ml-4 w-full h-full rounded"
              value={values.state}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
          {errors.state && touched.state ? (
            <p className="text-red-600 text-sm">{errors.state}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Phone</label>

          <div className=" login_input flex items-center">
            <span className="ml-3">
              <img src={PhoneIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="phone"
              placeholder="Enter Phone Number here"
              className="ml-4 w-full h-full rounded"
              value={values.phone}
              handleChange={(e) => {
                const { value } = e.target;
                if (/^\d*$/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />
          </div>
          {errors.phone && touched.phone ? (
            <p className="text-red-600 text-sm">{errors.phone}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">
            Password<span className="text-red-600">*</span>
          </label>

          <div className=" login_input flex items-center">
            <span className="ml-3">
              <img src={LockIcon} alt="img" />
            </span>
            <Input
              type={show.password ? "text" : "password"}
              name="password"
              className="ml-4 w-full h-full"
              placeholder="Enter Password here"
              value={values.password}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />

            <span
              className="mr-4 cursor-pointer"
              onClick={() => setShow({ ...show, password: !show.password })}
            >
              <img src={show.password ? ShowIcon : HideIcon} alt="img" />
            </span>
          </div>
          {errors.password && touched.password ? (
            <p className="text-red-600 text-sm">{errors.password}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">
            Confirm Password<span className="text-red-600">*</span>
          </label>

          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={LockIcon} alt="img" />
            </span>
            <Input
              type={show.repeat_password ? "text" : "password"}
              name="repeat_password"
              className="ml-4 w-full h-full"
              placeholder="Enter Confirm Password here"
              value={values.repeat_password}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />

            <span
              className="mr-4 cursor-pointer"
              onClick={() =>
                setShow({ ...show, repeat_password: !show.repeat_password })
              }
            >
              <img src={show.repeat_password ? ShowIcon : HideIcon} alt="img" />
            </span>
          </div>
          {errors.repeat_password && touched.repeat_password ? (
            <p className="text-red-600 text-sm">{errors.repeat_password}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">
            HSI contact person Name/Email Id
          </label>

          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={PersonIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="hsi_contact"
              placeholder="Enter HSI contact person Name/Email Id"
              className="ml-4 w-full h-full rounded"
              value={values.hsi_contact}
              handleChange={handleChange}
              handleBlur={handleBlur}
            />
          </div>
        </div>
        <div className="mt-4 w-full">
          <Button
            className="login_btn p-4 w-full text-white font-bold text-base"
            text={"Sign Up"}
            isDisabled={isLoading}
            type={"submit"}
          />
        </div>
        <div className="text-center">
          <p className="py-3 text-xl font-semibold"> or</p>
          <span className="text-sm font-medium txtGrey ">
            Already have an account? {}
          </span>
          <Link to="/" className=" font-extrabold text-lg">
            Sign In
          </Link>
        </div>
      </form>
    </div>
  );
}

export default SignUp;
