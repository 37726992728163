import React, { memo } from "react";
import { SurfaceMountedAxiomIcon, FlushAxiomIcon } from "assets/images";
import useHeadWallStore from "store/headWallStore";

function SubProducts() {
  const { values, setValues } = useHeadWallStore();

  const products = [
    {
      name: "Surface Mounted",
      image_url: SurfaceMountedAxiomIcon,
      value: "surface",
    },
    { name: "Flush Mounted", image_url: FlushAxiomIcon, value: "flush" },
  ];

  return (
    <div className="pt-4">
      <p className="font-bold text-base txtBlue">Headwall Style</p>
      <div className="overflow-auto newheight pr-4">
        <div className="grid grid-cols-1 gap-4 text-white mt-4 mb-4">
          {products.map((pro, i) => (
            <div
              key={i}
              className={`rounded-lg border-2 border-orange-600 overflow-hidden cursor-pointer hover:opacity-100 ${
                values.sub_model === pro.value || values.sub_model === ""
                  ? "opacity-100"
                  : " opacity-50"
              }`}
              onClick={() => setValues({ ...values, sub_model: pro.value })}
            >
              <img
                draggable={false}
                src={pro.image_url}
                alt="..img"
                className="w-full"
              />
              <p className=" text-sm font-bold bg-white text-orange-600 p-4 flex justify-center">
                {pro.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default memo(SubProducts);
