import React, { Fragment, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { Button, Select } from "components";
import SpecificationModal from "components/common/modal/specification-modal";
import { getServices } from "services/headwallServices";
import { PRODUCT } from "constants/appConstant";
import useServiceStore from "store/serviceStore";
import useLoaderStore from "store/loaderStore";
import useHeadWallStore from "store/headWallStore";

const serviceFilters = [
  { label: "Medical Gas Outlets", value: "Medical Gas Outlet" },
  { label: "Emergency Receptacles", value: "Emergency Receptacle" },
  { label: "Normal Receptacles", value: "Normal Receptacle" },
  { label: "Normal Switches", value: "Switch" },
  { label: "Emergency Switches", value: "Emergency Switch" },
  { label: "Low Voltage Provisions", value: "Low Voltage Provision" },
  { label: " Night Lights", value: "Night Light" },
  { label: "Blanks", value: "Blank" },
];

function Services({ handleDragStart }) {
  const { values } = useHeadWallStore();
  const { serv, view_only, setService } = useServiceStore();
  const { setLoading } = useLoaderStore();
  const { model } = values;
  //states
  const [showModal, setShowModal] = useState(false);
  const [selectedService, setSelectedService] = useState("");
  const [services, setServices] = useState([]);
  const [category, setCategory] = useState("Medical Gas Outlet");
  // const [serviceFilters, setServiceFilters] = useState([]);

  //  Queries
  const { isLoading } = useQuery({
    queryKey: ["services", model],
    queryFn: () => getServices(model),
    onSuccess: (data) => {
      setServices(data.data.data);
      // const categories = data.data.data.map((cat) => {
      //   return { label: cat.category, value: cat.category };
      // });
      // // Using filter and Set to get unique objects based on the 'name' property
      // const uniqueObjects = categories.reduce((unique, cat) => {
      //   return unique.some((u) => u.label === cat.label)
      //     ? unique
      //     : [...unique, cat];
      // }, []);
      // console.log({uniqueObjects})
      // setServiceFilters(uniqueObjects);
    },
  });

  const handleModal = (ser) => {
    setService(ser);
    setShowModal(true);
  };

  useMemo(() => {
    if (serv) {
      setShowModal(true);
      setSelectedService(serv);
    } else setSelectedService("");
  }, [serv]);

  //manage loader
  useMemo(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  const handleClose = () => {
    setShowModal(false);
    if (serv) setService("");
  };

  const regularServices = useMemo(() => {
    let servObj = {};
    const newServArr = services.filter(
      (serv) => serv.width < 5.875 && serv.category === category
    );
    if (newServArr.length > 2 && newServArr.length % 2) {
      servObj["servArr1"] = newServArr.slice(0, -1);
      servObj["servArr2"] = newServArr.slice(-1);
    } else servObj["servArr1"] = newServArr;
    return servObj;
  }, [category, services]);

  const largeServices = useMemo(() => {
    return services.filter(
      (serv) => serv.width >= 5.875 && serv.category === category
    );
  }, [category, services]);

  return (
    <>
      {showModal ? (
        <SpecificationModal
          view_only={view_only}
          product={model}
          model={model}
          service={selectedService}
          handleClose={handleClose}
          handleDragStart={handleDragStart}
        />
      ) : (
        <div className="pt-4 mt-8 border-t-2 border-slate-300">
          <p className="font-bold text-base txtBlue">Services</p>
          <div className="my-2">
            <Select
              name="rails"
              className="bg-white rounded-r font-bold text-xs w-full p-2 rounded border border-slate-300"
              options={serviceFilters}
              value={category}
              handleSelect={(e) => setCategory(e.target.value)}
            />
          </div>
          <div className="overflow-auto newheightService mt-4 pr-4">
            {/* for regular services */}
            <div
              className={`servicesListOpt grid gap-4 ${
                regularServices?.servArr1?.length > 1
                  ? "grid-cols-2"
                  : "grid-cols-1"
              } my-2`}
            >
              {regularServices?.servArr1.map((ser) => (
                <Fragment key={ser._id}>
                  <ServiceList
                    handleModal={handleModal}
                    handleDragStart={handleDragStart}
                    ser={ser}
                    model={model}
                  />
                </Fragment>
              ))}
            </div>
            {/* for odd numbers */}
            {!!regularServices?.servArr2?.length && (
              <div className={`servicesListOpt grid gap-4 grid-cols-1 my-2`}>
                {regularServices?.servArr2.map((ser) => (
                  <Fragment key={ser._id}>
                    <ServiceList
                      handleModal={handleModal}
                      handleDragStart={handleDragStart}
                      ser={ser}
                      model={model}
                    />
                  </Fragment>
                ))}
              </div>
            )}
            {/* for large services */}
            <div className="servicesListOpt grid gap-4 grid-cols-1 my-2">
              {largeServices.map((ser) => (
                <Fragment key={ser._id}>
                  <ServiceList
                    handleModal={handleModal}
                    handleDragStart={handleDragStart}
                    ser={ser}
                    model={model}
                  />
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default Services;

const ServiceList = ({ handleModal, handleDragStart, ser, model }) => {
  const serviceWidth = (ser) => {
    if (model !== PRODUCT.AXIOM) return ser.gang_width / 0.056;
    else if (model === PRODUCT.AXIOM) {
      if (ser.category === "Medical Gas Outlet"&&!ser?.is_slide)
        return (ser.width + 2.375) / 0.056; //2.375 for side margins
      else return (ser.width + 0.125) / 0.056;
    } //2.23 adding to add service width
  };

  const servicePadding = (ser) => {
    if (model === PRODUCT.INFINITY) return 0;
    else if (model === PRODUCT.AXIOM) {
      if (!ser.is_slide) return `13.39px 0 13.39px 0`;
      else return `5px 0 5px 0`;
    }
  };

  return (
    <div className="servicesListItem bg-white rounded border border-slate-300 flex flex-col items-center p-4 justify-between relative">
      <div
        draggableid={ser._id}
        draggable={true}
        onDragStart={(e) => {
          handleDragStart(ser, e);
        }}
        className="flex justify-center hover:z-50 items-center"
        style={{
          padding: servicePadding(ser),
          cursor: "grab",
          minHeight:"93.75px"
        }}
      >
        <div
          style={{
            width: serviceWidth(ser),
            backgroundImage: `url(${ser.image_url})`,
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            userSelect: "none",
            height: ser.height / 0.056,
          }}
        />
      </div>
      <p
        className="font-medium text-xs my-2 text-center"
        style={{ wordBreak: "break-word" }}
      >
        {ser.name}
      </p>
      <Button
        className="login_btn py-1 px-1 w-full text-white text-[12px] font-medium rounded"
        text="Specification"
        symbol="+"
        handleClick={() => handleModal(ser)}
        type={"button"}
      />
    </div>
  );
};
