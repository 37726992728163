import React from "react";

function Input(props) {
  const {
    type,
    name,
    className,
    placeholder,
    value,
    handleChange,
    handleBlur,
    readonly,
    is_disabled
  } = props;
  return (
    <>
      <input
        type={type}
        name={name}
        className={className}
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        readOnly={readonly}
        disabled={is_disabled}
      />
    </>
  );
}

export default Input;
