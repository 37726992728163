import React, { memo, useState, useEffect, useMemo } from "react";
import { useQuery } from "react-query";
import { getSpecifications } from "services/headwallServices";
import { CloseIcon } from "assets/images";
import { PRODUCT } from "constants/appConstant";
import useLoaderStore from "store/loaderStore";
import Select from "../select";

function SpecificationModal({
  view_only,
  product,
  model,
  service: ser,
  handleClose,
  handleDragStart,
}) {
  const { setLoading } = useLoaderStore();
  const [service, setService] = useState();
  const [specsData, setSpecsData] = useState("");
  const [manufacturers, setManufacturers] = useState([]);
  const [specifications, setSpecifications] = useState([]);

  useEffect(() => {
    setService(ser);
  }, [ser]);

  //  Queries
  const { isLoading } = useQuery({
    queryKey: ["specifications", service?._id],
    queryFn: () =>
      getSpecifications(service?._id, service?.category_id, product),
    onSuccess: (data) => {
      const res = data.data.data;
      const manufacturerOptions = res?.manufacturer?.map((d) => {
        return { label: d.name, value: d.name };
      });
      setSpecsData(res);
      setManufacturers(manufacturerOptions);
    },
    enabled: !!service,
    retry: false,
  });

  //manage loader
  useMemo(() => {
    setLoading(isLoading);
  }, [isLoading, setLoading]);

  useEffect(() => {
    const specObj = specsData?.specification?.find(
      (sp) => sp.service_item_id === service?.specification.service_item_id
    );
    const specs = specsData?.specification?.filter(
      (sp) => sp.manufacturer_id === specObj?.manufacturer_id
    );
    const specifications = specs?.map((sp) => {
      return { label: sp.name, value: sp.service_item_id };
    });
    if (specifications?.length) setSpecifications(specifications);
  }, [
    specsData?.manufacturer,
    specsData?.specification,
    service?.specification,
    ser,
  ]);

  const serviceWidth = () => {
    if (
      model === PRODUCT.INFINITY ||
      model === PRODUCT.COMPASS ||
      model === PRODUCT.ARRAY
    )
      return service?.gang_width / 0.056;
    else if (model === PRODUCT.AXIOM) {
      if (service?.category === "Medical Gas Outlet")
        return (service?.width + 2.375) / 0.056; //2.375 for side margins
      else return (service?.width + 0.125) / 0.056;
    } //2.23 adding to add service width
  };

  const servicePadding = () => {
    if (model === PRODUCT.INFINITY) return 0;
    else if (model === PRODUCT.AXIOM) {
      if (!ser.is_slide) return `13.39px 0 13.39px 0`;
      else return `5px 0 5px 0`;
    }
  };

  const handleManufacturer = (e) => {
    const { value } = e.target;
    const manufacturer = specsData.manufacturer.find((m) => m.name === value);
    const specs = specsData.specification.filter(
      (sp) => sp.manufacturer_id === manufacturer?._id
    );
    const defaultSpec = specs.find((sp) => sp.name === "Standard");
    const specifications = specs.map((sp) => {
      return { label: sp.name, value: sp.service_item_id };
    });

    const newObj = {
      manufacturer: value,
      specification: "Standard",
      custom_specification: "",
      service_item_id: defaultSpec.service_item_id,
    };
    setService((prevState) => ({
      ...prevState,
      specification: newObj,
    }));
    setSpecifications(specifications);
  };

  const handleProduct = (e) => {
    const { value } = e.target;
    const selectedLabel = e.target.options[e.target.selectedIndex].text;
    const updatedSpecification = {
      ...service.specification,
      specification: selectedLabel,
      service_item_id: value,
    };

    // Use the spread operator to create a new state object with the updated nested object
    setService((prevState) => ({
      ...prevState,
      specification: updatedSpecification,
    }));
  };

  const handleComment = (e) => {
    const { value } = e.target;
    const updatedSpecification = {
      ...service.specification,
      custom_specification: value,
    };

    // Use the spread operator to create a new state object with the updated nested object
    setService((prevState) => ({
      ...prevState,
      specification: updatedSpecification,
    }));
  };

  const handleServiceDrag = (e) => {
    const newService={...service}
    delete newService?.u_id;
    handleDragStart(newService, e); 
  };

  return (
    <div
      className="relative z-10 ease-out duration-300 "
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="inset-0 z-10 ease-out duration-300">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform  rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-lg">
            <div className="bg-white px-3 pb-4 pt-3 sm:pb-4">
              <div className="flex w-100 justify-end">
                <img
                  src={CloseIcon}
                  alt=""
                  className="cursor-pointer closeBtnModal"
                  onClick={handleClose}
                />
              </div>
              <div className="specification-popup sm:p-6">
                <div className="flex items-center gap-2">
                  <div className="flex-1 flex justify-center">
                    <div
                      draggable
                      onDragStart={(e) => handleServiceDrag(e)}
                      className="flex justify-center hover:z-50 "
                      style={{
                        width: serviceWidth(),
                        padding: servicePadding(),
                        // height: ser.height / 0.056,
                        cursor: "grab",
                      }}
                    >
                      <img
                        draggable={false}
                        src={service?.image_url}
                        alt="..."
                        style={{
                          height: ser.height / 0.056,
                          objectFit: "contain",
                          width: "auto",
                          pointerEvents: "none",
                        }}
                      />
                    </div>
                  </div>
                  <div className="font-bold lg:text-sm xl:text-base flex-1 text-center">
                    {service?.name}
                  </div>
                </div>
                <hr className="my-5" />

                <div className="mt-6">
                  <div className="mb-2 text-md font-normal">Manufacturer</div>

                  <Select
                    name="manufacturer"
                    className={`bg-white  font-bold w-full border border-teal-700 rounded p-2 ${
                      !service?.specification?.manufacturer &&
                      "opacity-50 text-sm"
                    }`}
                    options={manufacturers}
                    value={service?.specification?.manufacturer}
                    // value={serviceManufacturer}
                    handleSelect={handleManufacturer}
                    isDisabled={view_only ? true : false}
                  />
                  <>
                    <div className=" mt-5 mb-2 text-md font-normal">
                      Product
                    </div>

                    <Select
                      name="product"
                      className={`bg-white font-bold w-full border border-teal-700 rounded p-2  ${
                        !service?.specification?.service_item_id &&
                        "opacity-50 text-sm"
                      }`}
                      options={specifications}
                      value={service?.specification?.service_item_id}
                      handleSelect={handleProduct}
                      isDisabled={view_only ? true : false}
                    />
                  </>
                </div>
                <div className="my-6 flex">Custom Specifications Text</div>
                <div className="flex items-end">
                  <textarea
                    name="custom_specification"
                    rows="5"
                    className="bg-white w-full p-2 rounded font-bold text-xs border border-slate-300"
                    placeholder="Type Specification here..."
                    value={service?.specification?.custom_specification || ""}
                    onChange={handleComment}
                    disabled={view_only ? true : false}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(SpecificationModal);
