import React from "react";

function Loader({loading}) {
  return (
    <div className={`loaderDiv ${loading ? "d-block" : "hidden"}`}>
      <div className="mesh-loader">
        <div className="set-one">
          <div className="circle red"></div>
          <div className="circle green"></div>
        </div>
        <div className="set-two">
          <div className="circle blue"></div>
          <div className="circle yellow"></div>
        </div>
      </div>
    </div>
  );
}

export default Loader;
