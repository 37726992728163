import { useState, useEffect } from "react";
import { offlineAlert, closeAlert } from "config/sweet-alert/alert";
import { RouterProvider } from "react-router-dom";
import routes from "routes";
import Loader from "config/loader";
import { useLoaderStore } from "./store";

function App() {
  const { loading } = useLoaderStore();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  useEffect(() => {
    function onlineHandler() {
      setIsOnline(true);
      closeAlert();
    }

    function offlineHandler() {
      setIsOnline(false);
    }

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  if (!isOnline) offlineAlert(isOnline);

  return (
    <>
        <>
          <Loader loading={loading} />
          <RouterProvider router={routes} />
        </>
    </>
  );
}

export default App;
