import Swal from "sweetalert2";
import {
  SuccesIcon,
  ErrorIcon,
  DeleteIcon,
  AlertIcon,
  LogoutDialogIcon,
} from "assets/images";

const successAlert = (buttonText, successMessage) => {
  Swal.fire({
    title: "Success !",
    iconHtml: `<img src=${SuccesIcon} alt="">`,
    html: successMessage,
    confirmButtonText: buttonText,
  });
};

const errorAlert = (errorMessage) => {
  Swal.fire({
    title: "Error !",
    iconHtml: `<img src=${ErrorIcon} alt="">`,
    html: errorMessage,
    confirmButtonText: "Ok",
  });
};

const warningAlert = (message) => {
  Swal.fire({
    title: "Warning!",
    iconHtml: `<img src=${AlertIcon} alt="">`,
    html: message,
    confirmButtonText: "Ok",
  });
};

const chaseAlert = (message, handleSubmit) => {
  Swal.fire({
    title: "Warning!",
    text: message,
    iconHtml: `<img src=${AlertIcon} alt="">`,
    showCancelButton: true,
    confirmButtonText: "Yes, Remove",
    cancelButtonText: "No",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      handleSubmit();
    }
  });
};

const deleteAlert = (handleDiscard, title, text, confirmButtonText) => {
  Swal.fire({
    title: title,
    text: text,
    iconHtml: `<img src=${DeleteIcon} alt="">`,
    showCancelButton: true,
    confirmButtonText: confirmButtonText,
    cancelButtonText: "No",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      handleDiscard();
      Swal.fire("Deleted!", "Your file has been deleted.", "success");
    }
  });
};

const logoutAlert = (handleLogout, title, text) => {
  Swal.fire({
    title: "Are you sure you want to logout?",
    // text: text,
    iconHtml: `<img src=${LogoutDialogIcon} alt="">`,
    showCancelButton: true,
    confirmButtonText: "Yes, Logout",
    cancelButtonText: "No",
    reverseButtons: true,
  }).then((result) => {
    if (result.isConfirmed) {
      handleLogout();
      // Swal.fire("Logged out Successfully.", "success");
    }
  });
};

const offlineAlert = () => {
  return Swal.fire({
    title: "Oops! You are offline.",
    text: "Once the internet connection is restored, you can continue with your design.",
    icon: "error",
    showCancelButton: false,
    showConfirmButton: false,
    allowOutsideClick: false, // Prevents closing when clicking outside
    allowEscapeKey: false,
    allowEnterKey: false,
    // confirmButtonText: "Ok",
  });
};

const closeAlert = () => {
  Swal.close();
};

export {
  successAlert,
  errorAlert,
  warningAlert,
  deleteAlert,
  chaseAlert,
  offlineAlert,
  closeAlert,
  logoutAlert,
};
