import React, { Fragment, useMemo } from "react";
import { Switch } from "components";
import { ErrorImg } from "assets/images";
import { GRAINS, PRODUCT } from "constants/appConstant";
import useHeadWallStore from "store/headWallStore";

const railSection = {
  infinity: "Rail",
  axiom: "Panel",
  compass: "Section",
  array: "Section",
};

function Submit() {
  const { values, setValues } = useHeadWallStore();
  const {
    color,
    headwalls,
    comment,
    chased,
    model,
    width,
    name,
    background,
    cad_requested,
    quote_requested,
    span_window,
  } = values;

  const rails = useMemo(() => {
    let railArr = [];
    if (model === PRODUCT.INFINITY || model === PRODUCT.AXIOM)
      headwalls.forEach((r) => {
        return railArr.push(width.ft * 12 + width.in);
      });
    else if (model === PRODUCT.COMPASS)
      headwalls.forEach((r) => {
        return railArr.push(r.width);
      });
    else if (model === PRODUCT.ARRAY)
      headwalls.forEach((r) => {
        return railArr.push(r.width * 12);
      });

    return railArr;
  }, [headwalls, width, model]);

  const services = useMemo(() => {
    let servArr = [];

    headwalls?.forEach((rails) => {
      rails?.services?.forEach((service) => {
        const { specification, manufacturer } = service?.specification;
        const existingService = servArr.find((s) => s.name === service.name);

        if (existingService) {
          // If the service with the same name already exists, update count and push values
          existingService.count += 1;

          existingService?.specification?.push({
            [manufacturer]: specification,
          });
        } else {
          // If the service with the same name doesn't exist, create a new entry
          servArr.push({
            name: service.name,
            count: 1,
            specification: [
              {
                [manufacturer]: specification,
              },
            ],
          });
        }
      });
    });
    return servArr;
  }, [headwalls]);

  return (
    <>
      <div
        className="border-t-2 border-slate-300  pt-4 mt-4 mb-5"
        style={{ height: `calc(100vh - 240px)` }}
      >
        <div>
          <div
            className={`overflow-auto bg-white p-4 rounded-lg ${
              model === PRODUCT.INFINITY ? "submit_infinity" : "submit_compass"
            }`}
          >
            <h2 className="font-semibold text-[20px]">Summary</h2>
            <hr className="my-2" />
            <div>
              <h3 className="font-semibold text-lg">Headwall</h3>
              <h5 className="font-semibold text-sm my-2 capitalize">{model}</h5>
              <div className="flex flex-col gap-2">
                {rails.map((r, i) => (
                  <div className="flex justify-between" key={i}>
                    <span className="font-normal text-xs ">
                      {railSection[model]} {i + 1}
                    </span>
                    <span className="font-normal text-xs ">{r} inch</span>
                  </div>
                ))}
              </div>
            </div>
            <hr className="my-2" />
            <div>
              <h5 className="font-semibold text-base my-2">Services</h5>
              <div className="flex flex-col gap-2">
                {services.map((ser, i) => (
                  <div className="flex justify-between" key={i}>
                    <div className="flex flex-col flex-1">
                      <span className="font-semibold text-sm">{ser?.name}</span>
                      {ser?.specification?.map((s, index) => (
                        <Fragment key={index}>
                          {typeof s === "object" &&
                            Object.entries(s).map(([key, value], i) => (
                              <span className="font-light text-xs my-1" key={i}>
                                {key} ({value})
                              </span>
                            ))}
                        </Fragment>
                      ))}
                    </div>
                    <span className="font-normal text-sm">x {ser.count}</span>
                  </div>
                ))}
              </div>
            </div>
            {model === PRODUCT.INFINITY && (
              <>
                <hr className="my-2" />
                <div className="flex justify-between items-center ChaseOpt">
                  <h5 className="font-normal text-base my-2">Chase</h5>
                  <span className="font-normal text-sm">
                    {chased ? "Yes" : "No"}
                  </span>
                </div>
              </>
            )}
            <hr className="my-2" />
            {color ? (
              <div className="flex justify-between text-center items-center ColorChoose">
                <span className="font-semibold text-base">Headwall Color</span>
                <div
                  className="my-2 rounded border border-slate-300 h-[24px]  max-w-[24px] w-full"
                  style={{ backgroundColor: color }}
                />
              </div>
            ) : (
              <div className="flex justify-between text-center items-center ColorChoose">
                <span className="font-semibold text-base">Headwall Grain</span>
                <img
                  className="my-2 rounded border border-slate-300 h-[24px]  max-w-[24px] w-full"
                  style={{ objectFit: "cover" }}
                  src={GRAINS[background]}
                  alt="...img"
                />
              </div>
            )}
          </div>
          {model === PRODUCT.INFINITY && (
            <div className="flex items-center justify-between ChaseOpt">
              <p className="font-semibold text-sm headingTxt">Span Window</p>
              <Switch
                name="span_window"
                handleSwitch={(e) =>
                  setValues({ ...values, span_window: e.target.checked })
                }
                value={span_window}
              />
            </div>
          )}
          <div className="flex items-center justify-between my-3 ChaseOpt">
            <p className="font-semibold text-sm headingTxt">Request AutoCAD</p>
            <Switch
              name="cad_requested"
              handleSwitch={(e) =>
                setValues({ ...values, cad_requested: e.target.checked })
              }
              value={cad_requested}
            />
          </div>
          <div className="flex items-center justify-between  ChaseOpt">
            <p className="font-semibold text-sm headingTxt">
              Request Quotation
            </p>
            <Switch
              name="quote_requested"
              handleSwitch={(e) =>
                setValues({ ...values, quote_requested: e.target.checked })
              }
              value={quote_requested}
            />
          </div>
          <div className="my-2 CommentsTxtarea">
            <p className="font-semibold text-sm">Comments</p>
            <div className="my-2">
              <textarea
                name="comment"
                rows="2"
                className="bg-white w-full p-2 rounded font-bold text-xs border border-slate-300"
                value={comment}
                onChange={(e) =>
                  setValues({ ...values, comment: e.target.value })
                }
              />
            </div>
          </div>
          {!name.trim() && (
            <div className="flex gap-1 items-start errorTxtMsg">
              <img src={ErrorImg} alt="...img" className="p-1" />
              <p className="text-red-600  font-normal text-base">
                Please enter a name to save your design{" "}
              </p>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Submit;
