import React, { useState } from "react";
import { Outlet, Link } from "react-router-dom";
import Sidebar from "../sidebar";
import { LogoIcon, RightArrow } from "../../assets/images";
import { HeaderProfileLogo } from "components";

function DashboardLayout() {
  const [open, setOpen] = useState(true);

  return (
    <>
      <div className="contentLayoutDash">
        <div
          className={` ${
            open ? "active " : "deactive "
          } h-full relative duration-300 headerDash  px-0 `}
        >
          <div className="headerBg d-inline-block sideLayout">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="314"
              height="233"
              viewBox="0 0 314 233"
              fill="none"
            >
              <path
                d="M313.5 0H0V232.5C11.2 208.5 24 207.5 37.5 206C130 203.5 159.5 209.5 208 206C252.856 202.763 262.5 195.5 286 173.5C304.8 155.9 311.667 126.167 313 113.5L313.5 104V0Z"
                fill="#FFE8C9"
              />
            </svg>

            <Link to={"https://www.hsiheadwalls.com/"} target="blank">
              <img
                src={LogoIcon}
                alt="img"
                className=" rounded cursor-pointer w-100"
              />
            </Link>
          </div>
          {/* <span className="toogleBtn"> */}
          <img
            src={RightArrow}
            alt="...img"
            className={`absolute cursor-pointer toogleBtn -right-5 w-5  
                  ${open ? "rotate-180 " : "active"}`}
            onClick={() => setOpen(!open)}
          />
          {/* </span> */}
          <Sidebar />
        </div>
        <div
          className={` ${
            open ? "deactive " : "active "
          } main_container mainLayoutDash `}
        >
          <div className="header relative flex items-center justify-end px-0 ">
            <HeaderProfileLogo />
          </div>

          <Outlet />
        </div>
      </div>
    </>
  );
}

export default DashboardLayout;
