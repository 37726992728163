import { createBrowserRouter } from "react-router-dom";
import { AppLayout, DashboardLayout } from "layout";
import {
  SignUp,
  Login,
  Editor,
  ForgotPassword,
  ResetPassword,
  Dashboard,
  SavedDesigns,
  ChangePassword,
  UpdateProfile,
  TermsAndConditions,
  PrivacyPolicy,
} from "pages";
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoutes";

const routes = createBrowserRouter([
  {
    path: "/",
    element: <AppLayout />,
    children: [
      {
        path: "/",
        element: <PublicRoute component={Login} />,
      },
      {
        path: "/signup",
        element: <PublicRoute component={SignUp} />,
      },
      {
        path: "/forgot-password",
        element: <PublicRoute component={ForgotPassword} />,
      },
      {
        path: "/reset-password",
        element: <PublicRoute component={ResetPassword} />,
      },
      {
        path: "/profile",
        element: <PrivateRoute component={UpdateProfile} />,
      },
      {
        path: "/change-password",
        element: <PrivateRoute component={ChangePassword} />,
      },
      {
        path: "/editor",
        element: <Editor />,
      },
    ],
  },
  {
    basename: "/dashboard",
    element: <DashboardLayout />,
    children: [
      {
        path: "/dashboard",
        element: <PrivateRoute component={Dashboard} />,
      },
      {
        path: "/dashboard/saved-designs",
        element: <PrivateRoute component={SavedDesigns} />,
      },
    ],
  },
  {
    path: "/terms-conditions",
    element: <TermsAndConditions />,
  },
  {
    path: "/privacy-policy",
    element: <PrivacyPolicy />,
  },
]);

export default routes;
