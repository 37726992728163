import React, { useRef, useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import {
  ProfileIcon,
  DashboardIcon,
  LogoutIcon,
  EditProfileIcon,
  ChangePasswordIcon,
  DashboardActiveIcon,
  ProfileActiveIcon,
  ChangePassworddActiveIcon,
  LogoutActiveIcon,
} from "assets/images";
import queryClient from "services/queryClient";
import useHeadWallStore from "store/headWallStore";
import { logoutAlert } from "config/sweet-alert/alert";

const optionsForSignInUser = [
  {
    value: "Dashboard",
    path: "/dashboard",
    icon: DashboardIcon,
    activeIcon: DashboardActiveIcon,
  },
  {
    value: "Edit Profile",
    path: "/profile",
    icon: EditProfileIcon,
    activeIcon: ProfileActiveIcon,
  },
  {
    value: "Change Password",
    path: "/change-password",
    icon: ChangePasswordIcon,
    activeIcon: ChangePassworddActiveIcon,
  },
];

const optionsForNormalUser = [
  { value: "Sign In", path: "/" },
  { value: "Sign Up", path: "signup" },
];

function HeaderProfileLogo() {
  const { resetState } = useHeadWallStore();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [openDropDown, setOpenDropDown] = useState(true);

  const user = JSON.parse(localStorage.getItem("user"));
  const options = user ? optionsForSignInUser : optionsForNormalUser;

  //ref
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        // Clicked outside the dropdown, so close it
        setOpenDropDown(true);
      }
    };

    // Add event listener when the component mounts
    document.addEventListener("click", handleClickOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const handleLogOutDialog = () => {
    logoutAlert(handleLogOut);
  };

  const handleLogOut = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("terms_accepted");
    queryClient.clear();
    resetState();
    navigate("/");
  };

  return (
    <>
      <div
        className="flex items-center flex-end"
        ref={dropdownRef}
        onClick={() => setOpenDropDown(!openDropDown)}
      >
        <div className="rounded-full border border-slate-200 cursor-pointer flex w-12 h-12 m-2.5 float-right overflow-hidden">
          {user ? (
            <b className="flex items-center justify-center w-full bg-[#FFE8C9] uppercase">
              {user?.first_name?.charAt(0)}
              {user?.last_name?.charAt(0)}
            </b>
          ) : (
            <img
              src={ProfileIcon}
              alt="img"
              className="w-full h-full object-cover "
            />
          )}
        </div>
        <button
          className={
            "pr-4 pl-1 cursor-pointer ddownArrow " +
            (openDropDown ? "closeArw " : "openArw ")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="8"
            viewBox="0 0 12 8"
            fill="none"
          >
            <path
              d="M10.125 1.75L5.625 6.25L1.125 1.75"
              stroke="black"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
      <div
        className={"selectUserOpt " + (openDropDown ? "hidden " : "d-block ")}
      >
        <ul className="locationListOptDiv">
          {options.map(({ value, path, icon, activeIcon }, i) => (
            <Link to={path} key={i}>
              <li
                className={`flex gap-3 items-center group relative ${
                  pathname === path && "selectUserOpt_active"
                }`}
              >
                {user && (
                  <>
                    <img
                      src={icon}
                      alt=""
                      style={{ width: "17px" }}
                      className={`group-hover:hidden ${
                        pathname !== path ? "inline-block" : "hidden"
                      }`}
                    />
                    <img
                      src={activeIcon}
                      alt=""
                      style={{ width: "17px" }}
                      className={` group-hover:inline-block ${
                        pathname === path ? "inline-block" : "hidden"
                      }`}
                    />
                  </>
                )}
                <span>{value}</span>
              </li>
            </Link>
          ))}
          <hr />
          {user && (
            <Link onClick={handleLogOutDialog} to={"#"}>
              <li className="flex gap-3 items-center text-red-600  group">
                <img
                  src={LogoutIcon}
                  alt=""
                  style={{ width: "17px" }}
                  className="group-hover:hidden"
                />
                <img
                  src={LogoutActiveIcon}
                  alt=""
                  style={{ width: "17px" }}
                  className="hidden group-hover:inline-block"
                />
                <span>Logout</span>
              </li>
            </Link>
          )}
        </ul>
      </div>
    </>
  );
}

export default HeaderProfileLogo;
