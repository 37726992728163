import httpClient from "constants/httpClient";
import { AUTH } from "constants/appConstant";

const signUp = async (values) => {
  return await httpClient.post(AUTH.SIGNUP, values);
};

const signIn = async (values) => {
  return await httpClient.post(AUTH.LOGIN, values);
};

const forgotPassword = async (values) => {
  return await httpClient.post(AUTH.FORGOT_PASSWORD, values);
};

const resetPassword = async (values) => {
  return await httpClient.post(AUTH.RESET_PASSWORD, values);
};

const changePassword = async (values) => {
  return await httpClient.post(AUTH.CHANGE_PASSWORD, values);
};

const getDesigns = async (limit) => {
  return await httpClient.get(`${AUTH.GET_DESINGS}?limit=${limit}`);
};

const getProfile = async () => {
  return await httpClient.get(AUTH.GET_PROFILE);
};

const updateProfile = async (values) => {
  return await httpClient.patch(AUTH.UPDATE_PROFILE, values);
};

const deleteDesign = async (id) => {
  return await httpClient.delete(AUTH.DELETE_DESIGN.replace("{id}", id));
};



export {
  signUp,
  signIn,
  forgotPassword,
  resetPassword,
  changePassword,
  getDesigns,
  deleteDesign,
  getProfile,
  updateProfile,
};
