// import { PRODUCT } from "constants/appConstant";
import React from "react";

function HorizontalRuler(props) {
  const { class_name, width, rulerSectionWidth } = props;
  // Calculate the total number of feet and additional inches
  const totalFeet = width.ft;
  const additionalInches = width.in;

  const renderInches = (footIndex) => {
    const arrayLength = footIndex === 1 ? 12 : 13;
    const inchesLength = arrayLength === 12 ? 11 : 12;
    const inches = Array.from({ length: arrayLength }, (_, i) => (
      <div
        key={i}
        className={
          i === inchesLength ||
          (arrayLength === 12 && i === 5) ||
          (arrayLength === 13 && i === 6)
            ? "ft"
            : ""
        }
      >
        {i === inchesLength ||
        (arrayLength === 12 && i === 5) ||
        (arrayLength === 13 && i === 6) ? (
          <>
            {i === inchesLength && <span className="number">{footIndex}</span>}
          </>
        ) : (
          <> &#124;</>
        )}
      </div>
    ));
    return inches;
  };

  return (
    <>
      <div
        className={`scale ${class_name}`}
        style={{
          gridColumnStart: 1,
          gridColumnEnd: 3,
          position: "sticky",
          width: rulerSectionWidth,
        }}
      >
        {/* Render the first section (0 to 1 foot) */}
        <div className="first">
          <div className="ft">
            {/* &#124;&#10072; */}
            <span className="number">0</span>
          </div>
          {totalFeet >= 1 && <>{renderInches(1)}</>}
        </div>

        {/* Render the full feet sections */}
        {/* <div className={`next ${model===PRODUCT.AXIOM?"gap-[16px]":""}`} key={i}></div> */}
        {Array.from({ length: totalFeet - 1 }, (_, i) => (
          <div className={`next`} key={i}>
            {renderInches(i + 2)}
          </div>
        ))}

        {/* Render the additional inches in the last foot */}
        {!!additionalInches && (
          <div className="next">
            {Array.from({ length: additionalInches + 1 }, (_, i) => (
              <>
                {i === 6 ? (
                  <div key={i} className={i === 6 ? "ft" : ""} />
                ) : (
                  <div key={i}>&#124;</div>
                )}
              </>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default HorizontalRuler;
