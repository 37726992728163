import React from "react";

function Select(props) {
  const { name, className, options, handleSelect, value,isDisabled } = props;
  return (
    <>
      <select
        name={name}
        className={className}
        onChange={handleSelect}
        value={value}
        disabled={isDisabled}
      >
        {/* <option value="" disabled selected hidden>
          {placeholder ? placeholder : "Select..."}
        </option> */}
        {options.map((op, i) => (
          <option value={op.value} key={i}>
            {op.label}
          </option>
        ))}
      </select>
    </>
  );
}

export default Select;
