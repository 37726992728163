import React, { Fragment, forwardRef, useMemo, useEffect } from "react";
import useServiceStore from "store/serviceStore";
import { GRAINS, COMPASS } from "constants/appConstant";
import { HorizontalRuler } from "components";
import Wrapper from "./headwall-components/wrapper";

const Compass = forwardRef((props, ref) => {
  const {
    values,
    ruler,
    handleDragStart,
    handleonDragOver,
    handleDrop,
    handleDragEnter,
    dragEntered,
  } = props;
  const { setService } = useServiceStore();
  const { color, headwalls, ceiling_height, background, model } = values;

  const wallWidth = useMemo(() => {
    let totalWidth = 0;
    //added 10 inches extra to manage wall width
    if (headwalls.length >= 2) {
      headwalls.map((wall) => {
        totalWidth += Number(wall.width);
        return totalWidth;
      });
      return totalWidth / 0.056 + 100;
    }
  }, [headwalls]);

  const compassHeight = useMemo(() => {
    return Math.round(
      (ceiling_height.ft * 12 + ceiling_height.in - 9) / 0.056 //9 from bottom
    );
  }, [ceiling_height]);

  const wallHeight = useMemo(() => {
    return Math.round((ceiling_height.ft * 12 + ceiling_height.in) / 0.056);
  }, [ceiling_height]);

  const rulerWidth = useMemo(() => {
    const totalInches = headwalls.reduce(
      (acc, curr) => acc + Number(curr.width),
      0
    );

    // Convert total inches to feet and inches
    const feet = Math.floor(totalInches / 12);
    const inches = totalInches % 12;

    return { ft: feet, in: inches };
  }, [headwalls]);

  const sectionWidth = (width) => {
    let secWidth = 0;
    switch (Number(width)) {
      case 12:
        secWidth = 12 / 0.056;
        break;
      case 18:
        secWidth = 18 / 0.056;
        break;
      case 24:
        secWidth = 24 / 0.056;
        break;
      case 30:
        secWidth = 30 / 0.056;
        break;
      case 36:
        secWidth = 36 / 0.056;
        break;
      case 42:
        secWidth = 42 / 0.056;
        break;
      case 48:
        secWidth = 48 / 0.056;
        break;
      default:
        secWidth = 12 / 0.056;
    }
    return secWidth;
  };

  const serviceWidth = (ser) => {
    return Math.round(ser.gang_width / 0.056);
  };

  useEffect(() => {
    //to adjust scale for axiom
    // Select all elements with the class 'scale'
    if (ruler) {
      const firstElements = document.querySelector(".first");
      const nextElements = document.querySelectorAll(".next");

      // Update styles for 'first' elements
      firstElements.style.maxWidth = "214.285714285px";

      nextElements.forEach((element) => {
        element.style.maxWidth = "214.285714285px";
      });
      // Cleanup function to revert styles when component unmounts
      return () => {
        firstElements.style.maxWidth = "";
        nextElements.forEach((element) => {
          element.style.maxWidth = "";
        });
      };
    }
  }, [ruler, rulerWidth]);

  return (
    <Wrapper
      ruler={ruler}
      width={wallWidth}
      wallHeight={wallHeight}
      ceiling_height={ceiling_height}
      model={model}
      compassHeight={compassHeight}
      ref={ref}
    >
      <div className="flex gap-[2px] pb-[19px]">
        {headwalls.map((rail, railIndex) => (
          <Fragment key={railIndex}>
            <div>
              <div
                // subtracting 2 to manage 2px gap for compass
                style={{ width: sectionWidth(rail.width) - 2 }}
                className="flex flex-col gap-[2px] relative"
                id={`compass_section_${railIndex}`}
                onDragEnter={(e) => handleDragEnter(e, railIndex)}
                onDrop={(e) => handleDrop(e, "rail", railIndex)}
              >
                {rail.services
                  .filter((serv) => serv.is_slide)
                  .map((ser, serviceIndex) => (
                    <div
                      draggable
                      key={serviceIndex}
                      className={`absolute flex justify-center z-10 ${
                        dragEntered ? "non_dropable " : ""
                      }`}
                      style={{
                        left: ser.positions?.x,
                        top: ser.positions?.y,
                        cursor: "grab",
                      }}
                      onDragStart={(e) =>
                        handleDragStart(ser, e, railIndex, serviceIndex)
                      }
                      onDragEnter={(e) => handleDragEnter(e, railIndex)}
                      onClick={() => setService(ser, true)}
                    >
                      <div
                        draggable={"false"}
                        style={{
                          width: serviceWidth(ser),
                          backgroundImage: `url(${ser.image_url})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          pointerEvents: "none",
                          height: ser.height / 0.056,
                        }}
                        onDragEnter={(e) => e.stopPropagation()}
                      />
                    </div>
                  ))}
                {/* section 1 */}
                <div
                  className="w-full compass-section relative"
                  style={{
                    background: color,
                    height: `${COMPASS.SECTION_1_HEIGHT}px`,
                    backgroundImage: `url(${GRAINS[background]})`,
                  }}
                  onDragEnter={(e) => handleDragEnter(e, railIndex)}
                  onDragOver={(e) => handleonDragOver(e, "rail")}
                  onDrop={(e) => handleDrop(e, "rail", railIndex)}
                >
                  {/* Row 1 */}
                  <Row
                    row={1}
                    rail={rail}
                    railIndex={railIndex}
                    dragEntered={dragEntered}
                    className={
                      "h-[94.4642857143px]  mx-[49px] mb-[19.8214285714px] mt-[20.1785714286px]"
                    }
                    handleonDragOver={handleonDragOver}
                    handleDrop={handleDrop}
                    handleDragEnter={handleDragEnter}
                    handleDragStart={handleDragStart}
                  />

                  {/* Row 2 */}
                  <Row
                    row={2}
                    rail={rail}
                    railIndex={railIndex}
                    dragEntered={dragEntered}
                    className={
                      "h-[94.4642857143px]  mx-[49px] my-[19.8214285714px]"
                    }
                    handleonDragOver={handleonDragOver}
                    handleDrop={handleDrop}
                    handleDragEnter={handleDragEnter}
                    handleDragStart={handleDragStart}
                  />
                </div>
                {/* section 2 */}
                <div
                  className="w-full compass-section relative"
                  style={{
                    background: color,
                    height: `${COMPASS.SECTION_2_HEIGHT}px`,
                    backgroundImage: `url(${GRAINS[background]})`,
                  }}
                  onDragEnter={(e) => handleDragEnter(e, railIndex)}
                  onDragOver={(e) => handleonDragOver(e, "rail")}
                  onDrop={(e) => handleDrop(e, "rail", railIndex)}
                >
                  {/* Row 3 */}
                  <Row
                    row={3}
                    rail={rail}
                    railIndex={railIndex}
                    dragEntered={dragEntered}
                    className={
                      "h-[94.4642857143px]  mx-[49px] mt-[16.9642857143px]"
                    }
                    handleonDragOver={handleonDragOver}
                    handleDrop={handleDrop}
                    handleDragEnter={handleDragEnter}
                    handleDragStart={handleDragStart}
                  />

                  {/* Row 4 */}
                  <Row
                    row={4}
                    rail={rail}
                    railIndex={railIndex}
                    dragEntered={dragEntered}
                    className={
                      "h-[94.4642857143px] mx-[49px] my-[19.8214285714px]"
                    }
                    handleonDragOver={handleonDragOver}
                    handleDrop={handleDrop}
                    handleDragEnter={handleDragEnter}
                    handleDragStart={handleDragStart}
                  />

                  {/* Row 5 */}
                  <Row
                    row={5}
                    rail={rail}
                    railIndex={railIndex}
                    dragEntered={dragEntered}
                    className={"h-[94.4642857143px] mx-[49px]"}
                    handleonDragOver={handleonDragOver}
                    handleDrop={handleDrop}
                    handleDragEnter={handleDragEnter}
                    handleDragStart={handleDragStart}
                  />
                </div>
                {/* section 3 */}
                <div
                  className="w-full compass-section relative"
                  style={{
                    background: color,
                    height: `${COMPASS.SECTION_3_HEIGHT}px`,
                    backgroundImage: `url(${GRAINS[background]})`,
                  }}
                  onDragEnter={(e) => handleDragEnter(e, railIndex)}
                  onDragOver={(e) => handleonDragOver(e, "rail")}
                  onDrop={(e) => handleDrop(e, "rail", railIndex)}
                >
                  {/* Row 6 */}
                  <Row
                    row={6}
                    rail={rail}
                    railIndex={railIndex}
                    dragEntered={dragEntered}
                    className={"h-[94.4642857143px]  mx-[49px] mt-[27px]"}
                    handleonDragOver={handleonDragOver}
                    handleDrop={handleDrop}
                    handleDragEnter={handleDragEnter}
                    handleDragStart={handleDragStart}
                  />
                  {/* Row 7 */}
                  <Row
                    row={7}
                    rail={rail}
                    railIndex={railIndex}
                    dragEntered={dragEntered}
                    className={"h-[94.4642857143px]  mx-[49px] mt-[115px]"}
                    handleonDragOver={handleonDragOver}
                    handleDrop={handleDrop}
                    handleDragEnter={handleDragEnter}
                    handleDragStart={handleDragStart}
                  />
                </div>
              </div>
              <p className="flex justify-center mt-2 txtBlue font-semibold text-base">
                Section {railIndex + 1}
              </p>
            </div>
          </Fragment>
        ))}
      </div>
      {ruler && (
        <HorizontalRuler
          class_name={` bottom-[17px]  mt-10`}
          width={rulerWidth}
        />
      )}
    </Wrapper>
  );
});

export default Compass;

const Row = ({
  row,
  rail,
  railIndex,
  dragEntered,
  className,
  handleonDragOver,
  handleDrop,
  handleDragEnter,
  handleDragStart,
}) => {
  const { setService } = useServiceStore();
  const serviceWidth = (ser) => {
    return ser.gang_width / 0.056;
  };

  return (
    <>
      <div
        className={`relative flex items-center ${className} ${
          dragEntered && "bg-green-100"
        }`}
        onDragEnter={(e) => handleDragEnter(e, railIndex)}
        onDragOver={(e) => handleonDragOver(e, "rail")}
        onDrop={(e) => handleDrop(e, "rail", railIndex, row)}
      >
        {rail.services
          .filter(
            (serv) =>
              serv.rowPosition === row &&
              serv.category !== "Vacuum Bottle Slide"
          )
          .map((ser, serviceIndex) => (
            <div
              draggable
              key={serviceIndex}
              className={`absolute flex justify-center 
                        ${dragEntered ? "non_dropable " : ""}`}
              style={{
                top: "0px",
                left: ser.positions?.x,
                cursor: "grab",
              }}
              onDragStart={(e) =>
                handleDragStart(ser, e, railIndex, serviceIndex, row)
              }
              onDragEnter={(e) => e.stopPropagation()}
              onClick={() => setService(ser, true)}
            >
              <div
                draggable={"false"}
                className="pointer-events-none"
                style={{
                  width: serviceWidth(ser),
                  backgroundImage: `url(${ser.image_url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  pointerEvents: "none",
                  height: ser.height / 0.056,
                }}
                onDragEnter={(e) => e.stopPropagation()}
              />
            </div>
          ))}
      </div>
    </>
  );
};
