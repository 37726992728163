import React, { memo } from "react";
import { useQuery, useMutation } from "react-query";
import { getProducts, getTemplates } from "services/headwallServices";
import { PRODUCT } from "constants/appConstant";
import useHeadWallStore from "store/headWallStore";
import { useRightPanelStore } from "store";
import { errorAlert } from "config/sweet-alert/alert";

const models = {
  "Horizontal Infinity": "infinity",
  "Axiom Headwall": "axiom",
  "Array SSH Headwall": "array",
  "Compass Headwall": "compass",
};

function Product() {
  const { values, setValues } = useHeadWallStore();
  const { resetState } = useRightPanelStore();

  //  Queries
  const { data: products } = useQuery({
    queryKey: ["products"],
    queryFn: getProducts,
  });

  //function mutation
  const { mutate } = useMutation("templates", {
    mutationFn: (headwall) => getTemplates(headwall),
    onSuccess: (data) => {
      console.log({ data });
    },
    onError: (err) => {
      errorAlert(err.response.data.message);
    },
  });

  const handleProduct = (headwalltype) => {
    mutate(headwalltype);
    if (headwalltype === PRODUCT.INFINITY)
      setValues({
        ...values,
        model: headwalltype,
        name: values.name,
        width: { ft: 3, in: 0, ftError: "" },
        headwalls: [{ position: "middle", services: [], width: 0 }],
      });
    else if (headwalltype === PRODUCT.AXIOM)
      setValues({
        ...values,
        model: headwalltype,
        name: values.name,
        width: { ft: 2, in: 0, ftError: "" },
        headwalls: [{ position: "middle", services: [], width: 0 }],
      });
    else if (
      headwalltype === PRODUCT.COMPASS ||
      headwalltype === PRODUCT.ARRAY
    ) {
      const width = headwalltype === PRODUCT.COMPASS ? 12 : 2;
      setValues({
        ...values,
        model: headwalltype,
        name: values.name,
        width: { ft: 0, in: 0, ftError: "" },
        headwalls: [{ position: "", services: [], width: width }],
      });
    }
    resetState();
  };

  return (
    <div className="pt-4">
      <p className="font-bold text-base txtBlue">Headwall Style</p>
      <div className="overflow-auto newheight pr-4">
        <div className="grid grid-cols-1 gap-4 text-white mt-4 mb-4">
          {products?.data?.data.map((pro, i) => (
            <div
              key={i}
              className={`rounded-lg border-2 border-orange-600 overflow-hidden cursor-pointer hover:opacity-100 ${
                values.model === models[pro.name] || values.model === ""
                  ? "opacity-100"
                  : "opacity-50"
              }`}
              onClick={() => handleProduct(models[pro.name])}
            >
              <img
                draggable={false}
                src={pro.image_url}
                alt="..img"
                className="w-full"
              />
              <p className=" text-sm font-bold bg-white text-orange-600 p-4 flex justify-center">
                {pro.name}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default memo(Product);
