import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  useCallback,
} from "react";
import html2canvas from "html2canvas";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import ContentEditable from "react-contenteditable";
import {
  Stepper,
  Product,
  DesignPanel,
  Services,
  Submit,
  SubProducts,
} from "components";
import RightPanel from "layout/right-panel";
import HeadWalls from "components/editor/headwalls";
import { deleteAlert } from "config/sweet-alert/alert";
import { DiscardIcon, ScaleIcon } from "assets/images";
import {
  checkInfinityDropLocation,
  checkAxiomDropLocation,
  checkCompassDropLocation,
  handleCompassDrop,
  handleInfinityAxiomDrop,
  handleArrayDrop,
  checkArrayDropLocation,
  convertInchesToFeetAndInches,
  headwallData,
} from "config/calculations/calculation";
import { PRODUCT } from "constants/appConstant";
import { useLoaderStore, useHeadWallStore, useServiceStore } from "store";
import { editDesign } from "services/headwallServices";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "react-query";

function Editor() {
  const [searchParams] = useSearchParams();
  const designId = searchParams.get("id");
  const { setLoading } = useLoaderStore();
  const { setService } = useServiceStore();
  const { values, setValues, resetState } = useHeadWallStore();
  const {
    step,
    add_rail,
    width,
    model,
    headwalls,
    design_image,
    name,
    sub_model,
  } = values;
  //ref
  const designNameRef = useRef("Design1");
  const dragItem = useRef();
  const dragPositions = useRef({ x: 0, y: 0 });
  const dragItemNode = useRef();
  const wallRef = useRef(null); //to get Inner wall html;
  const outeWwallRef = useRef(null); //to get outer wall html;
  const railDimensionsRef = useRef(null);

  //states
  //to check the width of wall div
  const [wallWidth, setWallWidth] = useState(0);
  const [dragEntered, setDragEntered] = useState(false);
  //to verify the original name with the updated while editing design
  const [designName, setDesignName] = useState("");
  const [ruler, setRuler] = useState(true);

  useQuery({
    queryKey: ["design", designId],
    queryFn: () => editDesign(designId),
    // The query will not execute until the userId exists
    enabled: !!designId,
    refetchOnWindowFocus: false,
    onSuccess: (data) => {
      const { data: res } = data.data;
      const { width, ceiling_height, headwalls: sections, model, name } = res;
      const headwallWidth = convertInchesToFeetAndInches(width);
      const headwallHeight = convertInchesToFeetAndInches(ceiling_height);
      const headwalls = headwallData(sections, model);
      setValues({
        ...res,
        width: headwallWidth,
        step: 3,
        ceiling_height: headwallHeight,
        ...headwalls,
      });
      setDesignName(name);
    },
  });

  useEffect(() => {
    if (outeWwallRef.current) setWallWidth(outeWwallRef.current.clientWidth);
    //  Scroll code written ////
    switch (add_rail) {
      case "top":
        return outeWwallRef?.current?.scrollTo({
          top: outeWwallRef?.current?.clientHeight / 2 + 450,
          behavior: "smooth",
        });
      case "bottom":
        return outeWwallRef?.current?.scrollTo({
          top: outeWwallRef?.current?.clientHeight + 1000,
          behavior: "smooth",
        });
      case "both":
        return outeWwallRef?.current?.scrollTo({
          top: outeWwallRef?.current?.clientHeight / 2,
          behavior: "smooth",
        });

      default:
        return outeWwallRef?.current?.scrollTo({
          top: outeWwallRef?.current?.clientHeight / 2 + 450,
          behavior: "smooth",
        });
    }
  }, [width, model, add_rail, outeWwallRef.current?.clientWidth]);

  const handleDragStart = useCallback(
    (
      item,
      e = null,
      railIndex = null,
      serviceIndex = null,
      rowIndex = null //for compass rows
    ) => {
      e.dataTransfer.effectAllowed = "copyMove";
      dragItemNode.current = e.target;
      const draggedElement = e.target;

      // // Set the clone as the drag image
      if (item?.is_slide && model === PRODUCT.INFINITY)
        e.dataTransfer.setDragImage(
          draggedElement,
          e.target.offsetWidth / 2,
          0
        );
      else {
        e.dataTransfer.setDragImage(
          draggedElement,
          e.target.offsetWidth / 2,
          e.target.offsetHeight / 2
        );
      }

      dragItem.current = {
        service: item,
        railIndex: railIndex,
        serviceIndex: serviceIndex,
        dragServiceHeight: e.target.offsetHeight,
        dragServiceWidth: e.target.offsetWidth,
        rowIndex: rowIndex, //for compass
      };
    },
    [model]
  );

  const handleDragEnter = (e, railIndex = null, row = null) => {
    //getting here rail index to check in which section of compass elements gets dragged for compass slide calculations
    e.stopPropagation();
    const { service } = dragItem.current;
    setDragEntered(true);
    //get the dimesions of droppable div
    const dropContainerRect = e.target.getBoundingClientRect();
    // Get the parent div (outer div)
    let parentRect;
    let parentWidth;
    let parentHeight;
    let width;
    let left;
    let top;
    if (
      (model === PRODUCT.COMPASS || model === PRODUCT.ARRAY) &&
      service?.is_slide
    ) {
      const parentBlock = document.getElementById(
        `compass_section_${railIndex}`
      );
      parentRect = parentBlock.getBoundingClientRect();
      parentWidth = parentRect.width;
      width = parentRect.width;
      left = parentRect.left;
      parentHeight = parentRect.height;
      top = parentRect.top;
    } else if (model === PRODUCT.COMPASS && !service?.is_slide) {
      const parentBlock = document.getElementById(
        `compass_section_${railIndex}`
      );
      parentRect = parentBlock.getBoundingClientRect();
      parentWidth = e.target.offsetWidth;
      parentHeight = parentRect.height;
      width = dropContainerRect.width;
      left = dropContainerRect.left;
      top = parentRect.top;
    } else if (model === PRODUCT.ARRAY && !service?.is_slide) {
      const parentBlock = document.getElementById(
        `compass_section_${railIndex}`
      );
      const rowBlock = document.getElementById(`array_row_${railIndex}_${row}`);
      parentRect = parentBlock.getBoundingClientRect();
      const rowRect = rowBlock?.getBoundingClientRect();
      parentWidth = e.target.offsetWidth;
      parentHeight = rowRect?.height;
      width = rowRect?.width;
      left = rowRect?.left;
      top = rowRect?.top;
    } else {
      parentWidth = e.target.offsetWidth;
      parentHeight = e.target.offsetHeight;
      width = dropContainerRect.width;
      left = dropContainerRect.left;
      top = dropContainerRect.top;
    }

    railDimensionsRef.current = {
      parentWidth,
      parentHeight,
      width,
      left,
      top,
    };
  };

  const handleonDragOver = (e, draggingArea) => {
    e.preventDefault();
    e.stopPropagation();
    if (draggingArea === "rail") {
      // // Get the dimensions of the child div
      const { dragServiceHeight, dragServiceWidth, service } = dragItem.current;

      //to get the rail dimensions
      const deltaX =
        e.clientX - railDimensionsRef.current?.left - dragServiceWidth / 2;

      const deltaY =
        e.clientY - railDimensionsRef.current?.top - dragServiceHeight / 2;

      let newPosition = {
        x: deltaX,
        y: deltaY,
      };

      // // Calculate the boundaries within which the child div can move
      const maxX = railDimensionsRef.current?.parentWidth - dragServiceWidth;
      const maxY = railDimensionsRef.current?.parentHeight - dragServiceHeight;

      //------start of x while adding chase on left or right side-----//
      const startX = railMargins.start;
      const lastX = railMargins.end;
      const startY = railMargins.top;
      const lastY = railMargins.bottom;
      // // Restrict the child div's movement within the parent div
      const restrictedX = Math.max(
        startX,
        Math.min(newPosition.x, maxX - lastX)
      );
      const restrictedY = Math.max(
        startY,
        Math.min(newPosition.y, maxY - lastY)
      );
      // // Update the position of the child div
      // console.log({ restrictedX, restrictedY });
      if (model === PRODUCT.AXIOM && service?.is_slide) {
        if (restrictedX < railDimensionsRef.current?.width / 2)
          dragPositions.current = { x: 0, y: restrictedY };
        else
          dragPositions.current = {
            x: railDimensionsRef.current.width,
            y: restrictedY,
          };
      } else dragPositions.current = { x: restrictedX, y: restrictedY };
    }
  };

  const generateImage = async () => {
    try {
      setLoading(true);
      const content = wallRef.current;
      // Hiding scale
      const horizontalScale = content.querySelector(".scale");
      const verticalScale = content.querySelector(".vertical-scale");
      if (horizontalScale && verticalScale) {
        horizontalScale.style.visibility = "hidden"; // Hide each element
        verticalScale.style.visibility = "hidden"; // Hide each element
      }

      const scrollableHeight = content.scrollHeight;
      const result = await html2canvas(content, {
        proxy: "https://api.hsiheadwalls.com/html2canvas",
        height: scrollableHeight,
        windowHeight: scrollableHeight,
        scrollX: 0,
        scrollY: 0,
        allowTaint: false,
        backgroundColor: "rgba(181, 185, 189, 0.2)",
      });
      setValues({ ...values, design_image: result.toDataURL() });
    } catch (err) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = () => {
    if (step > 1)
      deleteAlert(
        handleDiscard,
        "Are you sure you wish to discard this screen?",
        "Please keep in mind that entire screen will be deleted and this process cannot be reversible.",
        "Yes, Discard!"
      );
  };

  const handleDiscard = () => {
    resetState();
    setService("");
  };

  const component = useMemo(() => {
    switch (step) {
      case 1:
        return <Product />;
      case 2:
        return <SubProducts />;
      case 3:
        return <DesignPanel />;
      case 4:
        return <Services handleDragStart={handleDragStart} />;
      case 5:
        return <Submit />;
      default:
        return <Product />;
    }
  }, [step, handleDragStart]); //rightPanelSteps,

  const headWallLabels = useMemo(() => {
    switch (step) {
      case 1:
        return "Product";
      case 2:
        return "Product";
      case 3:
        return "Design Panel";
      case 4:
        return "Add Services";
      case 5:
        return "Save Your Design";
      default:
        return "";
    }
  }, [step]);

  const headwallName = useMemo(() => {
    if (model === PRODUCT.AXIOM) return `${model} ${sub_model}`;
    else return model;
  }, [model, sub_model]);

  const railMargins = useMemo(() => {
    const { model, chase_position, chased } = values;
    if (model === PRODUCT.INFINITY) {
      if (!chased || chase_position === "center")
        return { start: 12.25, end: 12.25, top: 0, bottom: 0, between: 12.25 };
      else if (chase_position === "left")
        return { start: 107.25, end: 12.25, top: 0, bottom: 0, between: 12.25 };
      else if (chase_position === "right")
        return { start: 12.25, end: 107.25, top: 0, bottom: 0, between: 12.25 };
    } else if (model === PRODUCT.AXIOM) {
      return {
        start: 20.0892857143, //to manage 1.125 from start
        end: 18.732142857, //to manage 1.125 from end
        top: 228.2, //to manage 0.75 from end
        bottom: 0,
        between: 2.23214285714,
      };
    } else if (model === PRODUCT.COMPASS) {
      return { start: 0, end: 0, top: 0, bottom: 0, between: 0 };
    } else if (model === PRODUCT.ARRAY) {
      return {
        start: 4.46428571429,
        end: 16.46428571429, //4.46428571429
        top: 6.69642857143,
        bottom: 6.69642857143,
        between: 8.92857142857,
      };
    }
  }, [values]);

  const handleDrop = useCallback(
    (e, dropArea, railIndex = null, rowIndex = null) => {
      e.stopPropagation();
      if (dragItem.current) {
        setDragEntered(false);
        const rails = [...headwalls];
        const {
          railIndex: dragRailIndex, ////from where service is dragged
          service,
          // serviceIndex,
          rowIndex: dragRowIndex, //from where service is dragged
        } = dragItem.current;

        let dropLocation;
        //check droplocation only for the services that are dropped on rail
        if (dropArea === "rail") {
          if (model === PRODUCT.INFINITY)
            dropLocation = checkInfinityDropLocation(
              railIndex,
              dragItem,
              values,
              dragPositions,
              railDimensionsRef,
              railMargins
            );
          else if (model === PRODUCT.AXIOM) {
            if (service.category === "Gas") railMargins["between"] = 20; //margins for gas service is different
            dropLocation = checkAxiomDropLocation(
              railIndex,
              dragItem,
              values,
              dragPositions,
              railDimensionsRef,
              railMargins
            );
          } else if (model === PRODUCT.COMPASS) {
            dropLocation = checkCompassDropLocation(
              rowIndex,
              railIndex,
              dragItem,
              values,
              dragPositions,
              railMargins
            );
          } else if (model === PRODUCT.ARRAY) {
            dropLocation = checkArrayDropLocation(
              rowIndex,
              railIndex,
              dragItem,
              values,
              dragPositions,
              railMargins,
              railDimensionsRef
            );
          }
        }
        //drop from service panel and interchange of services between panels
        if (model === PRODUCT.INFINITY || model === PRODUCT.AXIOM) {
          handleInfinityAxiomDrop(
            e,
            rails,
            railIndex,
            dragRailIndex,
            values,
            dragPositions,
            railDimensionsRef,
            railMargins,
            dropLocation,
            service,
            // serviceIndex,
            dragItemNode
          );
        }
        //calculation for compass
        else if (model === PRODUCT.COMPASS) {
          handleCompassDrop(
            e,
            rails,
            railIndex,
            dragRailIndex,
            values,
            dragPositions,
            railDimensionsRef,
            railMargins,
            dropLocation,
            service,
            // serviceIndex,
            dragItemNode,
            rowIndex,
            dragRowIndex
          );
        } else if (model === PRODUCT.ARRAY) {
          handleArrayDrop(
            e,
            rails,
            railIndex,
            dragRailIndex,
            values,
            dragPositions,
            railDimensionsRef,
            railMargins,
            dropLocation,
            service,
            // serviceIndex,
            dragItemNode,
            rowIndex,
            dragRowIndex
          );
        }
        //calculation for array
        setValues({ ...values, headwalls: rails });
        dragItem.current = "";
      }
    },
    [headwalls, model, railMargins, setValues, values]
  );

  useEffect(() => {
    const handlDragServOutside = (e) => {
      e.preventDefault();
    };

    const handlDropServOutside = (e) => {
      handleDrop(e, "wall");
    };

    // Add event listener when the component mounts
    document.addEventListener("dragover", handlDragServOutside);
    document.addEventListener("drop", handlDropServOutside);

    // Clean up the event listener when the component unmounts
    return () => {
      document.removeEventListener("drop", handlDropServOutside);
    };
  }, [handleDrop]);
  // console.log(headwalls[0].services)

  return (
    <>
      <div className="wall_contatiner">
        <div className="mt-9">
          <Stepper currentStep={step} />
        </div>
        <div className="mt-6 mb-5 text-base font-bold flex justify-between">
          <div className="flex items-center txtBlue titleh2Txt">
            {headWallLabels}
          </div>
          {step >= 3 && (
            <div className="flex items-center txtBlue rootPathTxt">
              <b className="valueTypeTxt capitalize">{headwallName} </b>{" "}
              <b className="slashTxt">/</b>
              <span>
                <ContentEditable
                  className="text-slate-950 customTxt design_name cursor-text"
                  innerRef={designNameRef}
                  html={name} // innerHTML of the editable div
                  onKeyDown={(e) => {
                    if (
                      e.key === "Enter" ||
                      (!designNameRef.current.textContent && e.key === " ")
                    ) {
                      e.preventDefault();
                    }
                  }}
                  onChange={(e) => {
                    if (e.target.value === "<br>")
                      setValues({
                        ...values,
                        name: "",
                      });
                    else {
                      const specialCharRegex = /[^a-zA-Z0-9\s]/g; // Allow letters, numbers, and spaces only

                      // Get the current content and remove special characters
                      const sanitizedContent = e.target.value.replace(
                        specialCharRegex,
                        ""
                      );
                      const truncatedHtml = sanitizedContent.substring(0, 30);
                      setValues({
                        ...values,
                        name: truncatedHtml,
                      });
                    }
                  }} // handle innerHTML change
                  tagName="article" // Use a custom HTML tag (uses a div by default)
                />
              </span>
              <img
                src={DiscardIcon}
                alt="...img"
                className={`ml-5 ${
                  step > 1 ? "cursor-pointer" : "cursor-not-allowed"
                }`}
                onClick={handleDelete}
                data-te-toggle="tooltip"
                data-te-placement="top"
                data-te-ripple-init
                data-te-ripple-color="light"
                title="Discard Design"
              />
            </div>
          )}
        </div>
        {step <= 4 ? (
          <div className="wall relative">
            {model && (
              <img
                src={ScaleIcon}
                alt="..."
                className={`scaleIcon ${ruler ? "opacity-100" : "opacity-50"}`}
                onClick={() => setRuler(!ruler)}
              />
            )}
            <div
              ref={outeWwallRef}
              className="wall overflow-auto px-4 relative w-full"
              onDragOver={(e) => handleonDragOver(e, "wall")}
              onDrop={(e) => handleDrop(e, "wall")}
            >
              <HeadWalls
                values={values}
                ruler={ruler}
                handleonDragOver={handleonDragOver}
                handleDrop={handleDrop}
                handleDragStart={handleDragStart}
                handleDragEnter={handleDragEnter}
                dragEntered={dragEntered}
                wallLength={wallWidth}
                ref={wallRef}
                setDragEntered={setDragEntered}
              />
            </div>
          </div>
        ) : (
          <div
            className="flex justify-center  relative headwall_bg"
            style={{ height: `calc(100vh - 223px)` }}
          >
            <TransformWrapper defaultScale={1}>
              {({ zoomIn, zoomOut }) => (
                <>
                  <div className="btn_container">
                    <button className="btn" onClick={() => zoomIn()}>
                      +
                    </button>
                    <button className="btn" onClick={() => zoomOut()}>
                      -
                    </button>
                  </div>
                  <TransformComponent
                    contentStyle={{
                      justifyContent: "center",
                      height: `calc(100vh - 240px)`,
                      // width:"auto",
                      minWidth: wallWidth ? wallWidth : "auto",
                    }}
                  >
                    <img
                      src={design_image}
                      alt="..img"
                      style={{ height: "100%" }}
                    />
                  </TransformComponent>
                </>
              )}
            </TransformWrapper>
          </div>
        )}
      </div>

      <RightPanel
        children={component}
        handleGenerateImage={generateImage}
        designId={designId}
        designName={designName}
      />
    </>
  );
}

export default Editor;
