import React, { forwardRef, useMemo } from "react";
import useServiceStore from "store/serviceStore";
import { GRAINS } from "constants/appConstant";
import { HorizontalRuler } from "components";
import Wrapper from "./headwall-components/wrapper";

const ARRAY = {
  SECTION_2_HEIGHT: 535.714285714,
  SECTION_3_HEIGHT: 535.714285714,
};

const Array = forwardRef((props, ref) => {
  const {
    values,
    ruler,
    handleDragStart,
    handleonDragOver,
    handleDrop,
    handleDragEnter,
    dragEntered,
    setDragEntered,
  } = props;

  const { setService } = useServiceStore();
  const { color, headwalls, ceiling_height, background, model } = values;

  const wallWidth = useMemo(() => {
    let totalWidth = 0;
    //added 10 inches extra to manage wall width
    if (headwalls.length >= 2) {
      headwalls.map((wall) => {
        totalWidth += Number(wall.width);
        return totalWidth;
      });
      return (totalWidth * 12) / 0.056 + 100;
    }
  }, [headwalls]);

  const rulerWidth = useMemo(() => {
    let totalWidth = 0;
    headwalls.map((wall) => {
      totalWidth += Number(wall.width);
      return totalWidth;
    });
    return { ft: totalWidth, in: 0 };
  }, [headwalls]);

  const rulerSectionWidth = useMemo(() => {
    //Added 12 px more to manage border and 2px for each compartment to manage gap
    // return Math.round((rulerWidth.ft * 12) / 0.056 + 11);
    const baseWidth = (rulerWidth.ft * 12) / 0.056;
    const numberOfSections = headwalls.length; 
    let additionalWidth = 12;
    if (numberOfSections === 2)
      additionalWidth += 14; 
    else if (numberOfSections === 3)
      additionalWidth = 40; 
    else if (numberOfSections === 4)
      additionalWidth = 54; 
    else if (numberOfSections === 5) additionalWidth = 68; 
    return baseWidth + additionalWidth;
  }, [rulerWidth, headwalls.length]);

  const wallHeight = useMemo(() => {
    return Math.round((ceiling_height.ft * 12 + ceiling_height.in) / 0.056);
  }, [ceiling_height]);

  const ArrayHeight = useMemo(() => {
    return (ceiling_height.ft * 12 + ceiling_height.in - 6) / 0.056; //6inc from bottom
  }, [ceiling_height]);

  const sectionWidth = (width) => {
    //added 11px extra to manage borders of array
    // return (Number(width) * 12) / 0.056 + 11;
    return (Number(width) * 12) / 0.056;
  };

  const serviceWidth = (ser) => {
    return Math.round(ser.gang_width / 0.056);
  };

  const Section1Height = useMemo(() => {
    return (ceiling_height.ft * 12 + ceiling_height.in - 66) / 0.056; //subtracting 66inch for the rest two sections
  }, [ceiling_height]);

  const row1Height = useMemo(() => {
    return Section1Height - 35.7142857143 - 51.3392857143;
  }, [Section1Height]);

  return (
    <Wrapper
      ruler={ruler}
      width={wallWidth}
      wallHeight={wallHeight}
      ceiling_height={ceiling_height}
      model={model}
      ref={ref}
    >
      <div className="flex gap-[2px]  h-full items-end pb-[24px]">
        {headwalls.map((rail, railIndex) => (
          <div key={railIndex}>
            <div
              style={{
                // width: sectionWidth(rail.width),
                height: ArrayHeight + 13,
              }}
              className="flex flex-col gap-[2px] relative array"
              id={`compass_section_${railIndex}`}
              onDragEnter={(e) => handleDragEnter(e, railIndex)}
              onDrop={(e) => handleDrop(e, "rail", railIndex)}
            >
              {/* slide services */}
              {rail.services
                .filter((serv) => serv.is_slide)
                .map((ser, serviceIndex) => (
                  <div
                    draggable
                    key={serviceIndex}
                    className={`absolute flex justify-center z-10 ${
                      dragEntered ? "non_dropable " : ""
                    }`}
                    style={{
                      left: ser.positions?.x,
                      top: ser.positions?.y,
                      cursor: "grab",
                    }}
                    onDragStart={(e) =>
                      handleDragStart(ser, e, railIndex, serviceIndex)
                    }
                    onDragEnter={(e) => handleDragEnter(e, railIndex)}
                    onClick={() => setService(ser, true)}
                  >
                    <div
                      draggable={"false"}
                      style={{
                        width: serviceWidth(ser),
                        backgroundImage: `url(${ser.image_url})`,
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundPosition: "center",
                        pointerEvents: "none",
                        height: ser.height / 0.056,
                      }}
                      onDragEnter={(e) => e.stopPropagation()}
                    />
                  </div>
                ))}
              {/* section 1 */}
              <div
                className="w-full compass-section relative"
                style={{
                  width: sectionWidth(rail.width),
                  background: color,
                  height: `${Section1Height}px`,
                  backgroundImage: `url(${GRAINS[background]})`,
                }}
                onDragEnter={(e) => handleDragEnter(e, railIndex)}
                onDragOver={(e) => handleonDragOver(e, "rail")}
                onDrop={(e) => handleDrop(e, "rail", railIndex)}
              >
                {/* Row 1 */}
                <Row
                  row={1}
                  rail={rail}
                  railIndex={railIndex}
                  dragEntered={dragEntered}
                  className={`mx-[29.01px] mb-[35.71428571428571px] mt-[51.33928571428571px]`}
                  handleonDragOver={handleonDragOver}
                  handleDrop={handleDrop}
                  handleDragEnter={handleDragEnter}
                  handleDragStart={handleDragStart}
                  rowHeight={row1Height}
                  setDragEntered={setDragEntered}
                />
              </div>
              {/* section 2 */}
              <div
                className="w-full compass-section relative"
                style={{
                  background: color,
                  height: `${ARRAY.SECTION_2_HEIGHT}px`,
                  backgroundImage: `url(${GRAINS[background]})`,
                }}
                onDragEnter={(e) => handleDragEnter(e, railIndex)}
                onDragOver={(e) => handleonDragOver(e, "rail")}
                onDrop={(e) => handleDrop(e, "rail", railIndex)}
              >
                {/* Row 2 */}
                <Row
                  row={2}
                  rail={rail}
                  railIndex={railIndex}
                  dragEntered={dragEntered}
                  className={
                    "h-[464.2857142857143px] mx-[29.01px] mb-[35.7142857143px] mt-[35.7142857143px]"
                  }
                  handleonDragOver={handleonDragOver}
                  handleDrop={handleDrop}
                  handleDragEnter={handleDragEnter}
                  handleDragStart={handleDragStart}
                  setDragEntered={setDragEntered}
                />
              </div>
              {/* section 3 */}
              <div
                className="w-full compass-section relative"
                style={{
                  background: color,
                  height: `${ARRAY.SECTION_3_HEIGHT}px`,
                  backgroundImage: `url(${GRAINS[background]})`,
                }}
                onDragEnter={(e) => handleDragEnter(e, railIndex)}
                onDragOver={(e) => handleonDragOver(e, "rail")}
                onDrop={(e) => handleDrop(e, "rail", railIndex)}
              >
                {/* Row 3 */}
                <Row
                  row={3}
                  rail={rail}
                  railIndex={railIndex}
                  dragEntered={dragEntered}
                  className={
                    "h-[448.6607142857143px]  mx-[29.01px] mb-[51.3392857143px] mt-[35.7142857143px]"
                  }
                  handleonDragOver={handleonDragOver}
                  handleDrop={handleDrop}
                  handleDragEnter={handleDragEnter}
                  handleDragStart={handleDragStart}
                  setDragEntered={setDragEntered}
                />
              </div>
            </div>
            <p className="flex justify-center mt-10 txtBlue font-semibold text-base">
              Section {railIndex + 1}
            </p>
          </div>
        ))}
      </div>
      {ruler && (
        <HorizontalRuler
          class_name={`items-center bottom-[17px] z-50`}
          width={rulerWidth}
          rulerSectionWidth={rulerSectionWidth}
        />
      )}
    </Wrapper>
  );
});

export default Array;

const Row = ({
  row,
  rail,
  railIndex,
  dragEntered,
  className,
  handleonDragOver,
  handleDrop,
  handleDragEnter,
  handleDragStart,
  rowHeight,
  setDragEntered,
}) => {
  const rowArray = [
    ...new Set(
      rail?.services
        .filter((serv) => !serv.is_slide && serv.rowPosition === row)
        .map((item) => item.positions.y)
    ),
  ];

  const { setService } = useServiceStore();
  const serviceWidth = (ser) => {
    return Math.round(ser.width / 0.056);
  };

  const servicePadding = (ser) => {
    if (!ser.is_slide) return `6.69642857143px  4.46428571429px`;
    else return `5px 0 5px 0`;
  };

  return (
    <>
      {/* added array row className to get the height and widht of row */}
      <div
        className={`relative ${className} ${dragEntered && "bg-green-100 "}`}
        id={`array_row_${railIndex}_${row}`}
        onDragEnter={(e) => handleDragEnter(e, railIndex, row)}
        onDragOver={(e) => handleonDragOver(e, "rail")}
        onDrop={(e) => handleDrop(e, "rail", railIndex, row)}
        style={{ height: rowHeight }}
      >
        {rowArray.map((r, i) => (
          <div
            draggable={false}
            key={i}
            className={`w-full h-[107px] absolute ${
              dragEntered ? "border-dashed border-y-2 border-slate-600" : ""
            }`}
            style={{ transform: `translate(0, ${r - 6.69642857143}px)` }}
            onDragEnter={(e) => handleDragEnter(e, railIndex, row)}
          >
            <>
              {rail.services
                .filter((serv) => serv.rowPosition === row && !serv.is_slide)
                .filter((serv) => serv.positions.y === r)
                .map((ser, serviceIndex) => (
                  <div
                    key={serviceIndex}
                    className={`absolute flex justify-center ${
                      dragEntered ? "non_dropable " : ""
                    }`}
                    style={{
                      padding: servicePadding(ser),
                      // top: `${ser.positions?.y - 6.69642857143}px`,
                      left: `${ser.positions?.x - 4.46428571429}px`,
                    }}
                    onDragEnter={(e) => e.stopPropagation()}
                  >
                    <div
                      draggable
                      className="z-10"
                      style={{
                        width: serviceWidth(ser),
                        cursor: "grab",
                      }}
                      onDragStart={(e) =>
                        handleDragStart(ser, e, railIndex, serviceIndex, row)
                      }
                      onDragEnter={(e) => e.stopPropagation()}
                      onClick={() => setService(ser, true)}
                    >
                      <div
                        draggable={"false"}
                        className="pointer-events-none"
                        style={{
                          width: serviceWidth(ser),
                          backgroundImage: `url(${ser.image_url})`,
                          backgroundSize: "contain",
                          backgroundRepeat: "no-repeat",
                          backgroundPosition: "center",
                          pointerEvents: "none",
                          height: ser.height / 0.056,
                        }}
                        onDragEnter={(e) => e.stopPropagation()}
                      />
                    </div>
                  </div>
                ))}
            </>
          </div>
        ))}
      </div>
    </>
  );
};
