import React, { useState, useEffect } from "react";
import { Link, useSearchParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { Input, Button } from "components";
import { resetPasswordSchema } from "config/schema";
import { resetPassword } from "services/authServices";
import { errorAlert, successAlert } from "config/sweet-alert/alert";
import { LockIcon, HideIcon, ShowIcon } from "assets/images";
import { useLoaderStore } from "store";

function ResetPassword() {
  const [searchParams] = useSearchParams();
  const { setLoading } = useLoaderStore();
  // Get specific query parameters
  const token = searchParams.get("token")?.replaceAll(" ", "+");
  const user_id = searchParams.get("userid");
  const navigate = useNavigate();
  //state
  const [show, setShow] = useState({
    password: false,
    confirm_password: false,
  });

  //formik
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        password: "",
        confirm_password: "",
      },
      validationSchema: resetPasswordSchema,
      onSubmit: (values) => {
        _handleSubmit({
          ...values,
          token,
          user_id,
        });
      },
    });

  //function mutation
  const { mutate, isLoading } = useMutation({
    mutationFn: resetPassword,
    onSuccess: (res) => {
      successAlert("Ok", res?.data?.message);
      navigate("/");
    },
    onError: (err) => {
      errorAlert(err.response.data.message);
    },
  });

  //manage loader
  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);

  const _handleSubmit = async (values) => {
    mutate(values);
  };

  return (
    <div className="flex items-center justify-center  w-full login_outer">
      <form className="login_container " onSubmit={handleSubmit}>
        <h6 className="font-bold text-center text-2xl txtBlue">Set New Password</h6>
        <div className="my-2">
          <label className="inputTag text-lg">Password</label>
          <div className=" login_input flex items-center">
            <span className="ml-3">
              <img src={LockIcon} alt="img" />
            </span>
            <Input
              type={show.password ? "text" : "password"}
              name="password"
              className="ml-4 w-full h-full"
              placeholder="Enter Password here"
              value={values.password}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />

            <span
              className="mr-4 cursor-pointer"
              onClick={() => setShow({ ...show, password: !show.password })}
            >
              <img src={show.password ? ShowIcon : HideIcon} alt="img" />
            </span>
          </div>
          {errors.password && touched.password ? (
            <p className="text-red-600">{errors.password}</p>
          ) : null}
        </div>
        <div className="my-2">
          <label className="inputTag text-lg">Confirm Password</label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={LockIcon} alt="img" />
            </span>
            <Input
              type={show.confirm_password ? "text" : "password"}
              name="confirm_password"
              className="ml-4 w-full h-full"
              placeholder="Enter Confirm Password here"
              value={values.confirm_password}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />

            <span
              className="mr-4 cursor-pointer"
              onClick={() =>
                setShow({ ...show, confirm_password: !show.confirm_password })
              }
            >
              <img
                src={show.confirm_password ? ShowIcon : HideIcon}
                alt="img"
              />
            </span>
          </div>
          {errors.confirm_password && touched.confirm_password ? (
            <p className="text-red-600">{errors.confirm_password}</p>
          ) : null}
        </div>
        <div className="mt-4 w-full">
          <Button
            className="login_btn p-4 w-full text-white font-bold text-base"
            text={"Continue"}
            isDisabled={isLoading}
            type={"submit"}
          />
        </div>
        <div className="text-center">
          <p className="py-3 text-xl font-semibold"> or</p>
          <Link to="/signup" className="font-extrabold text-sm opacity-100">
            <span className="text-lg font-medium underline underline-offset-8 ">
              Back to Sign In
            </span>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default ResetPassword;
