import React from "react";

const chasePosition = {
  center: "absolute z-10",
  right: "absolute right-[-172px]",
  left: "",
};

function Chase({ chase_color, height, chase_position, background_image }) {
  return (
    <>
      <div
        className={` chase_outer ${chasePosition[chase_position]}`}
        style={{ height: `${height}px` }}
      >
        <div
          className="h-full chase_inner w-[151px] grain"
          style={{
             backgroundColor: chase_color,
            backgroundImage:`url(${background_image})`
             }}
        ></div>
      </div>
    </>
  );
}

export default Chase;
