import { PRODUCT } from "constants/appConstant";
import React, { forwardRef } from "react";

const NormalWrapper = forwardRef((props, ref) => {
  const { children, width, wallLength, railWidth, wallHeight, model } = props;
  return (
    <div
      ref={ref}
      className={`h-full flex  border-y-2 my-3 min-w-full items-end ${
        model === PRODUCT.INFINITY && wallLength > railWidth + 20
          ? "justify-center"
          : model !== PRODUCT.INFINITY
          ? "justify-center"
          : ""
      }`}
      style={{ height: wallHeight, width: width }}
    >
      {children}
    </div>
  );
});

export default NormalWrapper;
