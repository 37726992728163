import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { Input, Button } from "components";
import { forgotPasswordSchema } from "config/schema";
import { GmailIcon } from "assets/images";
import { errorAlert, successAlert } from "config/sweet-alert/alert";
import { forgotPassword } from "services/authServices";
import useLoaderStore from "store/loaderStore";

function ForgotPassword() {
  const { setLoading } = useLoaderStore();
  //formik
  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: { email: "" },
      validationSchema: forgotPasswordSchema,
      onSubmit: (values) => {
        _handleSubmit(values);
      },
    });

  //function mutation
  const { mutate, isLoading } = useMutation("signIn", {
    mutationFn: forgotPassword,
    onSuccess: (res) => {
      successAlert("Ok", res?.data?.message);
    },
    onError: (err) => {
      errorAlert(err.response.data.message);
    },
  });

  //manage loader
  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);

  const _handleSubmit = async (values) => {
    mutate(values);
  };

  return (
    <div className="flex items-center justify-center w-full login_outer">
      <form className="login_container  " onSubmit={handleSubmit}>
        <h6 className="font-bold text-center text-2xl txtBlue">
          Forgot Password
        </h6>
        {/* <p className="my-1.5  opacity-50 text-sm font-medium">
          Please enter Email address we will send reset link
        </p> */}
        <div className="my-5 ">
          <label className="inputTag text-lg">Email Address</label>
          <div className="flex items-center login_input ">
            <span className="ml-3">
              <img src={GmailIcon} alt="img" />
            </span>
            <Input
              type="text"
              name="email"
              placeholder="Enter Email Address here"
              className="ml-4 w-full h-full rounded"
              value={values.email}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />
          </div>
          {errors.email && touched.email ? (
            <p className="text-red-600">{errors.email}</p>
          ) : null}
        </div>

        <div className="mt-12 mb-6 w-full">
          <Button
            className="login_btn p-4 w-full text-white font-bold text-base"
            text={"Send Reset Link"}
            isDisabled={isLoading}
            type={"submit"}
          />
        </div>
        <div className="text-center">
          <p className="py-3 text-xl font-semibold"> or</p>
          <Link to="/" className=" font-extrabold text-lg">
          <span className="font-medium text-lg underline underline-offset-8">
            Back to Sign In
          </span>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default ForgotPassword;
