import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { Input, Button, Checkbox } from "components";
import { GmailIcon, LockIcon, HideIcon, ShowIcon } from "assets/images";
import { errorAlert } from "config/sweet-alert/alert";
import { loginSchema } from "config/schema/index.js";
import { signIn } from "services/authServices";
import useLoaderStore from "store/loaderStore";
import useHeadWallStore from "store/headWallStore";

function Login() {
  const { setLoading } = useLoaderStore();
  const { values: { step } } = useHeadWallStore()
  //state
  const [showPassword, setShowPassword] = useState(false);

  //navigation
  const navigate = useNavigate();

  //formik

  const {
    values,
    errors,
    touched,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember_me: false,
    },
    validationSchema: loginSchema,
    onSubmit: (values) => {
      _handleSubmit(values);
    },
  });

  useEffect(() => {
    const creds = JSON.parse(localStorage.getItem("cred"));
    if (creds) {
      setFieldValue("email", creds.email);
      setFieldValue("password", creds.password);
      setFieldValue("remember_me", true);
    }
  }, [setFieldValue]);

  //function mutation
  const { mutate, isLoading } = useMutation({
    mutationFn: signIn,
    onSuccess: (data) => {
      localStorage.setItem("user", JSON.stringify(data.data));
      if (values.remember_me)
        localStorage.setItem("cred", JSON.stringify(values));
      else localStorage.removeItem("cred");
      if (step >= 2) navigate("/editor");
      else navigate("/dashboard");
    },
    onError: (err) => {
      errorAlert(err.response.data.message);
    },
  });

  //manage loader
  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);

  const _handleSubmit = (values) => {
    mutate({ email: values.email, password: values.password });
  };

  return (
    <>
      <div className="flex items-top justify-center w-full login_outer">
        <form className="login_container my-12 " onSubmit={handleSubmit}>
          <h6 className="font-bold text-center text-2xl txtBlue">Sign In</h6>
          <div className="my-5 ">
            <label className="inputTag text-lg">Email Address</label>
            <div className="flex items-center login_input ">
              <span className="ml-3">
                <img src={GmailIcon} alt="img" />
              </span>
              <Input
                type="text"
                name="email"
                placeholder="Enter Email Address here"
                className="ml-4 w-full h-full rounded"
                value={values.email}
                handleChange={(e) => {
                  const { value } = e.target;
                  if (!/\s/.test(value)) {
                    handleChange(e);
                  }
                }}
                handleBlur={handleBlur}
              />
            </div>
            {errors.email && touched.email ? (
              <p className="text-red-600 text-sm">{errors.email}</p>
            ) : null}
          </div>
          <div>
            <label className="inputTag text-lg">Password</label>
            <div className="login_input flex items-center">
              <span className="ml-3">
                <img src={LockIcon} alt="img" />
              </span>
              <Input
                type={showPassword ? "text" : "password"}
                name="password"
                className="ml-4 w-full h-full"
                placeholder="Enter Password here"
                value={values.password}
                handleChange={(e) => {
                  const { value } = e.target;
                  if (!/\s/.test(value)) {
                    handleChange(e);
                  }
                }}
                handleBlur={handleBlur}
              />

              <span
                className="mr-4 cursor-pointer"
                onClick={() => setShowPassword(!showPassword)}
              >
                <img src={showPassword ? ShowIcon : HideIcon} alt="img" />
              </span>
            </div>
            {errors.password && touched.password ? (
              <p className="text-red-600 text-sm">{errors.password}</p>
            ) : null}
          </div>
          <div className="my-5 text-xs flex justify-between items-center">
            <div>
              <Checkbox
                name="remember_me"
                label="Remember me"
                className="ml-2 text-sm font-medium  opacity-85"
                value={values.remember_me}
                handleChange={handleChange}
              />
              {/* <label htmlFor="rememberMe" className="text-sm font-medium">
                Remember me
              </label> */}
            </div>
            <p
              className="text-sm font-medium cursor-pointer "
              onClick={() => navigate("/forgot-password")}
            >
              Forgot password?
            </p>
          </div>
          <div className="mt-4 w-full">
            <Button
              className="login_btn p-4 w-full text-white font-bold text-base"
              text={"Sign In"}
              isDisabled={isLoading}
              type={"submit"}
            />
          </div>
          <div className="text-center">
            <p className="py-3 text-xl font-semibold"> or</p>
            <span className="text-sm font-medium txtGrey">
              Don't have an account?{" "}
            </span>
            <Link to="/signup" className="font-extrabold text-lg opacity-100">
              Sign Up
            </Link>
          </div>
        </form>
      </div>
    </>
  );
}

export default Login;
