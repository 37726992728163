import React, { Fragment, forwardRef, useEffect, useMemo } from "react";
import { BedIcon } from "assets/images";
import useServiceStore from "store/serviceStore";
import { GRAINS } from "constants/appConstant";
// import { HorizontalRuler, VerticalRuler } from "components";
import Wrapper from "./headwall-components/wrapper";
import { HorizontalRuler } from "components";

const Axiom = forwardRef((props, ref) => {
  const {
    values,
    ruler,
    handleDragStart,
    handleonDragOver,
    handleDrop,
    handleDragEnter,
    dragEntered,
    setDragEntered,
  } = props;
  const { setService } = useServiceStore();

  const {
    color,
    headwalls,
    width,
    ceiling_height,
    sub_model,
    background,
    model,
  } = values;

  const axiomWidth = useMemo(() => {
    return (width.ft * 12 + width.in - 3.25) / 0.056; //3.25in for borders
    // return (width.ft * 12 + width.in) / 0.056; //3.25in for borders
  }, [width]);

  const axiomHeight = useMemo(() => {
    return Math.round(
      (ceiling_height.ft * 12 + ceiling_height.in - 12.63) / 0.056 //12.63 from bottom
    );
  }, [ceiling_height]);

  const wallHeight = useMemo(() => {
    return Math.round((ceiling_height.ft * 12 + ceiling_height.in) / 0.056);
  }, [ceiling_height]);

  const wallWidth = useMemo(() => {
    //added 10 inches extra to manage wall width
    if (headwalls.length >= 2)
      return Math.round((width.ft * 12 + width.in + 10) / 0.056) * 2 + 700;
    //700 px for image
    else return Math.round((width.ft * 12 + width.in + 10) / 0.056);
  }, [width, headwalls]);


  const rulerWidth = useMemo(() => {
    //added 2 ft for bed
    let totalFeet = 0;
    let totalInches = 0;
    if (headwalls.length >= 2) {
      totalFeet = width.ft * 2 + 2;
      totalInches = width.in * 2;
      if (totalInches >= 12) {
        totalFeet += Math.floor(totalInches / 12);
        totalInches = totalInches % 12;
      }
    } else {
      totalFeet = width.ft;
      totalInches = width.in;
    }

    return { ft: totalFeet, in: totalInches };
  }, [width, headwalls]);

  useEffect(() => {
    //to adjust scale for axiom
    // Select all elements with the class 'scale'
    if (ruler) {
      const firstElements = document.querySelector(".first");
      const nextElements = document.querySelectorAll(".next");

      // Update styles for 'first' elements
      firstElements.style.maxWidth = "214.285714285px";

      nextElements.forEach((element) => {
        element.style.maxWidth = "214.285714285px";
      });
      // Cleanup function to revert styles when component unmounts
      return () => {
        firstElements.style.maxWidth = "";
        nextElements.forEach((element) => {
          element.style.maxWidth = "";
        });
      };
    }
  }, [ruler, rulerWidth]);

  return (
    <>
      <Wrapper
        ruler={ruler}
        width={wallWidth}
        wallHeight={wallHeight}
        ceiling_height={ceiling_height}
        model={model}
        ref={ref}
      >
        <div className="h-full flex items-end pb-[89px]">
          {headwalls?.map((rail, railIndex) => (
            <Fragment key={railIndex}>
              {railIndex > 0 && (
                <div className="flex items-center relative">
                  <img
                    src={BedIcon}
                    alt="...img"
                    width="100%"
                    height="auto"
                    className=" w-[404px] mx-[8px]"
                    style={{ bottom: "500px" }}
                  />
                </div>
              )}
              <div
                className={`w-max axiom_outer ${
                  sub_model === "flush" && "px-[7.50px]"
                }`}
                style={{ height: axiomHeight }}
                onDrop={(e) => handleDrop(e, "rail", railIndex)}
                onDragOver={(e) => handleonDragOver(e, "rail")}
              >
                <div
                  className={`axiom relative h-full ${
                    dragEntered ? "axiom_compartment" : ""
                  }`}
                >
                  <div
                    className={`h-full ${
                      dragEntered ? "non_dropable_axiom" : ""
                    }`}
                    style={{
                      backgroundColor: color,
                      width: axiomWidth,
                      backgroundImage: `url(${GRAINS[background]})`,
                    }}
                    onDrop={(e) => handleDrop(e, "rail", railIndex)}
                    onDragOver={(e) => handleonDragOver(e, "rail")}
                    onDragEnter={handleDragEnter}
                  >
                    <Rows
                      dragEntered={dragEntered}
                      rail={rail}
                      railIndex={railIndex}
                      handleDragStart={handleDragStart}
                      setService={setService}
                      setDragEntered={setDragEntered}
                    />
                  </div>
                </div>
              </div>
            </Fragment>
          ))}
        </div>
        {ruler && (
          <HorizontalRuler
            class_name={"bottom-[14px]"}
            width={rulerWidth}
            model={model}
          />
        )}
      </Wrapper>
    </>
  );
});

export default Axiom;

const Rows = ({
  dragEntered,
  rail,
  railIndex,
  handleDragStart,
  setService,
  setDragEntered,
}) => {
  const rowArray = [
    ...new Set(
      rail?.services
        .filter((serv) => !serv.is_slide)
        .map((item) => item.positions.y)
    ),
  ];
  const generateStyle = (ser) => {
    const styleObj = {
      padding: servicePadding(ser),
      cursor: "grab",
    };
    const isSafari =
      /Safari/.test(navigator.userAgent) &&
      /Apple Computer/.test(navigator.vendor);
    if (isSafari) {
      styleObj.left = servLeft(ser);
      styleObj.top = ser.positions.y;
    } else styleObj.transform = servicePosition(ser);

    return styleObj;
  };

  const serviceWidth = (ser) => {
    if (ser.category === "Medical Gas Outlet" && !ser?.is_slide)
      return (ser.width + 2.375) / 0.056; //2.375 for side margins
    else return (ser.width + 0.125) / 0.056;
    // return Math.round(ser.width / 0.056) + 2;
  };

  const servicePosition = (ser) => {
    if (ser?.is_slide) {
      if (!ser.positions.x) return `translate(${-27}px, ${ser.positions?.y}px)`;
      else return `translate(${ser.positions?.x - 6}px, ${ser.positions?.y}px)`;
    } else return `translate(${ser.positions?.x}px)`;
  };

  const servLeft = (ser) => {
    if (ser?.is_slide) {
      if (!ser.positions.x) return "-27px";
      else return `${ser.positions?.x - 6}px`;
    } else return `${ser.positions?.x}px`;
  };

  const servicePadding = (ser) => {
    if (!ser?.is_slide) return `13.39px 0 13.39px 0`;
    else return `5px 0 5px 0`;
  };

  return (
    <>
      {/* for normal services */}
      {rowArray.map((row, i) => (
        <div
          draggable={false}
          key={i}
          className={`w-full h-[107px] absolute ${
            dragEntered ? "border-dashed border-y-2 border-slate-600" : ""
          }`}
          style={{ transform: `translate(0, ${row}px)` }}
          onDragEnter={(e) => {
            e.stopPropagation();
            setDragEntered(true);
          }}
        >
          {rail.services
            .filter((serv) => serv.positions.y === row && !serv.is_slide)
            .map((ser, serviceIndex) => {
              return (
                <div
                  draggable
                  key={serviceIndex}
                  className={`absolute flex justify-center 
                  ${dragEntered ? "non_dropable " : ""}`}
                  style={generateStyle(ser)}
                  onDragStart={(e) =>
                    handleDragStart(ser, e, railIndex, serviceIndex)
                  }
                  onDragEnter={(e) => e.stopPropagation()}
                  onClick={() => setService(ser, true)}
                >
                  <div
                    draggable={"false"}
                    className=" pointer-events-none"
                    style={{
                      width: serviceWidth(ser),
                      backgroundImage: `url(${ser.image_url})`,
                      backgroundSize: "contain",
                      backgroundRepeat: "no-repeat",
                      backgroundPosition: "center",
                      pointerEvents: "none",
                      height: ser.height / 0.056,
                    }}
                    onDragEnter={(e) => e.stopPropagation()}
                  />
                </div>
              );
            })}
        </div>
      ))}
      {/* for slides */}
      {rail.services
        .filter((serv) => serv.is_slide)
        .map((ser, serviceIndex) => {
          return (
            <div
              draggable
              key={serviceIndex}
              className={`absolute flex justify-center 
                  ${dragEntered ? "non_dropable " : ""}`}
              style={generateStyle(ser)}
              onDragStart={(e) =>
                handleDragStart(ser, e, railIndex, serviceIndex)
              }
              onDragEnter={(e) => e.stopPropagation()}
              onClick={() => setService(ser, true)}
            >
              <div
                draggable={"false"}
                className=" pointer-events-none"
                style={{
                  width: serviceWidth(ser),
                  backgroundImage: `url(${ser.image_url})`,
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  pointerEvents: "none",
                  height: ser.height / 0.056,
                }}
                onDragEnter={(e) => e.stopPropagation()}
              />
            </div>
          );
        })}
    </>
  );
};
