import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useMutation } from "react-query";
import { Input, Button } from "components";
import { useNavigate } from "react-router-dom";
import { LockIcon, HideIcon, ShowIcon } from "assets/images";
import { changePasswordSchema } from "config/schema";
import { errorAlert, successAlert } from "config/sweet-alert/alert";
import { changePassword } from "services/authServices";
// import { ErrorImg } from "assets/images";
import { useLoaderStore } from "store";

function ChangePassword() {
  const { setLoading } = useLoaderStore();
  const navigate = useNavigate();
  //state
  const [showPassword, setShowPassword] = useState({
    currentPassword: false,
    newPassword: false,
    confirm_password: false,
  });
  //formik

  const { values, errors, touched, handleChange, handleBlur, handleSubmit } =
    useFormik({
      initialValues: {
        current_password: "",
        new_password: "",
        confirm_password: "",
      },
      validationSchema: changePasswordSchema,
      onSubmit: (values) => {
        mutate(values);
      },
    });

  const { mutate, isLoading } = useMutation({
    mutationFn: changePassword,
    onSuccess: () => {
      successAlert("Ok", "Password updated successfully");
      localStorage.clear();
      navigate("/");
    },
    onError: (err) => {
      errorAlert(err.response.data.message);
    },
  });

  //manage loader
  useEffect(() => {
    setLoading(isLoading);
    return () => {
      setLoading(false);
    };
  }, [isLoading, setLoading]);

  return (
    <div className="w-full login_outer flex justify-center">
      <form
        className="login_container  my-12 h-fit flex flex-col"
        onSubmit={handleSubmit}
      >
        <h6 className="font-bold text-center text-2xl txtBlue">
          Change Password
        </h6>

        <div className="mt-10">
          <label className="inputTag text-lg">Current Password</label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={LockIcon} alt="img" />
            </span>
            <Input
              type={showPassword.currentPassword ? "text" : "password"}
              name="current_password"
              className="ml-4 w-full h-full"
              placeholder="Enter Current Password here"
              value={values.current_password}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />

            <span
              className="mr-4 cursor-pointer"
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  currentPassword: !showPassword.currentPassword,
                })
              }
            >
              <img
                src={showPassword.currentPassword ? ShowIcon : HideIcon}
                alt="img"
              />
            </span>
          </div>
          {errors.current_password && touched.current_password ? (
            <div className=" flex items-center text-sm font-normal text-red-600 mt-2">
              {/* <img src={ErrorImg} alt="..img" className="mx-2" /> */}
              {errors.current_password}
            </div>
          ) : null}
        </div>

        <div className="my-2">
          <label className="inputTag text-lg">New Password</label>
          <div className="login_input flex items-center">
            <span className="ml-3">
              <img src={LockIcon} alt="img" />
            </span>
            <Input
              type={showPassword.newPassword ? "text" : "password"}
              name="new_password"
              className="ml-4 w-full h-full"
              placeholder="Enter New Password here"
              value={values.new_password}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />

            <span
              className="mr-4 cursor-pointer"
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  newPassword: !showPassword.newPassword,
                })
              }
            >
              <img
                src={showPassword.newPassword ? ShowIcon : HideIcon}
                alt="img"
              />
            </span>
          </div>
          {errors.new_password && touched.new_password ? (
            <div className=" flex items-center text-sm font-normal text-red-600 mt-2">
              {/* <img src={ErrorImg} alt="..img" className="mx-2" /> */}
              {errors.new_password}
            </div>
          ) : null}
        </div>
        <div>
          <label className="inputTag text-lg">Confirm Password</label>
          <div className="login_input flex items-center ">
            <span className="ml-3">
              <img src={LockIcon} alt="img" />
            </span>
            <Input
              type={showPassword.confirm_password ? "text" : "password"}
              name="confirm_password"
              className="ml-4 w-full h-full"
              placeholder="Enter Confirm Password here"
              value={values.confirm_password}
              handleChange={(e) => {
                const { value } = e.target;
                if (!/\s/.test(value)) {
                  handleChange(e);
                }
              }}
              handleBlur={handleBlur}
            />

            <span
              className="mr-4 cursor-pointer"
              onClick={() =>
                setShowPassword({
                  ...showPassword,
                  confirm_password: !showPassword.confirm_password,
                })
              }
            >
              <img
                src={showPassword.confirm_password ? ShowIcon : HideIcon}
                alt="img"
              />
            </span>
          </div>
          {errors.confirm_password && touched.confirm_password ? (
            <div className=" flex items-center text-sm font-normal text-red-600 mt-2">
              {/* <img src={ErrorImg} alt="..img" className="mx-2" /> */}
              {errors.confirm_password}
            </div>
          ) : null}
        </div>
        <div className="mt-4 w-full flex justify-between gap-4">
          <Button
            className="btnTxt btnDashboard border-2 border-teal-700 text-teal-700  bg-white rounded cursor-pointer font-semibold text-base w-1/2"
            text={"Back"}
            type="button"
            handleClick={() => navigate(-1)}
            isDisabled={isLoading}
          />

          <Button
            className="login_btn btnDashboard btnTxt   text-white cursor-pointer rounded font-semibold text-base w-1/2"
            text={"Submit"}
            isDisabled={isLoading}
          />
        </div>
      </form>
    </div>
  );
}

export default ChangePassword;
