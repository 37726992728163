import React from "react";

function PrivacyPolicy() {
  return (
    <div className="p-20 text-black">
      <div className="flex justify-center my-5">
        <div>
          <h1 className="font-extrabold">Privacy Policy For Website</h1>
          <p className="my-2">Effective Date: ________</p>
          <p className="my-2">Applicable To The Following Website:</p>
          <p className="my-2">spark.hsiheadwalls.com</p>
        </div>
      </div>
      <h5 className="font-bold">Article 1 - DEFINITIONS:</h5>
      <p className="mt-3">
        a) APPLICABLE WEBSITE: This Privacy Policy will refer to and be
        applicable to the Website listed above, which shall hereinafter be
        referred to as "Website." Any listed Website may also refer to a
        corresponding mobile application, should one be currently in use or
        hereinafter developed.
      </p>
      <p className="mt-3">
        b) EFFECTIVE DATE: "Effective Date" means the date this Privacy Policy
        comes into force and effect.
      </p>
      <p className="mt-3">
        c) PARTIES: The parties to this privacy policy are the following data
        controller: Hospital Systems, Inc. ("Data Controller") and you, as the
        user of this Website. Hereinafter, the parties will individually be
        referred to as "Party" and collectively as "Parties."
      </p>
      <p className="mt-3">
        d) DATA CONTROLLER: Data Controller is the publisher, owner, and
        operator of the Website and is the Party responsible for the collection
        of information described herein. Data Controller shall be referred to
        either by Data Controller's name or "Data Controller," as listed above.
        If Data Controller or Data Controller's property shall be referred to
        through first-person pronouns, it shall be through the use of the
        following: us, we, our, ours, etc
      </p>
      <p className="mt-3">
        e) YOU: Should you agree to this Privacy Policy and continue your use of
        the Website, you will be referred to herein as either you, the user, or
        if any second-person pronouns are required and applicable, such pronouns
        as 'your", "yours", etc.
      </p>
      <p className="mt-3">
        f) SERVICES: "Services" means any services that we make available on the
        Website
      </p>
      <p className="mt-3">
        g) PERSONAL DATA: "Personal DATA" means personal data and information
        that we obtain from you in connection with your use of the Website that
        is capable of identifying you in any manner.
      </p>

      <h5 className="font-bold mt-2">Article 2 - GENERAL INFORMATION:</h5>
      <p className="mt-3">
        This privacy policy (hereinafter "Privacy Policy") describes how we
        collect and use the Personal Data that we receive about you, as well as
        your rights in relation to that Personal Data, when you visit our
        Website or use our Services.
      </p>
      <p className="mt-3">
        This Privacy Policy does not cover any information that we may receive
        about you through sources other than the use of our Website. The Website
        may link out to other websites or mobile applications, but this Privacy
        Policy does not and will not apply to any of those linked websites or
        applications.
      </p>
      <p className="mt-3">
        We are committed to the protection of your privacy while you use our
        Website.
      </p>
      <p className="mt-3">
        By continuing to use our Website, you acknowledge that you have had the
        chance to review and consider this Privacy Policy, and you acknowledge
        that you agree to it. This means that you also consent to the use of
        your information and the method of disclosure as described in this
        Privacy Policy. If you do not understand the Privacy Policy or do not
        agree to it, then you agree to immediately cease your use of our
        Website.
      </p>

      <h5 className="font-bold mt-2">
        Article 3 -CONTACT AND DATA PROTECTION OFFICER:
      </h5>
      <p className="mt-3">
        The Party responsible for the processing of your personal data is as
        follows: Hospital Systems, Inc. The Data Controller may be contacted as
        follows:
      </p>
      <p className="mt-3">
        datacontroller@hsiheadwalls.com <br />
        750 Garcia Ave <br />
        Pittsburg, CA 94565
      </p>
      <p className="mt-3">
        The Data Controller and operator of the Website are one and the same.
      </p>
      <p className="mt-3">
        The Data Protection Officer is as follows: Becca Teutle. The Data
        Protection Officer may be contacted as follows:
      </p>
      <p className="mt-3">
        bteutle@hsiheadwalls.com
        <br />
        750 Garcia Ave
        <br />
        Pittsburg, CA 94565
      </p>

      <h5 className="font-bold mt-2">Article 4 - LOCATION:</h5>
      <p className="mt-3">
        Please be advised the data processing activities take place in the
        United States, outside the European Economic Area. Data may also be
        transferred to companies within the United States, but will only be done
        so in a manner that complies with the EU's General Data Protection
        Regulation or GDPR. The location where the data processing activities
        take place is as follows:
      </p>
      <p className="mt-3">
        750 Garcia Ave
        <br />
        Pittsburg, CA 94596
      </p>
      <h5 className="font-bold mt-2">
        Article 5 - MODIFICATIONS AND REVISIONS:
      </h5>
      <p className="mt-3">
        We reserve the right to modify, revise, or otherwise amend this Privacy
        Policy at any time and in any manner. If we do so, however, we will
        notify you and obtain your consent to the change in processing. Unless
        we specifically obtain your consent, any changes to the Privacy Policy
        will only impact the information collected on or after the date of the
        change. It is also your responsibility to periodically check this page
        for any such modification, revision or amendment.
      </p>
      <h5 className="font-bold mt-2">
        Article 6 - THE PERSONAL DATA WE RECEIVE FROM YOU:
      </h5>
      <p className="mt-3">
        Depending on how you use our Website, you will be subject to different
        types of Personal Data collected and different manners of collection:
      </p>
      <p className="mt-3">
        <b>a) Registered users:</b> You, as a user of the Website, may be asked
        to register in order to use the Website or the Services available.
      </p>
      <p className="mt-3">
        During the process of your registration, we will collect some of the
        following Personal Data from you through your voluntary disclosure:
      </p>
      <p className="mt-3">
        Name
        <br /> Email Address
        <br /> Organization
        <br /> Phone <br />
        Password <br />
        HSI Contact Person Name/Email ID
      </p>
      <p className="mt-3">Personal Data may be asked for in relation to:</p>
      <p className="mt-3">I) Interaction with our representatives in any way</p>
      <p className="mt-3">
        II) receiving notifications by email about marketing
      </p>
      <p className="mt-3">III) receiving general emails from us</p>
      <p className="mt-3">IV) Or the following other forms of participation:</p>
      <p className="mt-3">
        Users will be contacted strictly to discuss their drawings, project
        timeline, or provide any additional information. Information will Not be
        sold
      </p>
      <p className="mt-3">
        By undergoing the registration process, you consent to us collecting
        your Personal Data, including the Personal Data described in this
        clause, as well as storing, using or disclosing your Personal Data in
        accordance with this Privacy Policy.
      </p>
      <p className="mt-3">
        <b>b) Unregistered users:</b> If you are a passive user of the Website
        and do not register for any purchases or other service, you may still be
        subject to certain passive data collection ("Passive Data Collection").
        Such Passive Data Collection may include through cookies, as described
        below, IP address information, location information, and certain browser
        data, such as history and/or session information.
      </p>
      <p className="mt-3">
        <b>c) All users:</b> The Passive Data Collection that applies to
        Unregistered users shall also apply to all other users and/or visitors
        of our Website.
      </p>
      <p className="mt-3">
        <b>d) Email Marketing: </b> You may be asked to provide certain Personal
        Data, such as your name and email address, for the purpose of receiving
        email marketing communications. This information will only be obtained
        through your voluntary disclosure and you will be asked to affirmatively
        opt-in to email marketing communications.
      </p>
      <p className="mt-3">
        <b>e) User Experience: </b> From time to time we may request information
        from you to assist us in improving our Website, and the Services we
        sell, such as demographic information or your particular preferences.
      </p>
      <h5 className="font-bold mt-3">
        Article 7 - THE PERSONAL DATA WE RECEIVE AUTOMATICALLY:
      </h5>
      <p className="mt-3">
        <b>Cookies:</b> We may collect information from you through automatic
        tracking systems (such as information about your browsing preferences)
        as well as through information that you volunteer to us (such as
        information that you provide during a registration process or at other
        times while using the Website, as described above).
      </p>
      <p className="mt-3">
        For example, we use cookies to make your browsing experience easier and
        more intuitive: cookies are small strings of text used to store some
        information that may concern the user, his or her preferences or the
        device they are using to access the internet (such as a computer,
        tablet, or mobile phone). Cookies are mainly used to adapt the operation
        of the site to your expectations, offering a more personalized browsing
        experience and memorizing the choices you made previously.
      </p>
      <p className="mt-3">
        A cookie consists of a reduced set of data transferred to your browser
        from a web server and it can only be read by the server that made the
        transfer. This is not executable code and does not transmit viruses.
      </p>
      <p className="mt-3">
        Cookies do not record or store any Personal Data. If you want, you can
        prevent the use of cookies, but then you may not be able to use our
        Website as we intend. To proceed without changing the options related to
        cookies, simply continue to use our Website.
      </p>
      <p className="mt-3">
        <b>Technical cookies: </b>Technical cookies, which can also sometimes be
        called HTML cookies, are used for navigation and to facilitate your
        access to and use of the site. They are necessary for the transmission
        of communications on the network or to supply services requested by you.
        The use of technical cookies allows the safe and efficient use of the
        site.
      </p>
      <p className="mt-3">
        You can manage or request the general deactivation or cancelation of
        cookies through your browser. If you do this though, please be advised
        this action might slow down or prevent access to some parts of the site.
      </p>
      <p className="mt-3">
        Cookies may also be retransmitted by an analytics or statistics provider
        to collect aggregated information on the number of users and how they
        visit the Website. These are also considered technical cookies when they
        operate as described.
      </p>
      <p className="mt-3">
        Temporary session cookies are deleted automatically at the end of the
        browsing session - these are mostly used to identify you and ensure that
        you don't have to log in each time - whereas permanent cookies remain
        active longer than just one particular session.
      </p>
      <p className="mt-3">
        <b>Support in configuring your browser:</b> You can manage cookies
        through the settings of your browser on your device. However, deleting
        cookies from your browser may remove the preferences you have set for
        this Website.
      </p>
      <p className="mt-3">
        For further information and support, you can also visit the specific
        help page of the web browser you are using:
      </p>
      <p className="mt-3">
        - Internet Explorer:
        http://windows.microsoft.com/en-us/windows-vista/block-orallow-cookies
      </p>
      <p className="mt-3">
        - Firefox:
        https://support.mozilla.org/en-us/kb/enable-and-disable-cookieswebsite-preferences
      </p>
      <p className="mt-3">- Safari: http://www.apple.com/legal/privacy/</p>
      <p className="mt-3">
        - Chrome: https://support.google.com/accounts/answer/61416?hl=en
      </p>
      <p className="mt-3">
        - Opera: http://www.opera.com/help/tutorials/security/cookies/
      </p>
      <p className="mt-3">
        <b>Log Data:</b> Like all websites and mobile applications, this Website
        also makes use of log files that store automatic information collected
        during user visits. The different types of log data could be as follows:
      </p>
      <p className="mt-3">
        - internet protocol (IP) address;
        <br />
        - type of browser and device parameters used to connect to the Website;
        <br />
        - name of the Internet Service Provider (ISP);
        <br />
        - date and time of visit;
        <br />
        - web page of origin of the user (referral) and exit;
        <br />
        - possibly the number of clicks.
        <br />
      </p>
      <p className="mt-3">
        The aforementioned information is processed in an automated form and
        collected in an exclusively aggregated manner in order to verify the
        correct functioning of the site, and for security reasons. This
        information will be processed according to the legitimate interests of
        the Data Controller.
      </p>
      <p className="mt-3">
        For security purposes (spam filters, firewalls, virus detection), the
        automatically recorded data may also possibly include Personal Data such
        as IP address, which could be used, in accordance with applicable laws,
        in order to block attempts at damage to the Website or damage to other
        users, or in the case of harmful activities or crime. Such data are 6/9
        never used for the identification or profiling of the user, but only for
        the protection of the Website and our users. Such information will be
        treated according to the legitimate interests of the Data Controller.
      </p>
      <h5 className="mt-3 font-bold">Article 8 - THIRD PARTIES:</h5>
      <p className="mt-3">
        We may utilize third-party service providers ("Third-Party Service
        Providers"), from time to time or all the time, to help us with our
        Website, and to help serve you.
      </p>
      <p className="mt-3">
        We may use Third-Party Service Providers to assist with information
        storage (such as cloud storage).
      </p>
      <p className="mt-3">
        We may provide some of your Personal Data to Third-Party Service
        Providers in order to help us track usage data, such as referral
        websites, dates and times of page requests, etc. We use this information
        to understand patterns of usage of, and to improve, the Website.
      </p>
      <p className="mt-3">
        We may use Third-Party Service Providers to host the Website. In this
        instance, the Third-Party Service Provider will have access to your
        Personal Data.
      </p>
      <p className="mt-3">
        We only share your Personal Data with a Third-Party Service Provider if
        that provider agrees to our privacy standards as set out in this Privacy
        Policy.
      </p>
      <p className="mt-3">
        Your Personal Data will not be sold or otherwise transferred to other
        third parties without your approval.
      </p>
      <p className="mt-3">
        Notwithstanding the other provisions of this Privacy Policy, we may
        provide your Personal Data to a third party or to third parties in order
        to protect the rights, property or safety, of us, our customers or third
        parties, or as otherwise required by law.
      </p>
      <p className="mt-3">
        We will not knowingly share your Personal Data with any third parties
        other than in accordance with this Privacy Policy.
      </p>
      <p className="mt-3">
        If your Personal Data might be provided to a third party in a manner
        that is other than as explained in this Privacy Policy, you will be
        notified. You will also have the opportunity to request that we not
        share that information.
      </p>
      <p className="mt-3">
        In general, you may request that we do not share your Personal Data with
        third parties. Please contact us via email, if so. Please be advised
        that you may lose access to certain services that we rely on third-party
        providers for.
      </p>
      <h5 className="mt-3 font-bold">
        Article 9 - HOW PERSONAL DATA IS STORED:
      </h5>
      <p className="mt-3">
        We use secure physical and digital systems to store your Personal Data
        when appropriate. We ensure that your Personal Data is protected against
        unauthorized access, disclosure, or destruction.
      </p>
      <p className="mt-3">
        Please note, however, that no system involving the transmission of
        information via the internet, or the electronic storage of data, is
        completely secure. However, we take the protection and storage of your
        Personal Data very seriously. We take all reasonable steps to protect
        your Personal Data.
      </p>
      <p className="mt-3">
        Personal Data is stored throughout your relationship with us. We delete
        your Personal Data upon request for cancelation of your account or other
        general request for the deletion of data.
      </p>
      <p className="mt-3">
        In the event of a breach of your Personal Data, you will be notified in
        a reasonable time frame, but in no event later than two weeks, and we
        will follow all applicable laws regarding such breach.
      </p>
      <h5 className="mt-3 font-bold">
        Article 10 - PURPOSES OF PROCESSING OF PERSONAL DATA:
      </h5>
      <p className="mt-3">
        We primarily use your Personal Data to help us provide a better
        experience for you on our Website and to provide you the services and/or
        information you may have requested, such as use of our Website.
      </p>
      <p className="mt-3">
        Information that does not identify you personally, but that may assist
        in providing us broad overviews of our customer base, will be used for
        market research or marketing efforts. Such information may include, but
        is not limited to, interests based on your cookies.
      </p>
      <p className="mt-3">
        Personal Data that may be considering identifying may be used for the
        following:
      </p>
      <p className="mt-3">a) Improving your personal user experience</p>
      <p className="mt-3">
        b) Communicating with you about your user account with us
      </p>
      <p className="mt-3">
        c) Marketing and advertising to you, including via email
      </p>
      <p className="mt-3">d) Providing customer service to you</p>
      <p className="mt-3">
        e) Advising you about updates to the Website or related Items
      </p>
      <h5 className="mt-3 font-bold">
        Article 11 - DISCLOSURE OF PERSONAL DATA:
      </h5>
      <p className="mt-3">
        Although our policy is to maintain the privacy of your Personal Data as
        described herein, we may disclose your Personal Data if we believe that
        it is reasonable to do so in certain cases, in our sole and exclusive
        discretion. Such cases may include, but are not limited to:
      </p>
      <p className="mt-3">
        a) To satisfy any local, state, or Federal laws or regulations
      </p>
      <p className="mt-3">
        b) To respond to requests, such discovery, criminal, civil, or
        administrative process, subpoenas, court orders, or writs from law
        enforcement or other governmental or legal bodies
      </p>
      <p className="mt-3">
        c) To bring legal action against a user who has violated the law or
        violated the terms of use of our Website
      </p>
      <p className="mt-3">
        d) As may be necessary for the operation of our Website
      </p>
      <p className="mt-3">
        e) To generally cooperate with any lawful investigation about our users
      </p>
      <p className="mt-3">
        f) If we suspect any fraudulent activity on our Website or if we have
        noticed any activity which may violate our terms or other applicable
        rules
      </p>
      <h5 className="mt-3 font-bold">
        Article 12 - OPTING OUT OF TRANSMITTALS FROM US:
      </h5>
      <p className="mt-3">
        From time to time, we may send you informational or marketing
        communications related to our Website such as announcements or other
        information. If you wish to opt-out of such communications, you may
        contact the following email: optout@hsiheadwalls.com. You may also click
        the opt-out link which will be provided at the bottom of any and all
        such communications.
      </p>
      <p className="mt-3">
        Please be advised that even though you may opt-out of such
        communications, you may still receive information from us that is
        specifically about your use of our Website or about your account with
        us.
      </p>
      <p className="mt-3">
        By providing any Personal Data to us, or by using our Website in any
        manner, you have created a commercial relationship with us. As such, you
        agree that any email sent from us or third-party affiliates, even
        unsolicited email, shall specifically not be considered SPAM, as that
        term is legally defined.
      </p>
      <h5 className="mt-3 font-bold">
        Article 13 - MODIFYING, DELETING, AND ACCESSING YOUR INFORMATION:
      </h5>
      <p className="mt-3">
        If you wish to modify or delete any information we may have about you,
        or you wish to simply access any information we have about you, you may
        do so from your account settings page.
      </p>
      <h5 className="mt-3 font-bold">Article 14 - ACCEPTANCE OF RISK:</h5>
      <p className="mt-3">
        By continuing to our Website in any manner, use the Product, you
        manifest your continuing asset to this Privacy Policy. You further
        acknowledge, agree and accept that no transmission of information or
        data via the internet is not always completely secure, no matter what
        steps are taken. You acknowledge, agree and accept that we do not 9/9
        guarantee or warrant the security of any information that you provide to
        us, and that you transmit such information at your own risk.
      </p>
      <h5 className="mt-3 font-bold">Article 15 - YOUR RIGHTS:</h5>
      <p className="mt-3">
        You have many rights in relation to your Personal Data. Specifically,
        your rights are as follows:
      </p>
      <p className="mt-3">
        - the right to be informed about the processing of your Personal Dat
      </p>
      <p className="mt-3">- the right to have access to your Personal Data</p>
      <p className="mt-3">
        - the right to update and/or correct your Personal Data
      </p>
      <p className="mt-3">- the right to portability of your Personal Data</p>
      <p className="mt-3">
        - the right to oppose or limit the processing of your Personal Data
      </p>
      <p className="mt-3">
        - the right to request that we stop processing and delete your Personal
        Data
      </p>
      <p className="mt-3">
        - the right to block any Personal Data processing in violation of any
        applicable law
      </p>
      <p className="mt-3">
        - the right to launch a complaint with the Federal Trade Commission
        (FTC) in the United States or applicable data protection authority in
        another jurisdiction
      </p>
      <p className="mt-3">
        Such rights can all be exercised by contacting us at the relevant
        contact information listed in this Privacy Policy.
      </p>
      <h5 className="mt-3 font-bold">Article 16 - CONTACT INFORMATION:</h5>
      <p className="mt-3">
        If you have any questions about this Privacy Policy or the way we
        collect information from you, or if you would like to launch a complaint
        about anything related to this Privacy Policy, you may contact us at the
        following email address: privacy@hsiheadwalls.com.
      </p>
    </div>
  );
}

export default PrivacyPolicy;
