import { EditingIcon, LinkServicesIcon, ReviewIcon } from "assets/images";
import { create } from "zustand";
const initialvalues = [
  {
    step: 3,
    text: "Editing",
    icon: EditingIcon,
    completed: false,
  },
  {
    step: 4,
    text: "Services",
    icon: LinkServicesIcon,
    completed: false,
  },
  {
    step: 5,
    text: "Review",
    icon: ReviewIcon,
    completed: false,
  },
];

// Define a Zustand store
const rightPanelStore = create((set) => ({
  rightPanelStepper: initialvalues,

  // Action to update the completed status based on step
  updateStepCompletion: (step, completed) =>
    set((state) => ({
      rightPanelStepper: state.rightPanelStepper.map((item) =>
        item.step === step ? { ...item, completed } : item
      ),
    })),
    resetState: () => set({ rightPanelStepper: initialvalues }),
}));
export default rightPanelStore;
