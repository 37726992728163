import React, { useState } from "react";
import { Link } from "react-router-dom";
import { LogoIcon } from "assets/images";
import { Button } from "components";

function Welcome({ handleClose }) {
  const [termsAccepted, setTermsAccepted] = useState(false);

  const handleChange = (e) => {
    const { checked } = e.target;
    setTermsAccepted(checked);
  };

  return (
    <div
      className="relative z-10"
      aria-labelledby="modal-title"
      role="dialog"
      aria-modal="true"
    >
      <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

      <div className="fixed inset-0 z-10 w-screen overflow-y-auto text-black">
        <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all md:max-w-screen-xl">
            <div className="bg-[#F9E7D0] p-20">
              <div className=" bg-white p-5">
                <div className="p-2 ">
                  <img
                    src={LogoIcon}
                    alt="..img"
                    width={"150px"}
                    height={"150px"}
                  />
                </div>
                <div className=" border-y-2 p-2">
                  <p>Welcome to Spark! by HSI! </p>
                  <br />
                  <p>
                    We are thrilled to provide a tool to Spark your creativity
                    and design the Headwall that meets your needs, just as you
                    want it, so we can make it a reality.
                  </p>
                  <br />
                  <p>
                    Before you begin, please create an account so that you can
                    save your designs and keep them all in one place. Your
                    contact information will exclusively be used to provide you
                    with CAD drawings, quotes, and other information related to
                    your project. Your data will never be sold or used for
                    marketing.
                  </p>
                  <br />
                  <p>
                    {" "}
                    HSI is proud our headwalls are build to order. Because the
                    possibilities are limitless, there are options that are not
                    present in Spark!. If there is something you’d like that
                    isn’t here, please feel free to include it in the comments
                    field when reviewing your design or reach out to us
                    directly. We are happy to work with you and make sure you
                    receive the headwall of your dreams!
                  </p>

                  <div className="flex items-center mt-4">
                    <input
                      name="terms_conditions"
                      type="checkbox"
                      className=" w-5 h-8 text-gray-600 bg-gray-100 border-gray-300 rounded focus:ring-gray-500 dark:focus:ring-gray-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                      onChange={handleChange}
                      checked={termsAccepted}
                    />
                    <label htmlFor={"terms_conditions"} className="ml-5">
                      Check the box to confirm you have read and agree to HSI’s{" "}
                      <Link to={"/terms-conditions"} className="underline">
                        Terms and Conditions
                      </Link>{" "}
                      and{" "}
                      <Link to={"/privacy-policy"} className="underline">
                        {" "}
                        Privacy Policy
                      </Link>
                      .
                    </label>
                  </div>
                </div>
                <div className="my-5 flex justify-center">
                  <Button
                    className="login_btn py-2 px-10  text-white cursor-pointer rounded btnTxt"
                    text={"Continue"}
                    handleClick={() => handleClose(false)}
                    isDisabled={termsAccepted ? false : true}
                    type={"button"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Welcome;
