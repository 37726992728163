import {
  emailRegExp,
  passwordRegExp,
  phoneRegExp,
} from "constants/appConstant";
import * as Yup from "yup";

//login schema

const loginSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(emailRegExp, "Please enter a valid email address")
    .required("Please enter a valid email address"),
  password: Yup.string()
    .min(8, "Please enter a valid password")
    .required("Please enter a valid password")
    .matches(
      passwordRegExp,
      "Password Must Include 8 - 20 Characters. e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ , Numbers: 0-9 . (e.g. Abcd@123)"
    ),
});

//sign up schema
const signupSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  country: Yup.string()
    .required("Please enter country")
    .matches(/^[a-zA-Z\s]*$/, "Country name can only contain letters"),
  state: Yup.string()
    .required("Please enter state")
    .matches(/^[a-zA-Z\s]*$/, "State name can only contain letters"),
  zipcode: Yup.string()
    .required("Please enter zip code")
    .min(4, "Zip code Must Include 4-10 Characters")
    .max(10, "Zip code Must Include 4-10 Characters")
    .matches(
      /^[a-zA-Z0-9]*$/,
      "Zip code can only contain numbers and alphanumeric characters"
    ),
  email: Yup.string()
    .email("Please enter a valid email address")
    .matches(emailRegExp, "Please enter a valid email address")
    .required("Please enter a valid email address"),
  password: Yup.string()
    .min(
      8,
      "Password Must Include 8 - 20 Characters. e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ , Numbers: 0-9 . (e.g. Abcd@123)"
    )
    .matches(
      passwordRegExp,
      "Password Must Include 8 - 20 Characters. e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ , Numbers: 0-9 . (e.g. Abcd@123)"
    )
    .required("Please enter a valid password"),
  repeat_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Please make sure your Passwords match")
    .required("Please enter a valid confirm password"),
  phone: Yup.string()
    .matches(phoneRegExp, "Please enter a valid phone no")
    .max(12, "Please enter a valid phone no"),
});

//reset password schema
const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(
      8,
      "Password  Must Include 8 - 20 Characters.  e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ ,  Numbers: 0-9 . (e.g. Abcd@123)"
    )
    .matches(
      passwordRegExp,
      "Password  Must Include 8 - 20 Characters.  e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ ,  Numbers: 0-9 . (e.g. Abcd@123)"
    )
    .required(
      "Password  Must Include 8 - 20 Characters.  e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ ,  Numbers: 0-9 . (e.g. Abcd@123)"
    ),
  confirm_password: Yup.string()
    .oneOf([Yup.ref("password"), null], "Please make sure your Passwords match")
    .required("Please make sure your Passwords match"),
});

//change password schema
const changePasswordSchema = Yup.object().shape({
  current_password: Yup.string().required(
    "Current password field cannot be blank"
  ),
  new_password: Yup.string()
    .min(
      8,
      "Password  Must Include 8 - 20 Characters.  e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ ,  Numbers: 0-9 . (e.g. Abcd@123)"
    )
    .matches(
      passwordRegExp,
      "Password  Must Include 8 - 20 Characters.  e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ ,  Numbers: 0-9 . (e.g. Abcd@123)"
    )
    .required(
      "Password  Must Include 8 - 20 Characters.  e.g. Uppercase: A-Z , Lowercase: a-z , Special Characters: ! @ $ ,  Numbers: 0-9 . (e.g. Abcd@123)"
    ),
  confirm_password: Yup.string()
    .oneOf(
      [Yup.ref("new_password"), null],
      "Please make sure your Passwords match"
    )
    .required("Please make sure your Passwords match"),
});

//forgot password schema
const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email")
    .required("Please enter a valid email")
    .max(40, "Please enter a valid email"),
});

const updateProfileSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter first name"),
  last_name: Yup.string().required("Please enter last name"),
  // zipcode: Yup.string()
  //   .required("Please enter zip code")
  //   .min(5, "Zip code Must Include 5-9 Characters")
  //   .max(10, "Zip code Must Include 5-9 Characters")
  //   .test("state-required", "Please enter a valid zip code", function (value) {
  //     const { state, country } = this.parent;
  //     if (country === "United States" && !state && value) {
  //       return false;
  //     }
  //     return true;
  //   }),
  country: Yup.string()
    .required("Please enter country")
    .matches(/^[a-zA-Z\s]*$/, "Country name can only contain letters"),
  state: Yup.string()
    .required("Please enter state")
    .matches(/^[a-zA-Z\s]*$/, "State name can only contain letters"),
  zipcode: Yup.string()
    .required("Please enter zip code")
    .min(4, "Zip code Must Include 4-10 Characters")
    .max(10, "Zip code Must Include 4-10 Characters")
    .matches(
      /^[a-zA-Z0-9]*$/,
      "Zip code can only contain numbers and alphanumeric characters"
    ),
  phone: Yup.string()
    .matches(phoneRegExp, "Please enter a valid phone no")
    .max(12, "Please enter a valid phone no"),
});

export {
  loginSchema,
  signupSchema,
  resetPasswordSchema,
  forgotPasswordSchema,
  changePasswordSchema,
  updateProfileSchema,
};
